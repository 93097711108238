import Text from './text';
import Image from './image';
import Gallery from './gallery';
import Button from './button';
import Containers from './containers';
import Contact from './contact';
import Social from './social';
import OnlineShop from './onlineShop';
import More from './more';
import Section from "./section";
import Video from './video';
import Booking from './booking';
import Widgets from './widgets';
import Bookings from './bookings';

const itemKinds = ([
    Text.kind,
    Image.kind,
    Gallery.kind,
    Video.kind,
    Button.kind,
    Section.kind,
    Containers.kind,
    Contact.kind,
    Social.kind,
    Widgets.kind,
    OnlineShop.kind,
    Booking.kind,
    Bookings.kind,
    More.kind
]);

export { itemKinds };

export default {
    [Text.kind]: Text,
    [Image.kind]: Image,
    [Gallery.kind]: Gallery,
    [Button.kind]: Button,
    [Containers.kind]: Containers,
    [Contact.kind]: Contact,
    [Social.kind]: Social,
    [OnlineShop.kind]: OnlineShop,
    [More.kind]: More,
    [Section.kind]: Section,
    [Video.kind]: Video,
    [Booking.kind]: Booking,
    [Widgets.kind]: Widgets,
    [Bookings.kind]: Bookings,
};
