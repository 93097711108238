import React from "react";
import { Dispatch } from "redux";
import { useSelector } from "react-redux";
import cx from 'classnames';
import { pure } from 'recompose';
import AppConfig from "../../../utils/AppConfig";
import { getAppConfig } from "../../App/epics/appConfig/appConfig";
import Scrollbar from '../../../view/common/Scrollbar/index';
import { TopBarHeight } from '../../TopBar/constants';
import { getLocaleCookie } from "../../Locale/cookie";
import * as actionTypes from "../actionTypes";
import { leftPanelOnMouseOverAC, leftPanelOnMouseLeaveAC } from '../../App/actionCreators/index';
import styles from './Inserter.css';
import itemConfigsMap, { itemKinds } from '../configs/index';
import type { PanelProps, ComponentsKindsToDisableMap } from "../flowTypes";
import { ShortCut } from "./DroppableCmpShortcut";
import { injectIntl } from "../../../view/intl/index";
import VerticalSpacer from "../../../view/common/VerticalSpacer";
import EPSeparator from "./EPSeparator";
import { Intl } from "../../../view/intl/injectIntl";
import { hasUpdate } from "../configs/hasUpdate";
import * as mouseUtils from "../../../utils/mouse";
import { SectionsKind } from "../configs/constants";
import { SectionShortcut } from "../configs/section";
import {
    getComponentTierDataFromComponentKind,
    checkComponentSubscriptionCompatibility
} from "../../ComponentTierManager/utils";
import getSubscriptionFeatureManager from "../../../getSubscriptionFeatureManager";
import CodeComponentKind from "../../oneweb/Code/kind";
import OnlineShop from '../configs/onlineShop';
import { isTrialSubscription, isEcommerceSubscription } from "../../App/epics/subscriptionData/utils";
import { isPartnerSubdomain } from "../../ComponentTierManager/partnerSubdomainNameUtils";
import { WidgetsBookingsKind } from "../../oneweb/Widgets/Bookings/kind";
import { getDAL } from "../../../../dal/index";
import BookingsKind from "../../oneweb/Bookings/kind";
import { selectShowBookingsUpgradeIcon } from "../../oneweb/Bookings/expandedPanel/epic/selectors";
import { NewTag } from "../../../view/common/Tag";

const shouldShowBookingWidget = () => {
    const appConfig = AppConfig(getAppConfig());
    const isBookingSupportedLocale = (locale) => ["en_gb", "da", "sv", "nl", "de"].includes(locale);
    const bookingsConfig = appConfig.getOptional('oneWeb.bookings');
    let showBookingWidget = bookingsConfig && isBookingSupportedLocale(getLocaleCookie());

    // check if domain whitelisting is enabled and if the current domain is whitelisted
    const whiteListedDomains = bookingsConfig.getOptional('whitelistedDomains', []);
    if (whiteListedDomains.length > 0) {
        const domain = getDAL().getDomain();
        showBookingWidget = showBookingWidget && whiteListedDomains.includes(domain);
    }

    return showBookingWidget;
};

export type ExtendedPanelContentProps = {
    dispatch: Dispatch,
    componentsKindsToDisableMap: ComponentsKindsToDisableMap,
    intl: Intl,
    subscriptionType: string,
    isDemo?: boolean,
    email?: string,
    subscriptionMetadata?: Record<string, any>
}

const Items = (props) => {
    const { isComponentCompatible, selectedKind, dispatch, subscriptionType } = props;
    let itemKindsUpdated = itemKinds;

    if (!(getSubscriptionFeatureManager().isOnlineShopCmpsAllowed()) || isPartnerSubdomain()) {
        itemKindsUpdated = itemKinds.filter(k => k !== OnlineShop.kind);
    }

    if (!(getSubscriptionFeatureManager().isBoookingsWidgetAllowed() && shouldShowBookingWidget())) {
        itemKindsUpdated = itemKindsUpdated.filter(k => k !== WidgetsBookingsKind);
    }

    // Bookings is not supported for Trial and partner domains
    if (isPartnerSubdomain() || isTrialSubscription(subscriptionType)) {
        itemKindsUpdated = itemKindsUpdated.filter(kind => kind !== BookingsKind);
    }

    const selectedItemIndex = itemKindsUpdated.findIndex(kind => selectedKind === kind);

    return <div className={styles.itemsContainer}>
        {
            [
                ...itemKindsUpdated.map((itemKind, index) => {
                    const {
                        kind,
                        shortCut: {
                            iconClassName,
                            text
                        }
                    } = itemConfigsMap[itemKind];

                    return (
                        <div
                            key={kind}
                            className={cx(styles.shortCutContainer, {
                                [styles.selectedKind]: (selectedKind === kind),
                                [styles.selectedKindPrev]: (index - selectedItemIndex === -1) && selectedItemIndex > -1,
                                [styles.selectedKindNext]: (index - selectedItemIndex === 1) && selectedItemIndex > -1,
                            })}
                            onClick={(e) => {
                                dispatch({
                                    type: actionTypes.PANEL_ITEM_CLICK,
                                    payload: { componentKind: itemKind, isComponentCompatible }
                                });
                                e.stopPropagation();
                            }}
                            onMouseDown={(e) => {
                                mouseUtils.onMouseDown(
                                    e,
                                    () => {
                                        dispatch({
                                            type: actionTypes.PANEL_ITEM_LEFT_MOUSE_DOWN,
                                            payload: { componentKind: itemKind, isComponentCompatible }
                                        });
                                        e.stopPropagation();
                                    },
                                    () => null
                                );
                            }}
                        >
                            {
                                kind === SectionsKind ?
                                    <SectionShortcut
                                        iconClassName={iconClassName}
                                        text={text}
                                        kind={kind}
                                        hasUpdate={hasUpdate(kind)}
                                    />
                                    :
                                    <ShortCut
                                        iconClassName={iconClassName}
                                        text={text}
                                        kind={kind}
                                        hasUpdate={hasUpdate(kind)}
                                    />
                            }
                        </div>
                    );
                }),
                <div className={styles.dummyContainer} key="dummyContainer"> </div>
            ]
        }
    </div>;
};

const Title = injectIntl(({ title, showUpgradeIconInTitle, showNewTag, intl }) => (
    <div className={styles.extendedPanelTitleContainer}>
        <VerticalSpacer y={20} />
        <div className={cx(styles.titleIcon, styles.extendedPanelTitle)}>
            {intl.msgJoint(title)}
            {showUpgradeIconInTitle && (
                <span className={cx(styles.componentTitleIcon, styles.premiumComponentIcon)} />
            )}
            {showNewTag && (
                <NewTag
                    containerClassName={styles.titleNewTagContainer}
                    labelClassName={styles.titleNewTagText}
                />
            )}
        </div>
        <VerticalSpacer y={17} />
        <EPSeparator />
    </div>
));

export default injectIntl(pure(({
    state: {
        browserDimensions,
        isMouseOverPanel,
        componentsKindsToDisableMap,
        extendedPanel: { open, itemKind },
    },
    subscriptionType,
    subscriptionMetadata,
    dispatch,
    intl
}: PanelProps) => {
    const
        props: ExtendedPanelContentProps = {
            componentsKindsToDisableMap,
            subscriptionType,
            subscriptionMetadata,
            dispatch,
            intl
        },
        leftPanelMouseOverHandler = () => {
            if (!isMouseOverPanel) {
                dispatch(leftPanelOnMouseOverAC());
            }
        },
        onExtendedPanelClose = (e) => {
            e.stopPropagation();
            dispatch({ type: actionTypes.EXTENDED_PANEL_CLOSE });
        },
        // @ts-ignore
        { title, content: Content } = itemKind ? itemConfigsMap[itemKind].extendedPanelData : {};

    let componentTier, isComponentCompatible;
    if (itemKind) {
        if (itemKind === CodeComponentKind && !isTrialSubscription(subscriptionType)) {
            // Existing users can continue using CODE component as regular
            isComponentCompatible = true;
        } else {
            componentTier = getComponentTierDataFromComponentKind(itemKind);
            isComponentCompatible = checkComponentSubscriptionCompatibility(componentTier.componentTierType, subscriptionType);
        }
    }

    // depending on the inserter screen the upgrade icon may need to be shown for bookings
    const showBookingsUpgradeIcon = useSelector(selectShowBookingsUpgradeIcon);

    const showUpgradeIconInTitle =
        !isComponentCompatible ||
        (itemKind === OnlineShop.kind && !isEcommerceSubscription(subscriptionType)) ||
        (itemKind === BookingsKind && showBookingsUpgradeIcon);

    const showNewTag = itemKind === BookingsKind;

    return (
        <div
            className={cx(styles.panel, { [styles.open]: open })}
            onMouseLeave={() => dispatch(leftPanelOnMouseLeaveAC())}
            onMouseOver={leftPanelMouseOverHandler}
            onMouseEnter={leftPanelMouseOverHandler}
        >
            <div className={styles.panelContainer} onClick={onExtendedPanelClose}>
                <Scrollbar height={browserDimensions.height - TopBarHeight}>
                    <div className={styles.itemsWrapper}>
                        <Items
                            selectedKind={itemKind}
                            subscriptionType={subscriptionType}
                            isComponentCompatible={isComponentCompatible}
                            dispatch={dispatch}
                        />
                    </div>
                </Scrollbar>
            </div>
            <div className={cx(styles.extendedPanel, 'extendedPanel')}>
                {
                    open &&
                    <div className={styles.extendedPanelContainer}>
                        {typeof title === "string" ? (
                            <Title
                                showUpgradeIconInTitle={showUpgradeIconInTitle}
                                title={title}
                                showNewTag={showNewTag}
                            />
                        ) : (
                            title
                        )}
                        <Scrollbar height="auto">
                            <div className={styles.items}>
                                <Content {...props} />
                            </div>
                        </Scrollbar>
                        <div
                            data-title={intl.msgJoint("msg: common.close {Close}")}
                            data-title-position="right"
                            onClick={onExtendedPanelClose}
                            className={styles.panelCloseBtn}
                        > </div>
                    </div>
                }
            </div>
        </div>
    );
}));
