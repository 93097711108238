/* eslint-disable max-len */
import cloneDeep from 'clone-deep';
import * as R from "ramda";
import makeEpic from '../../../epics/makeEpic';
import {
    CALCULATE_UPGRADE_TIER_BEFORE_SEAMLESS_UPGRADE,
    NEW_COMPONENT_CHECK_COMPONENT_TIER_ON_DROP,
    TRY_UPGRADE_DIALOG_CANCELLED,
    TRY_UPGRADE_DIALOG_COMPLETED,
    SET_PREMIUM_FEATURE_REQUEST_IN_PROGRESS,
    IS_PREMIUM_COMPONENT_REQUEST_IN_PROGRESS_CHANGED_ACTION
} from "../actionTypes";
import componentsRegistry from '../../../view/oneweb/registry/index';
import { receiveOnly, optional, withSelector } from "../../../epics/makeCondition";
import { subscriptionDataVat } from "../../App/epics/subscriptionData/valueActionType";
import {
    checkComponentSubscriptionCompatibility,
    getSubscriptionTypeForSeamlessUpgrade,
    checkSubscriptionCompatibilityFromKind,
    isShopRelatedComponent,
} from "../utils";
import { getRecommendedSubscriptionType } from "../getRecommendedSubscriptionType";
import { openDialog, updateDialog, closeDialog } from "../../App/actionCreators";
import { CONTINUE_ADD_COMPONENT_AFTER_TIER_CHECK } from "../../DndAddComponent/actionTypes";
import { LOAD_PAID_FEATURES_DOC_SUCCESS, LOAD_PAID_FEATURES_DOC_FAILURE } from "../../Workspace/epics/saveStatus/actionTypes";
import loadPaidFeaturesDoc from "../../Workspace/epics/saveStatus/actionCreators/loadPaidFeaturesDoc";
import { PAGE_DATASET_SAVE_SUCCESS } from "../../App/epics/pageDataset/actions";

import valueActionType from './valueActionType';
import { publicPagesSelector, sectionLinksSelector } from "../../App/epics/siteData";
import { SubscriptionType } from "../../../../../server/shared/subscriptionStatus/constants";
import { ComponentsMapSelector } from "../../Workspace/epics/componentsEval/selectorActionTypes";
import { NewComponentTypes } from "../../../../dal/pageMapAdapter/componentTypesMap";
import OpenSeamlessUpgradeDialog from "../../TopBar/view/Upgrade/SeamlessUpgradeDialog/OpenSeamlessUpgradeDialog";
import type { SubscriptionTypeT } from "../../App/epics/subscriptionData/flowTypes";
import { subscriptionTypeSelector } from "../../App/epics/subscriptionData";
import { isTrialSubscription } from "../../App/epics/subscriptionData/utils";
import { siteDataValueActionType } from "../../App/epics/siteData/valueActionType";
import * as dialogIds from "../../TopBar/view/dialogIds";
import currentPageIdValueActionType from "../../App/epics/currentPageId/valueActionType";
import { siteSettingsValueActionType } from '../../App/epics/siteSettings/valueActionType';
import { shopPageIdsListVat } from '../../oneweb/WebShop/epics/shopPageIdsList/valueActionType';
import { WORKSPACE_DID_MOUNT, DELETE_SELECTED_COMPONENTS, WORKSPACE_READY } from "../../Workspace/actionTypes";
import { checkIsIncompatibleComponentPresent, checkIncompatibleComponents, mergeFeaturesData } from './utils';
import { SITE_DATA_UPDATE_SUCCESS } from '../../App/actionTypes';
import { upgradeDialogOpenedAction } from '../../../../../src/dashboard/src/components/Main/UpgradePlansDialog/actions';
import AppConfigEpicVAT from "../../App/epics/appConfig/valueActionType";
import { partnerIsOnecom } from "../../../../../server/shared/partners/PartnerName";
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../actionCreators";

export type ComponentTierManagerStateType = {
    isIncompatibleComponentPresent: boolean,
    isPremiumComponentRequestInProgress?: boolean,
    isIncompatibleComponentPresentInCurrentPage: boolean,
    isIncompatibleComponentPresentInOtherPages: boolean,
    freeTierUpgradeSubscriptionType: SubscriptionTypeT
};

export type ComponentTierManagerScopeType = {
    serverResponsePremiumFeaturesData: Object
}

export const
    componentTierManagerState: ComponentTierManagerStateType = {
        isIncompatibleComponentPresent: false,
        isPremiumComponentRequestInProgress: false,
        freeTierUpgradeSubscriptionType: SubscriptionType.NORMAL,
        isIncompatibleComponentPresentInCurrentPage: false,
        isIncompatibleComponentPresentInOtherPages: false
    };
const componentTierManagerScope = {
    serverResponsePremiumFeaturesData: {}
};

const getIsIncompatibleComponentPresentList = ({
    premiumFeaturesDataJson,
    componentsMap,
    subscriptionType,
    currentPageId,
    siteMap,
    publicPages
}) => {
    let otherPages = {};
    let otherTemplates = {};

    let premiumFeaturesDataJsonLocal = cloneDeep(premiumFeaturesDataJson);
    let isCurrentPagePresentInPublicPages = publicPages.some(page => page.pageId === currentPageId);

    if (premiumFeaturesDataJsonLocal.standardFeaturesData) {
        let standardFeaturesOtherPagesData = premiumFeaturesDataJsonLocal.standardFeaturesData.pages;
        delete standardFeaturesOtherPagesData[currentPageId];
        otherPages = mergeFeaturesData(otherPages, standardFeaturesOtherPagesData);
        otherTemplates = mergeFeaturesData(otherTemplates, premiumFeaturesDataJsonLocal.standardFeaturesData.templates);
    }

    if (premiumFeaturesDataJsonLocal.premiumFeaturesData) {
        let premiumFeaturesOtherPagesData = premiumFeaturesDataJsonLocal.premiumFeaturesData.pages;
        delete premiumFeaturesOtherPagesData[currentPageId];
        otherPages = mergeFeaturesData(otherPages, premiumFeaturesOtherPagesData);
        otherTemplates = mergeFeaturesData(otherTemplates, premiumFeaturesDataJsonLocal.premiumFeaturesData.templates);
    }

    if (premiumFeaturesDataJsonLocal.ecommerceFeaturesData) {
        let ecommerceFeaturesOtherPagesData = premiumFeaturesDataJsonLocal.ecommerceFeaturesData.pages;
        delete ecommerceFeaturesOtherPagesData[currentPageId];
        //delete current page id
        otherPages = mergeFeaturesData(otherPages, ecommerceFeaturesOtherPagesData);
        otherTemplates = mergeFeaturesData(otherTemplates, premiumFeaturesDataJsonLocal.ecommerceFeaturesData.templates);
    }

    let currentPageComponentsKindList = [...new Set(Object.values(componentsMap as Record<string, any>).map(componentMap => componentMap.kind))];
    let isIncompatibleComponentPresentInCurrentPage = isCurrentPagePresentInPublicPages ?
        currentPageComponentsKindList.some((componentKind) => {
            if (isShopRelatedComponent(componentKind)) {
                return false;
            }
            // When subscription and componentkind is not compatible
            return !checkSubscriptionCompatibilityFromKind(componentKind, subscriptionType);
        }) : false;
    let isIncompatibleComponentPresentInOtherPages = checkIncompatibleComponents(
        siteMap,
        subscriptionType,
        {
            pages: otherPages,
            templates: otherTemplates,
            publicPages
        },
    );
    const isIncompatibleComponentPresent = isIncompatibleComponentPresentInCurrentPage || isIncompatibleComponentPresentInOtherPages;
    return {
        isIncompatibleComponentPresent,
        isIncompatibleComponentPresentInCurrentPage,
        isIncompatibleComponentPresentInOtherPages
    };
};

const isIncompatibleComponentCalculateReducer = ({ values:
         [
             siteMap,
             subscriptionType,
             publicPages,
             currentPageId,
             componentsMap,
             premiumFeaturesDataJson
         ],
state,
scope }) => {
    const {
        isIncompatibleComponentPresent,
        isIncompatibleComponentPresentInCurrentPage,
        isIncompatibleComponentPresentInOtherPages
    } = getIsIncompatibleComponentPresentList({
        premiumFeaturesDataJson,
        componentsMap,
        subscriptionType,
        currentPageId,
        siteMap,
        publicPages
    });
    return {
        state: {
            ...state,
            isPremiumComponentRequestInProgress: false,
            isIncompatibleComponentPresent,
            isIncompatibleComponentPresentInCurrentPage,
            isIncompatibleComponentPresentInOtherPages
        },
        scope: {
            ...scope,
            serverResponsePremiumFeaturesData: premiumFeaturesDataJson
        }
    };
};

/**
 * IMPORTANT: In order to write tests for epics, the updaters are called by their index number
 *      in the corresponding test files. Hence all new updates must always be added the bottom.
 */

const componentTierManagerEpic = makeEpic({
    defaultState: componentTierManagerState,
    defaultScope: componentTierManagerScope,
    valueActionType,
    afterUpdate: ({ prevState, nextState, nextScope }) => {
        const afterUpdateActions: Action[] = [];
        if (prevState.isPremiumComponentRequestInProgress !== nextState.isPremiumComponentRequestInProgress) {
            afterUpdateActions.push({ type: IS_PREMIUM_COMPONENT_REQUEST_IN_PROGRESS_CHANGED_ACTION });
        }
        return { state: nextState, scope: nextScope, afterUpdateActions };
    },
    updaters: [
        {
            conditions: [
                receiveOnly(subscriptionDataVat),
                NEW_COMPONENT_CHECK_COMPONENT_TIER_ON_DROP
            ],
            reducer: ({ state, scope, values: [subscriptionData, componentsData] }) => {
                let actionToDispatch: Action | null = null;
                const
                    subscriptionType = subscriptionData.subscriptionType,
                    { kind } = componentsData;

                actionToDispatch = {
                    type: CONTINUE_ADD_COMPONENT_AFTER_TIER_CHECK
                };

                if (
                    !checkComponentSubscriptionCompatibility(componentsRegistry[kind].componentTierData.componentTierType, subscriptionType)
                    && componentsRegistry[kind].componentTierData.dialogId
                ) {
                    const dialogProps = componentsRegistry[kind].componentTierData.dialogProps || {};
                    actionToDispatch = openDialog(componentsRegistry[kind].componentTierData.dialogId, dialogProps);
                }

                return {
                    state,
                    scope,
                    actionToDispatch
                };
            }
        },
        {

            conditions: [
                TRY_UPGRADE_DIALOG_CANCELLED
            ],
            reducer: ({ state, scope }) => {
                const multipleActionsToDispatch: Action[] = [];
                multipleActionsToDispatch.push(closeDialog());

                return {
                    state,
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {

            conditions: [
                receiveOnly(ComponentsMapSelector),
                receiveOnly(subscriptionDataVat),
                receiveOnly(siteDataValueActionType),
                receiveOnly(currentPageIdValueActionType),
                receiveOnly(publicPagesSelector),
                receiveOnly(AppConfigEpicVAT),
                TRY_UPGRADE_DIALOG_COMPLETED
            ],
            reducer: ({
                values: [componentsMap, subscriptionData, siteMap, currentPageId, publicPages, appConfig, payload],
                state,
                scope
            }) => {
                const multipleActionsToDispatch: Action[] = [];
                const premiumFeaturesDataJson = scope.serverResponsePremiumFeaturesData;
                const { subscriptionType } = subscriptionData;
                const { componentType, upgradeDialogMsg } = payload;

                const {
                    isIncompatibleComponentPresentInCurrentPage,
                    isIncompatibleComponentPresentInOtherPages
                } = getIsIncompatibleComponentPresentList({
                    premiumFeaturesDataJson,
                    componentsMap,
                    subscriptionType,
                    currentPageId,
                    siteMap,
                    publicPages
                });

                multipleActionsToDispatch.push(closeDialog());

                multipleActionsToDispatch.push(
                    !isTrialSubscription(subscriptionType) && partnerIsOnecom(appConfig.partnerName)
                        ? calculateUpgradeTierBeforeSeamlessUpgrade("TRY_NOW_DIALOG", componentType, upgradeDialogMsg)
                        : { type: CONTINUE_ADD_COMPONENT_AFTER_TIER_CHECK }
                );

                return {
                    state: {
                        ...state,
                        isIncompatibleComponentPresentInCurrentPage,
                        isIncompatibleComponentPresentInOtherPages
                    },
                    scope,
                    multipleActionsToDispatch
                };
            }
        },
        {

            conditions: [
                receiveOnly(ComponentsMapSelector),
                receiveOnly(subscriptionTypeSelector),
                receiveOnly(siteDataValueActionType),
                receiveOnly(currentPageIdValueActionType),
                receiveOnly(publicPagesSelector),
                DELETE_SELECTED_COMPONENTS
            ],
            reducer: ({ values: [componentsMap, subscriptionType, siteMap, currentPageId,
                publicPages], state, scope }) => {
                let premiumFeaturesDataJson = scope.serverResponsePremiumFeaturesData;
                const {
                    isIncompatibleComponentPresentInCurrentPage,
                    isIncompatibleComponentPresentInOtherPages
                } = getIsIncompatibleComponentPresentList({
                    premiumFeaturesDataJson,
                    componentsMap,
                    subscriptionType,
                    currentPageId,
                    siteMap,
                    publicPages
                });

                return {
                    state: {
                        ...state,
                        isIncompatibleComponentPresentInCurrentPage,
                        isIncompatibleComponentPresentInOtherPages
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                receiveOnly(subscriptionTypeSelector),
                receiveOnly(publicPagesSelector),
                receiveOnly(currentPageIdValueActionType),
                receiveOnly(ComponentsMapSelector),
                LOAD_PAID_FEATURES_DOC_SUCCESS
            ],
            reducer: isIncompatibleComponentCalculateReducer
        },
        {
            conditions: [
                receiveOnly(siteDataValueActionType),
                receiveOnly(subscriptionTypeSelector),
                receiveOnly(publicPagesSelector),
                receiveOnly(currentPageIdValueActionType),
                receiveOnly(ComponentsMapSelector),
                LOAD_PAID_FEATURES_DOC_SUCCESS,
                WORKSPACE_READY
            ],
            reducer: isIncompatibleComponentCalculateReducer
        },
        {
            conditions: [
                receiveOnly(ComponentsMapSelector),
                receiveOnly(subscriptionTypeSelector),
                receiveOnly(siteDataValueActionType),
                receiveOnly(currentPageIdValueActionType),
                publicPagesSelector
            ],
            reducer: ({ values: [componentsMap, subscriptionType, siteMap, currentPageId,
                publicPages], state, scope }) => {
                let premiumFeaturesDataJson = scope.serverResponsePremiumFeaturesData;

                const {
                    isIncompatibleComponentPresent,
                    isIncompatibleComponentPresentInCurrentPage,
                    isIncompatibleComponentPresentInOtherPages
                } = getIsIncompatibleComponentPresentList({
                    premiumFeaturesDataJson,
                    componentsMap,
                    subscriptionType,
                    currentPageId,
                    siteMap,
                    publicPages
                });
                return {
                    state: {
                        ...state,
                        isPremiumComponentRequestInProgress: false,
                        isIncompatibleComponentPresent,
                        isIncompatibleComponentPresentInCurrentPage,
                        isIncompatibleComponentPresentInOtherPages
                    },
                    scope
                };
            }
        },

        {
            conditions: [LOAD_PAID_FEATURES_DOC_FAILURE],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        isPremiumComponentRequestInProgress: false
                    },
                    scope
                };
            }
        },
        {
            conditions: [
                optional(WORKSPACE_DID_MOUNT),
                optional(PAGE_DATASET_SAVE_SUCCESS)
            ],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state
                    },
                    scope,
                    actionToDispatch: loadPaidFeaturesDoc()
                };
            }
        },
        {
            conditions: [SITE_DATA_UPDATE_SUCCESS],
            reducer: ({ values: [siteDataUpdatePatch], state, scope }) => {
                return {
                    state: {
                        ...state
                    },
                    scope,
                    actionToDispatch: (siteDataUpdatePatch.deletedPages && siteDataUpdatePatch.deletedPages.length) ?
                        loadPaidFeaturesDoc() : null
                };
            }
        },
        {
            conditions: [
                SET_PREMIUM_FEATURE_REQUEST_IN_PROGRESS
            ],
            reducer: ({ state, scope }) => {
                return {
                    state: {
                        ...state,
                        isPremiumComponentRequestInProgress: true
                    },
                    scope,
                };
            }
        },
        {
            conditions: [
                receiveOnly(ComponentsMapSelector),
                receiveOnly(publicPagesSelector),
                receiveOnly(sectionLinksSelector),
                receiveOnly(subscriptionDataVat),
                receiveOnly(shopPageIdsListVat),
                CALCULATE_UPGRADE_TIER_BEFORE_SEAMLESS_UPGRADE
            ],
            reducer: ({ values: [componentsMap, publicPages, sectionLinks, subscriptionData, shopPageIds, payload], state, scope }) => {
                let newState = state;

                let freeTierUpgradeSubscriptionType;

                let currentPageComponentsKindList = [...new Set(Object.values(componentsMap as Record<string, any>).map(componentMap => componentMap.kind))];

                const isBnE = () => {
                    if (payload.source) {
                        const source = payload.source.toLowerCase();

                        if (source.includes("componenttoolbar") &&
                            isShopRelatedComponent(payload.componentKindOrFeature)) {
                            return true;
                        }

                        if (source.includes("trackingtab")) {
                            return true;
                        }
                    }

                    return false;
                };

                if (isBnE()) {
                    freeTierUpgradeSubscriptionType = SubscriptionType.ECOMMERCE;
                } else {
                    const response = scope.serverResponsePremiumFeaturesData;

                    const values = [
                        ...Object.values(response.standardFeaturesData ? response.standardFeaturesData.pages : {}),
                        ...Object.values(response.premiumFeaturesData ? response.premiumFeaturesData.pages : {}),
                        ...Object.values(response.ecommerceFeaturesData ? response.ecommerceFeaturesData.pages : {}),
                        ...Object.values(response.standardFeaturesData ? response.standardFeaturesData.templates : {}),
                        ...Object.values(response.premiumFeaturesData ? response.premiumFeaturesData.templates : {}),
                        ...Object.values(response.ecommerceFeaturesData ? response.ecommerceFeaturesData.templates : {}),
                        [payload.componentKindOrFeature],
                    ];

                    const componentTypesUsedInSavedState = R
                        .flatten(values)
                        .map(usedComponentType => NewComponentTypes[usedComponentType]);

                    freeTierUpgradeSubscriptionType = getSubscriptionTypeForSeamlessUpgrade(
                        // currentPageComponentsKindList is used to look into current page components (Even in CAN_SAVE user savestauis state
                        [...componentTypesUsedInSavedState, ...currentPageComponentsKindList],
                        publicPages.length,
                        sectionLinks.length,
                        subscriptionData.subscriptionType,
                        shopPageIds.length
                    );

                    freeTierUpgradeSubscriptionType = getRecommendedSubscriptionType(
                        subscriptionData,
                        freeTierUpgradeSubscriptionType,
                        payload.source.toLowerCase(),
                    );
                }

                newState.freeTierUpgradeSubscriptionType = freeTierUpgradeSubscriptionType;

                const multipleActionsToDispatch: Action[] = [];

                if (payload.doSeamlessUpgrade) {
                    multipleActionsToDispatch.push(OpenSeamlessUpgradeDialog(
                        payload.source,
                        payload.upgradeContentBodyMsg,
                        payload.componentKindOrFeature
                    ));

                    multipleActionsToDispatch.push(
                        upgradeDialogOpenedAction({
                            feature: payload.componentKindOrFeature,
                            dialogId: dialogIds.SeamlessUpgradeDialogId
                        })
                    );

                    const upgradeData = subscriptionData.availableUpgrades?.find((upgrade) => (
                        upgrade.addonProductToUpgrade === freeTierUpgradeSubscriptionType
                    ));

                    if (upgradeData?.freeUpgradeAvailable) {
                        multipleActionsToDispatch.push(
                            updateDialog(dialogIds.SeamlessUpgradeDialogId, { isFreeUpgrade: true })
                        );
                    }
                    return {
                        state: newState,
                        scope,
                        multipleActionsToDispatch
                    };
                }

                return {
                    state: newState,
                    scope
                };
            }
        },
        {
            conditions: [
                subscriptionDataVat,
                receiveOnly(siteDataValueActionType),
                receiveOnly(siteSettingsValueActionType),
                receiveOnly(publicPagesSelector)
            ],
            reducer: ({ values: [subscriptionData, siteMap, siteSettings, publicPages], state, scope }) => {
                const { serverResponsePremiumFeaturesData } = scope;

                const isIncompatibleComponentPresent = checkIsIncompatibleComponentPresent(
                    siteMap,
                    subscriptionData,
                    serverResponsePremiumFeaturesData,
                    publicPages,
                    siteSettings,
                );

                return {
                    state: {
                        ...state,
                        isIncompatibleComponentPresent
                    },
                    scope
                };
            }
        }
    ]
});

const
    isIncompatibleComponentPresentSelector = withSelector(
        valueActionType, componentTierData => componentTierData.isIncompatibleComponentPresent
    ),
    isPremiumComponentRequestInProgressSelector = withSelector(
        valueActionType, componentTierData => componentTierData.isPremiumComponentRequestInProgress
    );

export {
    componentTierManagerEpic,
    isIncompatibleComponentPresentSelector,
    isPremiumComponentRequestInProgressSelector
};
