/* eslint-disable max-len */

import * as R from 'ramda';
import { all, fork, put, take } from 'redux-saga/effects'; // eslint-disable-line node/file-extension-in-import
import { SCHEDULE_ACTION } from '../../../redux/middleware/schedule/actionTypes';
import { selectGen, raceGen } from '../../../utils/saga/index';
import localStorage from "../../../utils/localStorage";
import { loadSiteData, openDialog, updateDialog, closeDialogByIdAction } from "../../App/actionCreators/index";
import {
    BACK_TO_EDITOR,
    SITE_DATA_LOAD_SUCCESS,
    SITE_DATA_BACKUP_LOAD_FAIL,
    SITE_DATA_BACKUP_LOAD_SUCCESS
} from "../../App/actionTypes";
import makeTakeEffectSaga from "../../../epics/makeTakeEffectSaga";
import checkUnsavedChangesSaga from "../../UnsavedChanges/checkUnsavedChangesSaga";
import * as actionTypes from "../actionTypes";
import Restore from "../actionCreators/Restore";
import { getRecords, namedBackup } from "../actionCreators/index";
import {
    introDialogId,
    upgradeDialogId,
    restoreDialogId,
    restoreSuccessDialogId,
    restoreFailedDialogId,
    loadErrorDialogId,
    namedBackupTitleDialogId
} from "../dialogs/index";
import type { AppState } from '../../../redux/modules/flowTypes';
import { DataSite } from "../../../../dal/model/index";
import { yesterdayTS } from "../../../utils/date";
import binarySearch from "../../../utils/binarySearch";
import { sendShownErrorEventToAecAC } from '../../App/actionCreators/sendEventToAecActionCreator';
import NamedBackup from '../../../../dal/model/NamedBackup';

const
    lsShowBackIntroDialog = 'ShowBackIntroDialog',
    lsRestoreStatus = 'restoreStatus',
    restoreRequestTimeout = 11000, // This must come from configuration
    restoreSuccessDialogHideTimeout = 3000;

const getPageIdToLoad = dataSite => {
    const items = dataSite.getItems();

    if (items.some(item => item.pageId === dataSite.homePageId)) {
        return dataSite.homePageId;
    } else if (items.length) {
        return items[0].pageId;
    }

    return null;
};

// HACK: ONEWEB-11966 - Don't show oldest savepoint if more than one savepoint are available
// (Intended for hiding the savepoint with no pages, would hide oldest savepoint even if pages exist)
const handleTimeline = R.curry((isBackupAndRestoreAllowed, timeline) => {
    if (!isBackupAndRestoreAllowed) {
        const lastYesterdayRecordIndex = binarySearch(
            timeline,
            yesterdayTS,
            (yesterdayTS, record) => yesterdayTS - record.time,
            true
        );
        return timeline.slice(lastYesterdayRecordIndex);
    }
    return timeline.length > 1 ? timeline.slice(1) : timeline;
});

const requestSiteData = function* (time) {
    // @ts-ignore
    yield put(loadSiteData(time));
    const { success: { payload: siteMap } } = yield* raceGen({
        success: take(SITE_DATA_BACKUP_LOAD_SUCCESS),
        failure: take(SITE_DATA_BACKUP_LOAD_FAIL)
    });
    return new DataSite(siteMap);
};

const onSetBackupRecord = function* (time) {
    const isBackupAndRestoreAllowed = yield* selectGen(({ backup: { isBackupAndRestoreAllowed } }: AppState) => isBackupAndRestoreAllowed);

    if (isBackupAndRestoreAllowed) {
        const dataSite = yield* requestSiteData(time),
            pageIdToLoad = getPageIdToLoad(dataSite);

        yield put({
            type: actionTypes.BACKUP_PREVIEW_RECORD_CHANGED,
            payload: {
                pageIdToLoad,
                timestamp: time
            }
        });
        return dataSite.homePageId;
    } else {
        /* Other users will see dialog for upgrade. */
        yield put(openDialog(upgradeDialogId));
        return null;
    }
};

const onRestore = function* (time) {
    const checked = yield* checkUnsavedChangesSaga();

    if (checked) {
        // Close the current dialog
        yield put(closeDialogByIdAction(restoreDialogId));

        // Hack to enable the isRestoring flag is the component state
        yield put({ type: actionTypes.BACKUP_RESTORE_REQUEST });

        // Have a delay before making the restore request. This is required because the server collects all changes
        // done with each 10 seconds and collates them to make single save point. Hence this gime gap required.
        yield put({ type: SCHEDULE_ACTION, payload: { actionToDispatch: Restore(time), timeout: restoreRequestTimeout } });

        const { success, failure } = yield* raceGen({
            success: take(actionTypes.BACKUP_RESTORE_SUCCESS),
            failure: take(actionTypes.BACKUP_RESTORE_FAIL)
        });
        //console.log('success', success);
        //console.log('failure', failure);
        if (success) {
            localStorage.set(lsRestoreStatus, 'success');

            // [TODO]generateScreenshot to be called from here
            //https://group-one.atlassian.net/browse/WBTGEN-34288
            // Irrespective of the status of request, just reload the page
            document.location.reload();
        }

        if (failure) {
            yield put(closeDialogByIdAction(restoreDialogId));
            yield put(openDialog(restoreFailedDialogId));
            yield put(sendShownErrorEventToAecAC('restore failed'));
        }
    }
};

const onBackupInitializedWorker = function* (): Generator<Object, void, any> {
    yield put(getRecords());

    if (localStorage.get(lsShowBackIntroDialog) !== true) {
        yield put(openDialog(introDialogId));
    }

    const
        { success } = yield* raceGen({
            success: take(actionTypes.BACKUP_RECORDS_SUCCESS),
            failure: take(actionTypes.BACKUP_RECORDS_FAIL)
        }),
        isBackupAndRestoreAllowed = yield* selectGen(({ backup: { isBackupAndRestoreAllowed } }: AppState) => isBackupAndRestoreAllowed),
        timeline = R.path(['payload', 'timeline'], success);

    if (Array.isArray(timeline)) {
        const
            ytLatestTimeline = handleTimeline(isBackupAndRestoreAllowed)(timeline),
            lastTime = R.pipe(R.last, R.prop('time'))(ytLatestTimeline.length ? ytLatestTimeline : timeline.slice(-1)),
            dataSite = yield* requestSiteData(lastTime),
            pageIdToLoad = getPageIdToLoad(dataSite);

        yield put({
            type: actionTypes.BACKUP_SET_PREVIEW_INTERFACE,
            payload: {
                pageIdToLoad,
                timestamp: lastTime,
                timeline: ytLatestTimeline
            }
        });
    } else {
        // Close the Backup intro dialog
        yield put(closeDialogByIdAction(introDialogId));

        // Open the generic error dialog
        yield put(openDialog(loadErrorDialogId));

        // Move user back to workspace
        yield put({ type: BACK_TO_EDITOR });
    }
};

const onToggleShowBackupIntroDialog = function* () {
    const { hideIntro } = yield* selectGen(({ backup }: AppState) => backup);
    yield put(updateDialog(introDialogId, { hideIntro }));
};

const onCloseBackupIntroDialog = function* () {
    const { hideIntro } = yield* selectGen(({ backup }: AppState) => backup);
    localStorage.set(lsShowBackIntroDialog, hideIntro);
    yield put(closeDialogByIdAction(introDialogId));
};

const onLoadShowRestoreSuccess = function* () {
    const restored = localStorage.get(lsRestoreStatus);

    if (restored === 'success') {
        localStorage.remove(lsRestoreStatus);
        yield put(openDialog(restoreSuccessDialogId));
        const actionToDispatch = closeDialogByIdAction(restoreSuccessDialogId);
        yield put({ type: SCHEDULE_ACTION, payload: { actionToDispatch, timeout: restoreSuccessDialogHideTimeout } });
    }
};
const onNameSavepoint = function* ({ payload, requestType } : { payload: NamedBackup, requestType: 'edit' | 'add'}) {
    yield put(namedBackup({ payload, requestType }));
    const { success } = yield* raceGen({
        success: take(actionTypes.BACKUP_NAME_SAVEPOINT_SUCCESS),
        failure: take(actionTypes.BACKUP_NAME_SAVEPOINT_FAILURE)
    });
    if (success) {
        yield put({ type: actionTypes.BACKUP_UPDATE_SAVEPOINT_PREVIEW, payload: { updatedTimeline: payload.timestamp, title: payload.title } });
    }
    yield put(closeDialogByIdAction(namedBackupTitleDialogId))
};

const listener = function* (): ForksSaga {
    yield all([
        fork(makeTakeEffectSaga('takeEvery', SITE_DATA_LOAD_SUCCESS, onLoadShowRestoreSuccess)),
        fork(makeTakeEffectSaga('takeEvery', actionTypes.BACKUP_CLICKED, onBackupInitializedWorker)),
        fork(makeTakeEffectSaga('takeEvery', actionTypes.BACKUP_SET_PREVIEW_RECORD, onSetBackupRecord)),
        fork(makeTakeEffectSaga('takeEvery', actionTypes.RESTORE_SAVEPOINT, onRestore)),
        fork(makeTakeEffectSaga('takeEvery', actionTypes.BACKUP_INTRO_TOGGLE_SHOW, onToggleShowBackupIntroDialog)),
        fork(makeTakeEffectSaga('takeEvery', actionTypes.BACKUP_INTRO_CLOSE_DIALOG, onCloseBackupIntroDialog)),
        fork(makeTakeEffectSaga('takeEvery', actionTypes.NAME_SAVEPOINT, onNameSavepoint))
    ]);
};

export {
    listener as default
};
