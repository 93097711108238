import { epicStateAppSel } from "../../../../epics/selectors";
import siteDataVAT from "./valueActionType";
import type { PathSelectorPath } from "../../../../utils/pathSelector";
import { Lit } from "../../../../lit";
import { findPageBySubType } from "../../../../../utils/findPageBySubType";

export const
    siteDataStateAppSel = (path: PathSelectorPath = []) => epicStateAppSel(siteDataVAT, path),
    siteDataItemsAppSel = siteDataStateAppSel([Lit.folder, Lit.items]);

/**
 * Returns the page ID of the bookings page or undefined
 */
export const selectBookingsPageId = (state): string | undefined =>
    findPageBySubType("containsBookings", siteDataItemsAppSel(state))?.pageId;
