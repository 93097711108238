import React from "react";
import { Msg } from "../../../view/intl";
import { openDialog } from "../../App/actionCreators";
import BookingsManagementDialogId from "./BookingsManagementDialog/dialogId";

const ctaOnClick = ({ dispatch }) => {
    return dispatch(openDialog(BookingsManagementDialogId));
};

const componentMainActions = {
    // hides the standard settings button
    // but makes the custom button red by default
    isCTAUrgent: () => true,

    // custom button that opens the bookings management iframe
    CtaButtonTextView: () => (
        <span style={{ color: "#0078c8" }}>
            <Msg k="component.bookings.cta.manage">Manage</Msg>
        </span>
    ),

    // open the bookings management dialog on button click
    ctaOnClick,
};

export { componentMainActions };
