import { getDAL } from "../../../../dal";
import AppConfig from "../../../utils/AppConfig";
import { getAppConfig } from "../../App/epics/appConfig/appConfig";

const appConfig = AppConfig(getAppConfig());
const appUrl = appConfig.get("oneWeb.apps.bookings.appUrl");

const getBookingsScriptUrl = (locale = '', isPreview = false) => {
    const domain = getDAL().getDomain();
    let url = `${appUrl}/api/v1/published/init.js?domain=${domain}`;
    if (locale) {
        url += `&locale=${locale}`;
    }
    if (isPreview) {
        url += '&preview=true';
    }
    return url;
}

const getBookingsUrlWithToken = (token: string) => {
    const url = new URL(appUrl);
    url.searchParams.append('bs-req-app', 'wsbEditor');
    url.searchParams.append('bs-shrt-tkn', token);
    return url.toString();
};

const getBookingsIframeToken = () => {
    return getDAL().getAppViewToken('bookings');
};

export {
    getBookingsScriptUrl,
    getBookingsIframeToken,
    getBookingsUrlWithToken
}
