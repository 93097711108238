import type { SideBarItemDef } from '../../SideBar/types';
import { DashboardBannerIds } from '../../Banners/constants';

import imageStyles from "../../Images/imageStyle.css";

export const MARKETGOO_PAGE_SIDE_BAR_ITEM_ID = 'MARKETGOO_PAGE_SIDE_BAR_ITEM_ID';

export const MarketgooSideBarItem: SideBarItemDef = {
    id: MARKETGOO_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.marketing,
    title: 'msg: common.marketgoo {SEO scan}'
};

export const MARKETGOO_POLLING_DURATION = 1000 * 2.5; // 2.5 seconds
export const MARKETGOO_POLLING_INITIAL_DELAY = 1000 * 2.5; // 2.5 seconds
export const GET_MARKETGOO_REPORT_DELAY = 1000 * 1.5; // 1.5 seconds
export const MARKETGOO_PREFERENCE_SAVING_CONFLICT_DELAY = 100;

export enum MARKETGOO_TASK_STATUS {
    pending = 'pending',
    empty = '',
    done = 'done'
}

export const MARKETGOO_BANNERS = [
    DashboardBannerIds.MARKETGOO_BANNER_RESCAN_FAILED,
    DashboardBannerIds.MARKETGOO_BANNER_REMEMBER_TO_PUBLISH,
    DashboardBannerIds.MARKETGOO_BANNER_DONT_SEE_INFORMATION,
];

export const SEO_SCAN_TASK_ID_KEY = 'SEO_SCAN_TASK_ID';
export const SEO_SCAN_TASK_PAGE_ID_KEY = 'SEO_SCAN_TASK_PAGE_ID';
export const GO_TO_EDITOR_TO_PUBLISH_KEY = 'GO_TO_EDITOR_TO_PUBLISH_KEY';
