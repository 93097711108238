import type { SideBarItemDef } from '../../SideBar/types';
import { formatNumberWithCommas } from './Editor/utils';

export const PRIVACY_POLICY_ITEM_ID = 'PRIVACY_POLICY_ITEM_ID';

export const PrivacyPolicyItem: SideBarItemDef = {
    id: PRIVACY_POLICY_ITEM_ID,
    title: 'msg: demo.signup.termsAndCondition.privacyPolicy {Privacy policy}'
};

export const MAX_ALLOWED_CHARACTERS = 50000;
export const MAX_ALLOWED_CHARACTERS_WITH_COMMA = formatNumberWithCommas(50000);

