/**
 * Recursively searches for a page which has the given subType
 * @param subTypeKey - String of the subType property to look for (e.g. "containsBookings")
 * @param pageItems - Array of the pages in which to look for
 * @returns The found page object or `null` if page wasn't found
 */
export const findPageBySubType = (subTypeKey: string, pageItems: Array<any>) => {
    let result: any = null;

    pageItems.some(page => {
        // the page must have a subType object and the wanted subType property must be truthy
        if (page.subType && page.subType[subTypeKey]) {
            result = page;
            return true; // Stop iteration
        }

        // in case the page has sub pages, look through them as well
        if (page.items && page.items.length > 0) {
            result = findPageBySubType(subTypeKey, page.items);

            if (result) {
                // Stop iteration if found
                return true;
            }
        }
        return false;
    });

    return result;
};
