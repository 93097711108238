import pathUtil from '../utils/path';
import urlBuild from '../utils/urlBuild';
import { PAGE_DATA_COMPONENT, SITE_DATA, SITE_SETTINGS, SOURCE_REPOSITORY, TEMPLATE_DATA_COMPONENT } from "./constants";
import { RAW_ASSET_ENDPOINT } from '../../server/shared/constants';
import type { SstockCollectionT } from "./flowTypes";
import { InstagramUrl } from "../../server/shared/instagram/InstagramUrl";
import { FacebookFeedUrl } from "../../server/shared/facebookFeed/FacebookFeedUrl";
import { FacebookChatUrl } from "../../server/shared/facebookChat/FacebookChatUrl";

const PREFIX = '/api/v1';

type Parts = Array<string>;

export class ApiUrl {
    /*::#*/domainBase: string;

    constructor(domainName: string) {
        this./*::#*/domainBase = pathUtil.resolve(PREFIX, domainName);
    }

    /* === REPOSITORY === */

    repository(...parts: Parts) { // eslint-disable-line class-methods-use-this
        return pathUtil.resolve(PREFIX, SOURCE_REPOSITORY, ...parts);
    }

    repositoryDoc(...parts: Parts) { return this.repository('doc', ...parts); }

    repositoryPage(id: string) {
        return this.repositoryDoc(PAGE_DATA_COMPONENT, id);
    }

    repositoryPageAll(id: string) {
        return urlBuild(this.repositoryDoc(PAGE_DATA_COMPONENT, id), { query: { all: true } });
    }

    repositoryTemplateAllCopy(id: string) {
        return urlBuild(this.repositoryDoc(TEMPLATE_DATA_COMPONENT, id), { query: { all: 'copy' } });
    }

    repositorySite() { return this.repositoryDoc(SITE_DATA, 'site'); }

    repositoryWebspace(...parts: Parts) { return this.repository('webspace', ...parts); }

    /* === DOMAIN === */

    domain(...parts: Parts) {
        return pathUtil.resolve(this./*::#*/domainBase, ...parts);
    }

    doc(...parts: Parts) { return this.domain('doc', ...parts); }

    site() { return this.doc(SITE_DATA, 'site'); }

    publish(query?: Record<string, any>) { return urlBuild(this.domain('publish'), { query }); }

    webspace(...parts: Parts) { return this.domain('webspace', ...parts, '/') + '/'; }

    rawAsset() { return this.domain(RAW_ASSET_ENDPOINT); }

    onewebmedia() { return this.webspace('onewebmedia'); }

    subscriptionStatus() { return this.domain('subscriptionStatus'); }

    googleAdsCampaign() { return this.domain('googleAdsCampaign'); }

    encryptedDomain() { return this.domain('encryptedDomain'); }

    wsbVideoStatus() { return this.domain('wsbVideoStatus'); }

    subscriptionStatusBackupRestore() { return this.domain('subscriptionStatusBackupRestore'); }

    ownerInfo() { return this.domain('ownerInfo'); }

    ownerInfoEmail() { return urlBuild(this.domain('ownerInfo'), { query: { email: true } }); }

    page(id: string) { return this.doc(PAGE_DATA_COMPONENT, id); }

    pageAll(id: string) {
        return urlBuild(this.page(id), { query: { all: true } });
    }

    template(id: string) { return this.doc(TEMPLATE_DATA_COMPONENT, id); }

    templateAll(id: string) { return urlBuild(this.template(id), { query: { all: true } }); }

    copyTemplate(id: string) { return this.doc(TEMPLATE_DATA_COMPONENT, id, 'copy'); }

    copyTemplateRef(id: string) {
        return urlBuild(
            this.copyTemplate(id),
            { query: { templateWithPages: true } }
        );
    }

    deleteTemplateAll(id: string) {
        return this.doc(TEMPLATE_DATA_COMPONENT, id, 'all');
    }

    templatePages(templateId: string) { return this.doc('templatePages', templateId); }

    templatesWithPages() { return this.doc('templatesWithPages'); }

    repositoryTemplateRefList() {
        return urlBuild(this.repositoryDoc(TEMPLATE_DATA_COMPONENT), { query: { stubs: true } });
    }

    repositoryPageStubs() {
        return urlBuild(
            this.repositoryDoc(PAGE_DATA_COMPONENT),
            { query: { type: PAGE_DATA_COMPONENT, stubs: true } },
        );
    }

    repositoryTemplateStubs() {
        return urlBuild(
            this.repositoryDoc(TEMPLATE_DATA_COMPONENT),
            { query: { type: TEMPLATE_DATA_COMPONENT, stubs: true } },
        );
    }

    shutterStockCategories(collection: SstockCollectionT) {
        return this.domain(`sstock/images/${collection}/categories`);
    }

    unsplashCategories() {
        return this.domain('unsplash/images/categories');
    }

    sstockFreeImagesDownloads() { return this.domain('sstock/images/free/downloads'); }

    unsplashImagesDownloads() { return this.domain('unsplash/images/downloads'); }

    sstockImages(collection: SstockCollectionT) { return this.domain(`sstock/images/${collection}`); }

    unsplashImages() { return this.domain('unsplash/images'); }

    getFreeOneComVideos() { return this.domain('freeonecom/videos'); }

    generateScreenshot() { return this.domain('createSiteTemplateThumbnail'); }

    sstockImagesFullPurchage() { return this.domain('sstock/images/full/purchase'); }

    sstockImagesFullPrice() { return this.domain('sstock/images/full/price'); }

    batch() { return this.doc('batch'); }

    getAllDocsWithComponentWrappedInTable() { return this.domain('getAllDocsWithComponentWrappedInTable'); }

    siteSettings() { return this.doc(SITE_SETTINGS, 'settings'); }

    migrate() { return this.domain('migrate'); }

    preferences(...parts: Parts) { return this.domain('preferences', '1', ...parts); }

    preferencesWebEditorUiState() { return this.preferences('webEditorUiState'); }

    checkIfComponentHasReservedContent() { return this.domain('checkIfComponentHasReservedContent'); }

    analyticsDependencies(query?: number) { return urlBuild(this.domain('analyticsDependencies'), { query }); }

    analyticsGoalAchieved(query?: string) { return urlBuild(this.domain('analyticsGoalAchieved'), { query }); }

    // eslint-disable-next-line
    getListOfSectionBlocks() { return pathUtil.resolve('/list-of-sections-blocks'); }

    // eslint-disable-next-line
    trialDomainAvailable(query: Record<string, any>) {
        return urlBuild(pathUtil.resolve('/trial/domainavailable/'), { query });
    }
    getTrialImportAvailability(query?: Record<string, any>) { return urlBuild(this.domain('trialImportAvailability'), { query }); }

    listOfDesigns() { // eslint-disable-line class-methods-use-this
        return '/list-of-designs';
    }
    // eslint-disable-next-line
    instagramUserMedia(query?: Record<string, any>) {
        return urlBuild(pathUtil.resolve(`${InstagramUrl.ROOT}${InstagramUrl.GET_USER_MEDIA}`), { query });
    }

    instagramAccessToken() { return urlBuild(this.domain(InstagramUrl.ROOT, InstagramUrl.GET_ACCESS_TOKEN)); }

    disconnectInstagram() { return urlBuild(this.domain(InstagramUrl.ROOT, InstagramUrl.MARK_USER_FOR_DELETE)); }

    // NOTE(facebookExchangeAccessToken): Both facebookfeed and facebookchat are internally calling facebookfeed url.
    // Currently, differentiating based on query isFacebookChat/isFacebookFeed. Not changing since this url is also
    // added in Facebook App setup
    // eslint-disable-next-line class-methods-use-this
    facebookExchangeAccessToken(query?: Record<string, any>) {
        return urlBuild(
            pathUtil.resolve(`${FacebookFeedUrl.ROOT}${FacebookFeedUrl.EXCHANGE_ACCESS_TOKEN}`),
            { query }
        );
    }

    // eslint-disable-next-line
    facebookFeedUserMedia(query?: Record<string, any>) {
        return urlBuild(pathUtil.resolve(`${FacebookFeedUrl.ROOT}${FacebookFeedUrl.GET_USER_MEDIA}`), { query });
    }

    facebookFeedPageVisibility(query?: Record<string, any>) {
        return urlBuild(this.domain(FacebookFeedUrl.ROOT, FacebookFeedUrl.TOGGLE_PAGE_VISIBILITY), { query });
    }

    facebookFeedAccessToken() { return urlBuild(this.domain(FacebookFeedUrl.ROOT, FacebookFeedUrl.GET_ACCESS_TOKEN)); }

    disconnectFacebookFeed() { return urlBuild(this.domain(FacebookFeedUrl.ROOT, FacebookFeedUrl.MARK_USER_FOR_DELETE)); }

    facebookChatAccessToken() { return urlBuild(this.domain(FacebookChatUrl.ROOT, FacebookChatUrl.GET_ACCESS_TOKEN)); }

    disconnectFacebookChat() { return urlBuild(this.domain(FacebookChatUrl.ROOT, FacebookChatUrl.MARK_PAGE_FOR_DELETE)); }

    getSocialMediaComponentsUsage() { return urlBuild(this.domain('/socialMediaComponentUsage')); }

    paidFeaturesDoc() { return this.doc('getPaidFeaturesDoc'); }

    getFeaturedProductsPageIds() { return this.doc('getFeaturedProductsPageIds'); }

    createComponentPage() { return this.doc("createComponentPage"); }

    generateAIText() { return this.domain("getGeneratedAIText"); }

    regenerateTextWithAI() { return this.domain("regenerateTextWithAI"); }

    getConceptForGmbKey() { return this.domain("getConceptForGmbKey"); }

    getGeneratedAIContent() { return this.domain("getGeneratedAIContent"); }

    generateSectionContent() { return this.domain("generateSectionContent"); }

    slackAlerts() { return this.domain('slackAlerts'); }

    synonyms() { return this.domain('synonyms'); }

    subscriptionMetadata() { // eslint-disable-line class-methods-use-this
        return pathUtil.resolve(PREFIX, '/subscription/metadata');
    }
}
