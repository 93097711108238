import Background from "../../../components/oneweb/Background/previewConfig";
import HoverBox from "../../../components/oneweb/HoverBox/previewConfig";
import Button from "../../../components/oneweb/Button/previewConfig";
import Image from "../../../components/oneweb/Image/previewConfig";
import Logo from "../../../components/oneweb/Logo/previewConfig";
import Gallery from "../../../components/oneweb/Gallery/previewConfig";
import ImageSlider from '../../../components/oneweb/ImageSlider/previewConfig';
import FacebookPage from "../../../components/oneweb/Facebook/previewConfig";
import ShareButtons from "../../../components/oneweb/ShareButtons/previewConfig";
import Menu from "../../../components/oneweb/Menu/previewConfig";
import Text from "../../../components/oneweb/Text/previewConfig";
import ContactForm from "../../../components/oneweb/ContactForm/previewConfig";
import Strip from "../../../components/oneweb/Strip/previewConfig";
import Section from "../../../components/oneweb/Section/previewConfig";
import Document from "../../../components/oneweb/Document/previewConfig";
import Table from '../../../components/oneweb/Table/previewConfig';
import Code from '../../../components/oneweb/Code/previewConfig';
import WebShop from '../../../components/oneweb/WebShop/previewConfig';
import WebShopPolicies from '../../../components/oneweb/WebShopMHF/WebShopPolicies/previewConfig';
import WebShopPaymentMethods from '../../../components/oneweb/WebShopMHF/WebShopPaymentMethods/previewConfig';
import Social from '../../../components/oneweb/Social/previewConfig';
import Svg from '../../../components/oneweb/Svg/previewConfig';
import { emailPreviewConfig } from '../../../components/oneweb/TextLike/Email/emailComponentConfig';
import { addressPreviewConfig } from '../../../components/oneweb/TextLike/Address/addressComponentConfig';
import { phonePreviewConfig } from '../../../components/oneweb/TextLike/Phone/phoneComponentConfig';
import type { ComponentsRegistry } from "../../../redux/modules/children/workspace/flowTypes";
import FeaturedProducts from '../../../components/oneweb/FeaturedProducts/previewConfig';
import InstagramGallery from "../../../components/oneweb/InstagramGallery/previewConfig";
import FacebookFeedGallery from "../../../components/oneweb/FacebookFeedGallery/previewConfig";
import OpeningHours from '../../../components/oneweb/OpeningHours/previewConfig';
import ProductWidget from '../../../components/oneweb/ProductWidget/previewConfig';
import Video from "../../../components/oneweb/Video/previewConfig";
import VideoFile from "../../../components/oneweb/Video/VideoFile/previewConfig";
import { WidgetsPreviewConfigs } from "../../../components/oneweb/Widgets/previewConfig";
import GoogleReviews from "../../../components/oneweb/GoogleReviews/previewConfig";
import Bookings from "../../../components/oneweb/Bookings/previewConfig";

const componentConfigs: Array<any> = [
    Strip,
    Section,
    Background,
    HoverBox,
    Video,
    VideoFile,
    Menu,
    Gallery,
    ImageSlider,
    Text,
    Button,
    ContactForm,
    FacebookPage,
    WebShop,
    WebShopPolicies,
    WebShopPaymentMethods,
    ShareButtons,
    Image,
    Logo,
    Code,
    Document,
    Table,
    Social,
    emailPreviewConfig,
    addressPreviewConfig,
    phonePreviewConfig,
    Svg,
    FeaturedProducts,
    InstagramGallery,
    FacebookFeedGallery,
    OpeningHours,
    ProductWidget,
    GoogleReviews,
    Bookings,
    ...WidgetsPreviewConfigs,
];

const registry: ComponentsRegistry = componentConfigs.reduce((registry, config) => {
    return {
        ...registry,
        [config.kind]: config
    };
}, {});

export default registry;
