import {
    makeCombineReducer,
    makeComponentBaseReducers,
    makeDefaultStateReducers
} from "../../../../redux/makeReducer";
import { BOOKINGS_DEFAULT_HEIGHT, BOOKINGS_DEFAULT_WIDTH } from "../constants";
import BookingsKind from "../kind";

export default makeCombineReducer({
    combineReducers: {
        ...makeComponentBaseReducers(BookingsKind),

        // component has these default dimensions when inserted
        ...makeDefaultStateReducers({
            width: BOOKINGS_DEFAULT_WIDTH,
            height: BOOKINGS_DEFAULT_HEIGHT
        })
    },
    handleActions: {}
});
