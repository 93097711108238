import { readWsbCustomerProfileCookiePayload } from "../../src/utils/wsbCustomerProfile/wsbCustomerProfileUtils";

export default class NamedBackup {
    timestamp!: string;
    customerType!: "diy" | "difm";
    title!: string;
    description!: string;
    userId!: string;
    userName!: string;

    constructor(data: {title: string, timestamp: string}) {
        this.timestamp = data.timestamp;
        this.title = data.title;

        const customerProfileData = readWsbCustomerProfileCookiePayload();
        const { customerType, userId, userName } = customerProfileData || {};
        this.customerType = customerType;
        this.userId = userId;
        this.userName = userName;
    }
}
