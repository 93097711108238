/* eslint-disable max-len */

import React from "react";
import cx from 'classnames';
import LoadingOverlay from '../../../view/common/LoadingOverlay/index';
import Toolbar from './Toolbar';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';
import RestoreScreen from './RestoreScreen';
import previewStyles from '../../Preview/View/Preview.css';
import styles from './Backup.css';
import type { BackupProps } from '../flowTypes';

export default ({
    isRestoring,
    panelExpanded,
    dimensions,
    pageIdToLoad,
    timestamp,
    timeline,
    loading,
    isBackupAndRestoreAllowed,
    uiLocale,
    opened,
    isMobileView,
    mobileView,
    siteMap,
    dispatch,
    subscriptionData,
}: BackupProps) => {
    if (isRestoring) {
        return <RestoreScreen />;
    } else if (opened) {
        return (
            <div className={cx(previewStyles.fullScreen, styles.window)}>
                {loading && <LoadingOverlay />}
                <Toolbar
                    isMobileView={isMobileView}
                    timestamp={timestamp}
                    timeline={timeline}
                    loading={loading}
                    hasPages={!!pageIdToLoad}
                    isBackupAndRestoreAllowed={isBackupAndRestoreAllowed}
                    dispatch={dispatch}
                />
                <LeftPanel
                    loading={loading}
                    dimensions={dimensions}
                    timestamp={timestamp}
                    timeline={timeline}
                    panelExpanded={panelExpanded}
                    isBackupAndRestoreAllowed={isBackupAndRestoreAllowed}
                    dispatch={dispatch}
                    hasPages={!!pageIdToLoad}
                    subscriptionData={subscriptionData}
                />
                <RightPanel
                    pageIdToLoad={pageIdToLoad}
                    timestamp={timestamp}
                    loading={loading}
                    isMobileView={isMobileView}
                    mobileView={mobileView}
                    dispatch={dispatch}
                    uiLocale={uiLocale}
                    siteMap={siteMap}
                />
            </div>
        );
    }

    return null;
};
