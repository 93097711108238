import { applyMappers, makePlainMappers } from "../../utils";
import type { AnyComponentData } from "../../../../src/components/App/flowTypes";
import { BookingsComponent } from "../../../../src/components/oneweb/Bookings/flowTypes";

const plainPropsMapper = makePlainMappers({});

export function to(componentData: AnyComponentData): BookingsComponent {
    return applyMappers(
        componentData,
        plainPropsMapper.to
    );
}

export function back(component: BookingsComponent): AnyComponentData {
    return applyMappers(
        component,
        plainPropsMapper.back
    );
}
