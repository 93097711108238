import {
    CREATE_COMPONENT_PAGE_FAILURE,
    CREATE_COMPONENT_PAGE_REQUEST,
    CREATE_COMPONENT_PAGE_SUCCESS,
    SAVE_SITE_DATA
} from "./actionTypes";
import type { UpdateSiteDataInput } from "../../../../../dal/flowTypes";
import CALL_API from "../../../../redux/middleware/api/CALL_API";

type Params = {
    saveSiteDataInput: UpdateSiteDataInput;
    afterSaveActionType?: string;
    afterSaveUpdateReason?: string;
};

export const saveSiteDataAction = ({
    saveSiteDataInput,
    afterSaveActionType,
    afterSaveUpdateReason
}: Params) => ({
    type: SAVE_SITE_DATA,
    payload: { saveSiteDataInput, afterSaveActionType, afterSaveUpdateReason }
});

/**
 * Dispatch action to call the create component page API
 * @param data - Data to send as request body
 */
export const createComponentPage = data => {
    return {
        [CALL_API]: {
            // types define the actions which are called
            // when the request is called, request succeeded and failed
            types: [
                CREATE_COMPONENT_PAGE_REQUEST,
                CREATE_COMPONENT_PAGE_SUCCESS,
                CREATE_COMPONENT_PAGE_FAILURE
            ],
            endpoint: "createComponentPage",
            endpointParams: data
        }
    };
};
