import makeEpic from "../../../../../epics/makeEpic";
import { createComponentPage } from "../../../../App/epics/siteData/actionCreators";
import { EXTENDED_PANEL_CLOSE } from "../../../../Panel/actionTypes";
import { checkUnsavedChangesAction } from "../../../../UnsavedChanges/actionCreators";
import {
    CANCEL_UNSAVED_CHANGES,
    UNSAVED_CHANGES_CHECKED
} from "../../../../UnsavedChanges/actionTypes";
import { BookingsInserterViewTypes } from "../types";
import { getBookingsInserterState } from "./actionCreators";
import {
    CREATE_BOOKINGS_PAGE,
    FETCH_BOOKINGS_STATE,
    GET_BOOKINGS_STATE_FAILURE,
    GET_BOOKINGS_STATE_SUCCESS,
    RESET_BOOKINGS_INSERTER
} from "./actionTypes";

export const bookingsInserterGlobalDataVAT = "BOOKINGS_INSERTER_GLOBAL_DATA_EPIC";

/**
 * Array of inserter view types which require the upgrade icon to be shown
 */
const BookingsInserterTypesWithUpgrade = [
    BookingsInserterViewTypes.TRY_FOR_FREE_BASIC,
    BookingsInserterViewTypes.TRY_FOR_FREE_PREMIUM,
    BookingsInserterViewTypes.ACTIVATE_BOOKINGS_PREMIUM,
    BookingsInserterViewTypes.ACTIVATE_BOOKINGS_BASIC
];

const initialState = {
    waitingForAPIResponse: true,
    inserterView: "",
    showUpgradeIcon: false,
    onboardingSteps: []
};

const initialScope = {
    isInserterOpen: false,
    bookingsPageParams: null
};

export const bookingsInserterEpic = makeEpic({
    defaultState: initialState,
    defaultScope: initialScope,
    valueActionType: bookingsInserterGlobalDataVAT,
    updaters: [
        {
            conditions: [FETCH_BOOKINGS_STATE],
            reducer: ({ state, scope }) => ({
                state: {
                    ...state,
                    waitingForAPIResponse: true
                },
                scope: {
                    ...scope,
                    isInserterOpen: true
                },
                actionToDispatch: getBookingsInserterState()
            })
        },
        {
            conditions: [GET_BOOKINGS_STATE_SUCCESS],
            reducer: ({ state, scope, values: [response] }) => {
                // show error screen if the inserter state is not defined
                const inserterView = Object.values(BookingsInserterViewTypes).includes(
                    response.data.state
                )
                    ? response.data.state
                    : BookingsInserterViewTypes.ERROR;
                const onboardingSteps = response.data.onboardingSteps || [];

                return {
                    // only process response if the inserter is still open
                    state: scope.isInserterOpen
                        ? {
                              ...state,
                              waitingForAPIResponse: false,
                              inserterView,
                              onboardingSteps,
                              showUpgradeIcon:
                                  BookingsInserterTypesWithUpgrade.includes(inserterView)
                          }
                        : state,
                    scope
                };
            }
        },
        {
            conditions: [GET_BOOKINGS_STATE_FAILURE],
            reducer: ({ state, scope }) => {
                return {
                    // only process response if the inserter is still open
                    state: scope.isInserterOpen
                        ? {
                              ...state,
                              waitingForAPIResponse: false,
                              inserterView: BookingsInserterViewTypes.ERROR
                          }
                        : state,
                    scope
                };
            }
        },
        {
            // reset the inserter when it closes
            conditions: [RESET_BOOKINGS_INSERTER],
            reducer: () => ({
                state: initialState,
                scope: initialScope
            })
        },
        {
            conditions: [CREATE_BOOKINGS_PAGE],
            reducer: ({ state, scope, values: [payload] }) => ({
                // before creating the page check for unsaved changes in the current page
                state,
                scope: {
                    ...scope,
                    bookingsPageParams: payload
                },
                actionToDispatch: checkUnsavedChangesAction()
            })
        },
        {
            conditions: [UNSAVED_CHANGES_CHECKED],
            reducer: ({ state, scope }) => {
                // only create the page when the unsaved changes were checked before add page was clicked
                if (scope.bookingsPageParams) {
                    return {
                        state,
                        multipleActionsToDispatch: [
                            { type: EXTENDED_PANEL_CLOSE },
                            createComponentPage(scope.bookingsPageParams)
                        ],
                        scope: {
                            ...scope,
                            bookingsPageParams: null
                        }
                    };
                }
                return { state, scope };
            }
        },
        {
            // scope flag needs to be reset when the unsaved changes dialog is canceled
            // so that the page is not created when the unsaved changes are checked next time
            conditions: [CANCEL_UNSAVED_CHANGES],
            reducer: ({ state, scope }) => ({
                state,
                scope: {
                    ...scope,
                    bookingsPageParams: null
                }
            })
        }
    ]
});
