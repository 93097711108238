import React from 'react';
import * as R from 'ramda';
import Background from "../../../components/oneweb/Background/index";
import HoverBox from "../../../components/oneweb/HoverBox/index";
import Button from "../../../components/oneweb/Button/index";
import Image from "../../../components/oneweb/Image/index";
import Gallery from "../../../components/oneweb/Gallery/index";
import InstagramGallery from "../../../components/oneweb/InstagramGallery/index";
import FacebookFeedGallery from "../../../components/oneweb/FacebookFeedGallery/index";
import ImageSlider from '../../../components/oneweb/ImageSlider/index';
import FacebookPage from "../../../components/oneweb/Facebook/index";
import Menu from "../../../components/oneweb/Menu/index";
import Text from "../../../components/oneweb/Text/index";
import Video from "../../../components/oneweb/Video/index";
import GoogleReviews from '../../../components/oneweb/GoogleReviews/index';
import VideoFile from "../../../components/oneweb/Video/VideoFile/index";
import ContactForm from "../../../components/oneweb/ContactForm/index";
import Document from "../../../components/oneweb/Document/index";
import Strip from "../../../components/oneweb/Strip/index";
import Section from "../../../components/oneweb/Section/index";
import Table from '../../../components/oneweb/Table/index';
import Code from '../../../components/oneweb/Code/index';
import Svg from '../../../components/oneweb/Svg/index';
import WebShop from '../../../components/oneweb/WebShop/index';
import WebShopPolicies from '../../../components/oneweb/WebShopMHF/WebShopPolicies/index';
import WebShopPaymentMethods from '../../../components/oneweb/WebShopMHF/WebShopPaymentMethods/index';
import { socialComponentConfig } from '../../../components/oneweb/Social/index';
import { logoComponentConfig } from '../../../components/oneweb/Logo/index';
import componentViewInitializers from "./componentViewInitializers/index";
import { memoMax } from '../../../../utils/memo';
import type { ComponentsRegistry } from "../../../redux/modules/children/workspace/flowTypes";
import { MaxComponentsOnPage } from "../../../constants/app";
import ShareButtons from '../../../components/oneweb/ShareButtons/index';
import { arrayToTrueMap } from '../../../utils/arrayToTrueMap';
import { emailComponentConfig } from '../../../components/oneweb/TextLike/Email/emailComponentWorkspaceConfig';
import { phoneComponentConfig } from '../../../components/oneweb/TextLike/Phone/phoneComponentWorkspaceConfig';
import { addressComponentConfig } from '../../../components/oneweb/TextLike/Address/addressComponentWorkspaceConfig';
import FeaturedProducts from '../../../components/oneweb/FeaturedProducts/index';
import ProductWidget from '../../../components/oneweb/ProductWidget/index';
import { getComponentTierDataFromComponentKind } from "../../../components/ComponentTierManager/utils";
import OpeningHours from '../../../components/oneweb/OpeningHours/index';
import FacebookChat from "../../../components/oneweb/FacebookChat/index";
import Bookings from "../../../components/oneweb/Bookings/index";

/* Widgets */
import { WidgetsRegistry } from "../../../components/oneweb/Widgets/registry";

const componentConfigs: Array<any> = [
    Text,
    Button,
    Strip,
    Section,
    Background,
    HoverBox,
    Image,
    Gallery,
    ImageSlider,
    Video,
    VideoFile,
    FacebookPage,
    socialComponentConfig,
    WebShop,
    WebShopPolicies,
    WebShopPaymentMethods,
    ContactForm,
    Document,
    logoComponentConfig,
    Code,
    Menu,
    Table,
    ShareButtons,
    emailComponentConfig,
    phoneComponentConfig,
    addressComponentConfig,
    Svg,
    GoogleReviews,
    FeaturedProducts,
    InstagramGallery,
    FacebookFeedGallery,
    OpeningHours,
    ProductWidget,
    FacebookChat,
    Bookings,
    ...WidgetsRegistry,
];

const
    I = R.identity,
    AlwaysFalse = () => false,
    defaultContextMenu = {
        items: [],
        defaultItemsOverride: {},
        layeringItemsOverride: {},
        reducer: I
    },
    defaultPageId = 'Not Implemented',
    defaultPropertiesPanel = {
        defaultPageId,
        pages: {
            [defaultPageId]: () => <div>Not implemented</div>
        }
    },
    defaultComponentMainActions = {
        editView: () => <div>Not implemented</div>
    },
    defaultComponentPanelActions = {
        show: false
    },
    componentViewPartNames = Object.keys(componentViewInitializers),

    registry: ComponentsRegistry = componentConfigs.reduce((registry, config) => {
        const
            { kind } = config,
            record: Record<string, any> = componentViewPartNames.reduce((record, componentPartName) => ({
                ...record,
                [componentPartName]: config[componentPartName] || componentViewInitializers[componentPartName](config)
            }), {});

        record.requireConfigurationOnDrop = config.requireConfigurationOnDrop;
        record.configurationDialog = config.configurationDialog; // todo WBTGEN-1314 - Rename registry.record.configurationDialog to registry.record.configurationDialogId

        record.contextMenu = R.merge(defaultContextMenu, config.contextMenu);

        record.label = config.label;

        if (config.propertiesPanel && !config.propertiesPanel.defaultPageId) {
            throw Error(`You should provide propertiesPanel.defaultPageId for ${kind}`);
        }

        record.propertiesPanel = config.propertiesPanel || defaultPropertiesPanel;

        record.componentMainActions = config.componentMainActions || defaultComponentMainActions;

        record.componentPanelActions = config.componentPanelActions || defaultComponentPanelActions;

        record.saga = config.saga;
        record.reducer = config.reducer || I;
        record.reducerForExtension = config.reducerForExtension;
        record.dependsOn = config.dependsOn;
        record.controlsDependsOn = config.controlsDependsOn;
        record.adjustComponentOnAdd = config.adjustComponentOnAdd || I;
        record.copyHook = config.copyHook || I;
        record.adjustComponentOnPaste = config.adjustComponentOnPaste || I;
        record.onBeforeComponentAdded = config.onBeforeComponentAdded || I;
        record.adjustmentHookConfig = config.adjustmentHookConfig;
        record.adjustAfterPushdownHookConfig = config.adjustAfterPushdownHookConfig;
        record.componentMainActionsIsNotDependentOnSelectedComponent =
            config.componentMainActionsIsNotDependentOnSelectedComponent;
        record.calcRenderProps = config.calcRenderProps &&
            memoMax(
                (componentId,
                    component,
                    componentExtension,
                    componentDependencies,
                    isWorkspace,
                    base,
                    inEditMode,
                    isSelected,
                    isResizing,
                    selectedParentTheme,
                    parentSection,
                    mhfCmpData) => ({
                    ...config.calcRenderProps({
                        componentId,
                        component,
                        componentExtension,
                        componentDependencies,
                        isWorkspace,
                        inEditMode,
                        isSelected,
                        isResizing,
                        selectedParentTheme,
                        parentSection,
                        mhfCmpData
                    }),
                    base
                }),
                MaxComponentsOnPage
            );
        record.willHandleMouseMoveAfterMouseDownInEditMode =
            config.willHandleMouseMoveAfterMouseDownInEditMode || AlwaysFalse;
        record.onMouseMoveAfterMouseDownInEditMode = config.onMouseMoveAfterMouseDownInEditMode || I;
        record.validateComponentAdd = config.validateComponentAdd || (() => null);
        record.minDimensions = config.minDimensions;
        record.shouldKeepAspectRatio = config.shouldKeepAspectRatio || (() => false);
        record.errorMsgsOnAdd = config.errorMsgsOnAdd || {};
        record.mobileEditorConfig = config.mobileEditorConfig || null;
        record.hideFromInserter = config.hideFromInserter || null;
        record.shouldBeAvailableUnderMore = config.shouldBeAvailableUnderMore || false;
        record.sequenceNumberUnderMore = config.sequenceNumberUnderMore || -1;
        record.showNewLabel = config.showNewLabel || null;
        record.changeOldComponentDataBeforeImport = config.changeOldComponentDataBeforeImport || null;
        record.nonUndoableActionTypes = config.nonUndoableActionTypes || [];
        record.debouncedActionTypes = config.debouncedActionTypes || [];
        record.componentTierData = getComponentTierDataFromComponentKind(kind);

        return {
            ...registry,
            [kind]: record
        };
    }, {});

export default registry;

export const NonUndoableActions = arrayToTrueMap(
    Object.keys(registry).reduce((acc, kind) => [...acc, ...registry[kind].nonUndoableActionTypes], [] as string[])
);

export const DebouncedActions = arrayToTrueMap(
    Object.keys(registry).reduce((acc, kind) => [...acc, ...registry[kind].debouncedActionTypes], [] as string[])
);

export function getComponentMainActionsConfig(kind: string) {
    return registry[kind].componentMainActions;
}

export function getComponentPanelActionsConfig(kind: string) {
    return registry[kind].componentPanelActions;
}

export function getPropertiesPanelConfig(kind: string) {
    return registry[kind].propertiesPanel;
}
