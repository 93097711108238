export enum BookingsInserterViewTypes {
    ADD_BOOKINGS_PAGE = "ADD_BOOKINGS_PAGE",
    GET_STARTED = "GET_STARTED",
    TRY_FOR_FREE_BASIC = "TRY_FOR_FREE_BASIC",
    TRY_FOR_FREE_PREMIUM = "TRY_FOR_FREE_PREMIUM",
    ACTIVATE_BOOKINGS_PREMIUM = "ACTIVATE_BOOKINGS_PREMIUM",
    ACTIVATE_BOOKINGS_BASIC = "ACTIVATE_BOOKINGS_BASIC",
    ONBOARDING_STEPS = "ONBOARDING_STEPS",
    ALL_DONE = "ALL_DONE",
    ERROR = "ERROR"
}

export enum BookingsInserterButtonTypes {
    CREATE_PAGE = "CREATE_PAGE",
    MANAGE = "MANAGE",
    GO_TO_PAGE = "GO_TO_PAGE",
    TRY_AGAIN = "TRY_AGAIN",
    CLOSE_INSERTER = "CLOSE_INSERTER"
}

export interface BookingsInserterDetails {
    [key: string]: AnyReactComponent;
}

type OnboardingStep = {
    step: "ADD_AVAILABILITY" | "ADD_SERVICES" | "PUBLISH_TO_SITE";
    status: "COMPLETED" | "PENDING";
};

export type BookingsOnboardingSteps = OnboardingStep[];
