import * as R from 'ramda';
import makeStyle from "./makeStyle";
import { WEBSPACE_PREFIX } from '../../../../dal/index';
import isStretchComponentKind from '../isStretchComponentKind';
import type { BackgroundCalcRenderProps, BackgroundCalcRenderPropsResult } from "./flowTypes";
import { getQualityAssetUrlParam, isJpeg, isPng } from '../Image/utils';
import * as mp from "../../../mappers/path";
import { getAssetUrl } from "../../../utils/assetUtils";
import { getThemeRulesForBackground } from "../../ThemeGlobalData/themeRules";
import { isHeaderOrFooterSection } from "../Section/utils";
import { VideoUtils } from "../../../utils/fileUtils";

const
    isBackgroundImageMissing = (backgroundImage, missingAssetUrls) => {
        const WEBSPACE_PREFIX_REPLACE = WEBSPACE_PREFIX.replace(':', '');
        return !!(backgroundImage &&
            missingAssetUrls.length > 0 &&
            missingAssetUrls.some(
                missingAssetUrl => Array.isArray(backgroundImage) && backgroundImage.every(
                    image => image.indexOf(missingAssetUrl.replace(WEBSPACE_PREFIX, WEBSPACE_PREFIX_REPLACE)) > -1
                )
            )
        );
    };

const updateCmpBgScrollEffectForWS = (cmp, scrollEffect, isWorkspace) => {
    return (isWorkspace && scrollEffect) ? R.assocPath(mp.styleBackgroundAssetDataScrollEffect, null, cmp) : cmp;
};

const backgroundCalcRenderProps: BackgroundCalcRenderProps = ({
    isWorkspace,
    component,
    paddingBottom,
    componentDependencies: {
        missingAssetUrls,
        workspaceBBoxWidth,
        splitData,
        headerSectionId,
        topMostPageSectionId,
        shareHeaderAndFirstSectionBgImg,
        themeSettingsData = {},
        themeColorsData,
        defaultHeaderFooter,
    }
}): BackgroundCalcRenderPropsResult => {
    const assetData = component.style
            && component.style.background
            && component.style.background.assetData,
        asset = assetData
            && assetData.asset,
        overlay = assetData && assetData.overlay,
        dimVideoBg = overlay === "block",
        { kind, width: componentWidth, height, selectedTheme, selectedGradientTheme, selectedBorderTheme, modernLayout, title } = component;
    const isHeaderSection = component.id === headerSectionId;
    const isTopMostSection = component.id === topMostPageSectionId;
    const disableSectionBgImage = shareHeaderAndFirstSectionBgImg
        && shareHeaderAndFirstSectionBgImg.enabled
        && (isTopMostSection || isHeaderSection);
    const disableSectionBgColor = shareHeaderAndFirstSectionBgImg
        && shareHeaderAndFirstSectionBgImg.enabled
        && isTopMostSection;
    const { autoColorMode } = themeSettingsData as any,
        // Undefined and Null are both different. Null means transparent. Currently transparent is used only for gallery background.
        backgroundColorFromTheme =
            autoColorMode && selectedTheme && themeColorsData ?
                themeColorsData[getThemeRulesForBackground(selectedTheme, themeColorsData).background] : undefined,
        backgroundGradientColorFromTheme =
            autoColorMode && selectedGradientTheme && themeColorsData ? themeColorsData[selectedGradientTheme] : undefined,
        getBorderColorFromTheme = () => {
            if (autoColorMode && themeColorsData) {
                if (selectedBorderTheme) {
                    return themeColorsData[selectedBorderTheme];
                } else if (selectedTheme) {
                    return themeColorsData[getThemeRulesForBackground(selectedTheme, themeColorsData).text];
                }
            }
            return undefined;
        },
        borderColorFromTheme = getBorderColorFromTheme(),
        width = isStretchComponentKind(kind) ? workspaceBBoxWidth : componentWidth;

    const
        scrollEffect = (assetData && assetData.scrollEffect) || null,
        updatedCmp = updateCmpBgScrollEffectForWS(component, scrollEffect, isWorkspace);

    if (asset && VideoUtils.isVideoFile(asset.url)) {
        return {
            videoStyle: {
                width: '100%',
                height: '100%',
                objectFit: assetData.size === 100 ? 'scale-down' : assetData.size,
                objectPosition: assetData.position.join(" "),
                opacity: disableSectionBgColor ? 0 : 1,
                position: 'absolute',
                top: 0,
                zIndex: 0
            },
            dimVideoBg,
            url: getAssetUrl(asset, {}),
            disableSectionBgImage,
            disableSectionBgColor,
            style: makeStyle(updatedCmp, {
                backgroundColorFromTheme,
                backgroundGradientColorFromTheme,
                borderColorFromTheme,
            }),
            showMissingImage: isBackgroundImageMissing(asset.url, missingAssetUrls),
            width,
            height,
            kind,
            scrollEffect: null,
            asset,
            isWorkspace,
            splitData,
            title,
            defaultHeaderFooter: isHeaderOrFooterSection(component) ? defaultHeaderFooter : null,
            isHeaderSection,
            paddingBottom: paddingBottom || 0,
            modernLayout,
        };
    }

    let backgroundImageQuery: any = getQualityAssetUrlParam(asset || {});
    // Resize the image to improve page-load performance
    if (isPng(asset?.contentType) || isJpeg(asset?.contentType)) {
        backgroundImageQuery.progressive = null;
        // reduce size if asset is displayed as a percentage of Original Size
        const bgSizePercent = parseInt(assetData && assetData.size, 10);
        if (!Number.isNaN(bgSizePercent) && bgSizePercent < 100) {
            const w = Math.ceil((asset.width * bgSizePercent) / 100);
            const h = Math.ceil((asset.height * bgSizePercent) / 100);
            backgroundImageQuery.resize = `${w},${h}`;
        }
    }

    const style = makeStyle(updatedCmp, {
        backgroundImageQuery,
        backgroundColorFromTheme,
        backgroundGradientColorFromTheme,
        borderColorFromTheme,
    });
    const showMissingImage = isBackgroundImageMissing(style?.backgroundImage, missingAssetUrls);

    return {
        disableSectionBgImage,
        disableSectionBgColor,
        style,
        showMissingImage,
        width,
        height,
        kind,
        scrollEffect,
        asset,
        isWorkspace,
        splitData,
        title: component.title,
        defaultHeaderFooter: isHeaderOrFooterSection(component) ? defaultHeaderFooter : null,
        isHeaderSection,
        paddingBottom: paddingBottom || 0,
        modernLayout,
    };
};

export default backgroundCalcRenderProps;
