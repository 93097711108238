import React from "react";

import type { SideBarItemDef } from '../../SideBar/types';
import { RightSideIcon } from "./RightSideIcon";
import { openShopHomeAction } from "./actions";

import imageStyles from '../../Images/imageStyle.css';
import { isOnlineShopCmpsNotAllowedBySubscriptionType } from "../../../../../../wbtgen/src/components/TemplateSelector_DEPRECATED/view/utils";

export const SHOP_PAGES_ROUTES = {
    HOME: "/",
    OPEN_ORDERS: "/orders/status/open",
    PRODUCTS: "/products",
    PRODUCTS_OUT_OF_STOCK: "/products/f/outofstock",
    CATEGORIES: "/products/categories",
    PRODUCT_FILTERS: "/settings/product-filters"
};

export const ONLINE_SHOP_PAGE_SIDE_BAR_ITEM_ID = 'ONLINE_SHOP_PAGE_SIDE_BAR_ITEM_ID';

export const OnlineShopSideBarItem: SideBarItemDef = {
    id: ONLINE_SHOP_PAGE_SIDE_BAR_ITEM_ID,
    icon: imageStyles.onlineShopIcon,
    rightSideIcon: <RightSideIcon />,
    title: 'msg: onlineShop {Online Shop}',
    clickHandler: ({ dispatch }) => {
        dispatch(openShopHomeAction());
    },
    disabled: () => isOnlineShopCmpsNotAllowedBySubscriptionType(),
    testId: "online-shop-sidebar-item"
};
