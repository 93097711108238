import type {
    MainThemeColorType,
    BlackThemeColorType,
    WhiteThemeColorType,
    AccentThemeColorType,
    TransparentColorType,
    ThemeColorTypes,
    BlackThemeBackgroundType,
    WhiteThemeBackgroundType,
    MainThemeBackgroundType,
    AccentedThemeBackgroundType,
    ThemeBackgroundType,
    PrimaryThemeButtonType,
    AlternateThemeButtonType,
    SecondaryThemeButtonType,
    ThemeButtonTypes,
} from './flowTypes';
import type { Color } from "../../mappers/flowTypes";
import p from "../../utils/pipePath";

/* Strings */
export const
    //themeTextClass
    THEME_TEXT_CLASS = "themeTextClass",
    THEME_TABLE_CLASS = 'themeTableClass',
    THEME_BUTTON_CLASS = 'themeButtonClass',
    THEME_BORDER_COLOR_CLASS = 'themeBorderColorClass',
    //Background
    BACKGROUND_THEME_BLACK: BlackThemeBackgroundType = "Black",
    BACKGROUND_THEME_WHITE: WhiteThemeBackgroundType = "White",
    BACKGROUND_THEME_MAIN: MainThemeBackgroundType = "Main",
    BACKGROUND_THEME_ACCENTED: AccentedThemeBackgroundType = "Accented",
    ALL_BACKGROUND_THEMES: ThemeBackgroundType[] = [
        BACKGROUND_THEME_ACCENTED,
        BACKGROUND_THEME_MAIN,
        BACKGROUND_THEME_WHITE,
        BACKGROUND_THEME_BLACK,
    ],
    // Button
    BUTTON_THEME_PRIMARY: PrimaryThemeButtonType = "primary",
    BUTTON_THEME_ALTERNATE: AlternateThemeButtonType = "alternate",
    BUTTON_THEME_SECONDARY: SecondaryThemeButtonType = "secondary",
    ALL_BUTTON_THEMES: ThemeButtonTypes[] = [BUTTON_THEME_PRIMARY, BUTTON_THEME_SECONDARY, BUTTON_THEME_ALTERNATE],
    // Link
    LINK_THEME_ACCENT = "accent",
    LINK_THEME_UNDERLINED = "underlined",
    LINK_THEME_TEXTLIKE = "textlike",
    // Link Labels
    LINK_THEME_STYLE_ACCENT = "msg: common.linkThemeStyleAccent {Accent (Theme)}",
    LINK_THEME_STYLE_UNDERLINED = "msg: common.linkThemeStyleUnderlined {Underlined (Theme)}",
    LINK_THEME_STYLE_TEXTLIKE = "msg: common.linkThemeStyleTextlike {Textlike (Theme)}",
    LINK_THEME_GLOBALSTYLE_ACCENT = "msg: common.linkThemeGlobalStyleAccent {Accented}",
    LINK_THEME_GLOBALSTYLE_UNDERLINED = "msg: common.linkThemeGlobalStyleUnderlined {Underlined}",
    LINK_THEME_GLOBALSTYLE_TEXTLIKE = "msg: common.linkThemeGlobalStyleTextlike {Textlike}";

export const MAIN_THEME_COLOR: MainThemeColorType = 'mainColor';
export const BLACK_THEME_COLOR: BlackThemeColorType = 'blackColor';
export const WHITE_THEME_COLOR: WhiteThemeColorType = 'whiteColor';
export const ACCENT_THEME_COLOR: AccentThemeColorType = 'accentColor';
export const TRANSPARENT_COLOR: TransparentColorType = 'transparent'; // Used only for rendering not saved in DB

export const THEME_COLOR_PALETTE: ThemeColorTypes[] = [
    ACCENT_THEME_COLOR,
    MAIN_THEME_COLOR,
];
export const THEME_COLORS: ThemeColorTypes[] = [
    ...THEME_COLOR_PALETTE,
    WHITE_THEME_COLOR,
    BLACK_THEME_COLOR,
];

// WBTGEN-17241: black color for theme: #3C3C3C
export const blackColorValueHSL: Color = ["HSL", 0.5645, 0, 0.23529411764705882, 1];
export const blackColorHex: string = "3C3C3C";
export const whiteColorValueHSL: Color = ["HSL", 0, 0, 1, 1];
export const whiteColorHex: string = "FFFFFF";
// Gray
export const grayColorHex: string = "7F7E7E"; // ["HSL", 0, 0.00395256916996046, 0.49607843137254903, 1]

export const THEME_BUTTON_PREVIEW_TEXT = {
    [BUTTON_THEME_PRIMARY]: 'msg: common.primary {Primary}',
    [BUTTON_THEME_SECONDARY]: 'msg: common.secondary {Secondary}',
    [BUTTON_THEME_ALTERNATE]: 'msg: common.alternate {Alternate}'
};

export const THRESHOLD_PARENT_OPACITY = 0.3;

// Paths
const
    operationDetails = 'operationDetails',
    perTemplateDetails = 'perTemplateDetails',
    computeThemeData = 'computeThemeData',
    themeColors = 'themeColors',
    dataPageSet = 'dataPageSet',
    pages = 'pages',
    siteMap = 'siteMap',
    folder = 'folder',
    items = 'items',
    siteSettings = 'siteSettings',
    localizationsByTemplateId = 'localizationsByTemplateId';

export const
    operationDetailsThemeColorsPath = p(operationDetails, perTemplateDetails, computeThemeData, themeColors),
    operationDetailsDataPageSetPath = p(operationDetails, perTemplateDetails, dataPageSet),
    operationDetailsDataPagesPath = p(operationDetails, perTemplateDetails, dataPageSet, pages),
    operationDetailsSiteMapPath = p(operationDetails, siteMap),
    operationDetailsSiteMapItemsPath = p(operationDetails, siteMap, folder, items),
    operationDetailsSiteSettingsPath = p(operationDetails, siteSettings),
    operationDetailsLocalizationsByTemplateIdPath = p(operationDetails, localizationsByTemplateId);

export const
    THEME_ACTIVATED_LOCAL_STORAGE_KEY = 'themeActivatedLocalStorageKey',
    THEME_ACTIVATED_WORKSPACE_STATE = 'THEME_ACTIVATED_WORKSPACE_STATE',
    THEME_ACTIVATED_WORKSPACE_STATE_LEFT_PANEL = 'THEME_ACTIVATED_WORKSPACE_STATE_LEFT_PANEL';

export const GA = {
    EVENT_CATEGORY: 'AUTO_COLOR',
    EVENT_ACTIONS: {
        TURN_ON: 'TURN_ON',
        TURN_OFF: 'TURN_OFF',
        PUBLISH: 'PUBLISH',
    },
    EVENT_LABEL: {
        PAGE_COUNT: 'PAGE_COUNT',
    },
};

// @ts-ignore
export const getThemeColorIndex = (themeColorName: string): number => THEME_COLORS.indexOf(themeColorName);
