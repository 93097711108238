import React from 'react';
import cx from 'classnames';
import styles from './CheckBox.css';
import type { CheckBoxPropTypes } from "./flowTypes";
import { injectIntl } from "../../intl/index";

export default injectIntl(({ isChecked, isGreen, onClick, disabled, className, height, width, ...rest }: CheckBoxPropTypes) => {
    let style, handleStyle;
    if (height && width) {
        let sliderPadding = Math.floor(height / 8);
        style = {
            height: `${height}px`,
            width: `${width}px`,
            borderRadius: `${height / 2}px`
        };

        handleStyle = {
            height: `${height - (sliderPadding * 2)}px`,
            width: `${height - (sliderPadding * 2)}px`,
            left: `${isChecked ? width - height + sliderPadding : sliderPadding}px`,
            top: `${sliderPadding}px`,
            borderRadius: `50%`
        };
    }
    return (
        <span
            style={style}
            className={cx({
                [styles.checked]: isChecked,
                [styles.blueBkgSlider]: !isGreen,
                [styles.disabled]: disabled
            }, styles.slider, className)}
            onClick={onClick}
            {...rest}
        >
            <span style={handleStyle} className={styles.sliderHandle} />
        </span>
    );
});
