
const SECTION_LINK = "sectionLink:",
    DT_CONCEPT_GENERATION_COUNT = "dt_generationCount",
    DT_GMBKEY = "dt_gmbKey",
    TRIAL_PRE_SELECTED_TEMPLATES = "trialPreSeletedTemplateList",
    TRIAL_ORIGIN = "trialOrigin",
    WIZARD_STEP_ID = {
        GMB_SELECTION: 'GMB_SELECTION',
        CATCH_ALL: 'CATCH_ALL',
        WEBSITE_PURPOSE: 'WEBSITE_PURPOSE',
        NAME: 'NAME',
        DESCRIPTION: 'DESCRIPTION',
        CONTACT_INFO: 'CONTACT_INFO',
        PRONOUNS: 'PRONOUNS',
        LANGUAGE: 'LANGUAGE'
    },
    TemplateTypes = {
        static: 'static',
        dynamic: 'dynamic',
    },
    TEXT_CHARACTER_LIMIT_ON_STEP_FIELD = {
        gmbKey: {
            min: 1,
            max: 100
        },
        businessName: {
            min: 1,
            max: 100
        },
        keywords: {
            min: 1,
            max: 250
        }
    },
    DEFAULT_ADDRESS_LOCATION = {
        addressLocation: { lat: 0, lng: 0 },
        addressViewport: { south: 0, west: 0, north: 0, east: 0 },
    },
    DEFAULT_COMPLETE_ADDRESS = {
        address: '',
        addressUrl: '',
        addressName: '',
        addressId: '',
        addressPlaceId: '',
        addressLocation: null,
        addressViewport: null,
        addressStreetAddress: '',
        addressCity: '',
        addressArea: '',
        addressZip: '',
        addressCountryCode: '',
        addressFloor: '',
    },
    STEP_NAMES = {
        BUSINESS_NAME: 'businessName',
        ABOUT_KEYWORDS: 'aboutKeywords'
    },
    FIELD_NAMES = {
        TITLE: 'title',
        DESC: 'desc',
        PLACEHOLDER: 'placeholder'
    },
    DYNAMIC_PREVIEW_TOASTER_TYPES = {
        SUCCESS: 'success',
        SUCCESS_TOASTER_KEY: 'dynamicPreviewRegenerateTextTSuccessToaster',
        ERROR_TOASTER_KEY: 'dynamicPreviewRegenerateTextTErrorToaster',
        REPEAT_ERROR: 'repeatError',
        MULTIPAGE_LOAD_ERROR: "MULTIPAGE_LOAD_ERROR",
        MULTIPAGE_IMPORT_ERROR: "MULTIPAGE_IMPORT_ERROR"
    },
    GENERIC_CATCH_ALL_NAME = 'genericCatchall',
    CATCH_ALL_CONCEPT_MAP = {
        'portfolioCatchall': 'catch_all_portfolio',
        'servicesCatchall': 'catch_all_service',
        'eventCatchall': 'catch_all_event',
        'physicalStoreCatchall': 'catch_all_physical_store',
        'onlineShopCatchall': 'catch_all_online_shop',
        'organizationCatchall': 'catch_all_organisation',
        'projectsCatchall': 'catch_all_project',
        'personalWebsiteCatchall': 'catch_all_personal_website',
        [GENERIC_CATCH_ALL_NAME]: 'catch_all_generic',
    },
    GPT = 'GPT';

export {
    SECTION_LINK,
    DT_CONCEPT_GENERATION_COUNT,
    DT_GMBKEY,
    TRIAL_PRE_SELECTED_TEMPLATES,
    TRIAL_ORIGIN,
    TEXT_CHARACTER_LIMIT_ON_STEP_FIELD,
    WIZARD_STEP_ID,
    DEFAULT_COMPLETE_ADDRESS,
    DEFAULT_ADDRESS_LOCATION,
    TemplateTypes,
    STEP_NAMES,
    FIELD_NAMES,
    DYNAMIC_PREVIEW_TOASTER_TYPES,
    GENERIC_CATCH_ALL_NAME,
    CATCH_ALL_CONCEPT_MAP,
    GPT
};
