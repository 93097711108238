export type BookingsStateType = {
    isDashboardOpen: boolean;
    appRoute: string;
};

export enum BookingsMessageTypes {
    // WSB
    WSB_CLOSE_REQUEST = "WSB_CLOSE_REQUEST",
    WSB_ROUTE_CHANGE_REQUEST = "WSB_ROUTE_CHANGE_REQUEST",
    // Bookings
    BOOKINGS_LOADING_COMPLETE = "BOOKINGS_LOADING_COMPLETE",
    BOOKINGS_RELOADING = "BOOKINGS_RELOADING",
    BOOKINGS_ROUTE_CHANGE = "BOOKINGS_ROUTE_CHANGE",
    BOOKINGS_CLOSE = "BOOKINGS_CLOSE",
    BOOKINGS_OPEN_WSB_EDITOR = "BOOKINGS_OPEN_WSB_EDITOR"
}

export type BookingsMiddlewareScope = {
    shopLoginStart: number;
    iframeLoadingStart: number;
};
