import { withSelector } from "../../epics/makeCondition";
import { ONBOARDING_VAT } from "./onboardingVat";
import type { OnboardingEpicState } from "./onboardingEpic";
import { makeEpicStateSelector } from '../../epics/makeEpic';
import type { AppState } from "../../redux/modules/flowTypes";

// use inside epic updaters
export const OnboardingStepSelector = withSelector(ONBOARDING_VAT, (state: OnboardingEpicState) => state.step);
export const isShopOnboardingSelector = withSelector(ONBOARDING_VAT, (state: OnboardingEpicState) => state.isShopOnboarding);
export const isBookingsOnboardingSelector = withSelector(ONBOARDING_VAT, (state: OnboardingEpicState) => state.isBookingsOnboarding);

// use inside components
const onboardingEpicStateSelector = makeEpicStateSelector(ONBOARDING_VAT);
export const onboardingTemplateInfoSelector = (state: AppState) => onboardingEpicStateSelector(state).template;
export const isShopOnboardingStateSelector = (state: AppState) => onboardingEpicStateSelector(state).isShopOnboarding;
export const isBookingsOnboardingStateSelector = (state: AppState) => onboardingEpicStateSelector(state).isBookingsOnboarding;
