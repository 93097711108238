import { ComponentNames } from "../constants";
import { componentMainActions } from "./componentMainActions";
import { BOOKINGS_MIN_HEIGHT, BOOKINGS_MIN_WIDTH } from "./constants";
import { BookingsCalcRenderProps } from "./flowTypes";
import selectedLanguageEpic from "../../TopBar/epics/languages/index";
import BookingsKind from "./kind";
import reducer from "./reducer";
import view from "./view/index";
import workspace from "./view/workspace";

export default {
    kind: BookingsKind,
    label: ComponentNames[BookingsKind],
    shortcutIconName: "bookings",

    // user can't make the component smaller than these dimensions
    minDimensions: {
        width: BOOKINGS_MIN_WIDTH,
        height: BOOKINGS_MIN_HEIGHT
    },
    dependsOn: {
        localeDetails: selectedLanguageEpic.reducer,
    },

    calcRenderProps: (props: BookingsCalcRenderProps) => {
        const locale = props.componentDependencies.localeDetails.current;
        return {
            ...props.componentDependencies,
            locale
        };
    },

    // config for default parameters (e.g. default dimensions)
    reducer,

    // config for what the component should render
    view,
    workspace,

    // mobile has the same view for now
    mobileEditorConfig: {
        kind: BookingsKind,
        view
    },

    // config for the settings buttons of the component
    componentMainActions
};
