/* eslint-disable max-len */

import { connect } from 'react-redux';
import cx from "classnames";
import React from "react";
import { injectIntl, Intl } from "../../../view/intl/index";
import * as styles from "./TrackingTab.css";
import * as siteSettingsStyles from '../SiteSettingsDialog/view/SiteSettingsDialog.css';
import * as actionTypes from "./actionTypes";
import { makeEpicStateSelector } from '../../../epics/makeEpic';
import type { TrackingGlobalDataType } from './trackingGlobalDataEpic';
import { trackingGlobalDataEpic } from './trackingGlobalDataEpic';
import type { TrackingServiceStateType } from "./trackingServiceEpic";
import { trackingServiceEpic } from "./trackingServiceEpic";
import CheckBoxSlider from "../../../view/common/CheckBox/CheckBoxSlider";
import Scrollbar from "../../../view/common/Scrollbar/index";
import { closeDialog, loadSubscriptionDataForGoogleAdsCampaign } from "../../App/actionCreators/index";
import { googleAdsStates } from "./constants";
import VerticalSpacer from '../../../view/common/VerticalSpacer';
import { ValidatedInput } from '../../../view/common/Input/ValidatedInputField';
import FeatureTypes from "../../../view/oneweb/registry/FeatureTypes";
import {
    checkEcommerceSubscriptionCompatibilityFromKind,
    isComponentOrFeatureTierDisabledByConfiguration,
    getEcommercePackageNameFromMetadata,
    isWsbFeatureEnabled
} from "../../ComponentTierManager/utils";
import { WsbFeature } from "../../../../../server/shared/WsbFeature";
import type { SubscriptionData } from "../../../redux/modules/flowTypes";
import { canUserBuyGoogleAds } from "./utils";
import { isTrialSubscription, isEcommerceSubscription } from "../../App/epics/subscriptionData/utils";
import { Flex } from "../../../view/reflexbox/index";
import { calculateUpgradeTierBeforeSeamlessUpgrade } from "../../ComponentTierManager/actionCreators";
import { GoogleAnalyticsSupportedFormatsTip } from "./GoogleAnalyticsSupportedFormatsTip";
import { FEATURE_FACEBOOK_PIXEL, FEATURE_GOOGLE_ANALYTICS } from '../../TopBar/view/Upgrade/tracking/contants';
import { SUPPORTED_FORMATS_HEADER_TEXT } from '../../../../../src/dashboard/src/components/Pages/Connections/GoogleAnalyticsSettings/constants';
import UpgradeText from "../../UpgradeText";

type Props = {
    intl: Intl,
    dispatch: Dispatch,
    tracking: TrackingGlobalDataType,
    service: TrackingServiceStateType,
    subscriptionData: SubscriptionData,
};

type GoogleAdsStateType = {
    stateKey: string,
    heading: string,
    content: string,
    linkText: string,
    linkUrl: string,
    tooltipText: string,
    informationContainer: boolean,
    iconName?: string,
};

const
    egPlaceholder = "msg: trackingTab.google.placeholder {e.g.}",
    learnMoreMsg = "msg: common.learnMore {Learn more}";

type RenderAreaProps = {
    intl: Intl;
    iconStyles: any;
    label: string;
    postLabelIcon?: string;
    spaceAboveMsg: number;
    msg: string;
    spaceBelowMsg: number;
    link: string;
    content: any
}

const renderArea = (props: RenderAreaProps) => {
    const { intl, iconStyles, label, postLabelIcon, spaceAboveMsg, msg, spaceBelowMsg, link, content } = props;

    return (
        <Flex>
            <div className={siteSettingsStyles.fieldLabel}>
                <div className={styles.appIconContainer}>
                    <div className={cx(styles.iconCommon, iconStyles)} />
                    <div className={siteSettingsStyles.fieldName}>
                        {intl.msgJoint(label)}
                    </div>
                    {postLabelIcon && <div className={postLabelIcon} />}
                </div>
                <VerticalSpacer y={spaceAboveMsg} />
                <div className={siteSettingsStyles.fieldDescription}>
                    {intl.msgJoint(msg)}
                </div>
                <VerticalSpacer y={spaceBelowMsg} />
                <a className={siteSettingsStyles.learnMoreLink} href={intl.msgJoint(link)} target="_blank">
                    {intl.msgJoint(learnMoreMsg)}
                </a>
            </div>
            {content}
        </Flex>
    );
};

export class TrackingTab extends React.Component<Props> {
    componentDidMount() {
        this.props.dispatch({ type: actionTypes.TRACKING_TAB_MOUNT });
    }

    renderBusinessECommerceLabel({ bannerMsg, feature }) {
        const
            {
                subscriptionData,
                dispatch,
                intl,
            } = this.props,
            isDemoUser = isTrialSubscription(subscriptionData.subscriptionType),
            subscriptionMetadata = subscriptionData?.metadata;
        const eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata);

        return (
            <div className={styles.becBannerContainer}>
                <div className={styles.becBannerHeader}>
                    {intl.msgJoint([
                        "msg:trackingTab.businessECommerce.blockLabel.header {{packageName} feature}",
                        { packageName: eCommercePackageNameFromMetadata || intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}') }
                    ])}
                </div>
                <div className={styles.becBannerMsg}>
                    {bannerMsg}
                </div>
                {!isDemoUser &&
                    <div
                        className={styles.becBannerUpgradeBtnContainer}
                        onClick={() => {
                            dispatch(closeDialog());
                            dispatch(calculateUpgradeTierBeforeSeamlessUpgrade("TrackingTab:UpgradeBtn", feature));
                        }}
                    >
                        <UpgradeText />
                    </div>}
            </div>
        );
    }

    renderEditableGoogleAnalytisContents() {
        const
            {
                tracking: { gaMeasurementId },
                service: { isGaMeasurementIdValid },
                intl, dispatch
            } = this.props;

        return (
            <div className={siteSettingsStyles.fieldContent}>
                <div className={siteSettingsStyles.inputLabel}>
                    {intl.msgJoint("msg: trackingTab.google.analytics.input.labelTrackingId {Google Analytics and Tag Manager tracking IDs}")}
                </div>
                <VerticalSpacer y={6} />
                <ValidatedInput
                    placeholder="msg: trackingTab.google.analytics.input.placeholder {To enter multiple IDs, separate them by commas}"
                    value={gaMeasurementId}
                    onChange={(gaMeasurementId) => {
                        dispatch({
                            type: actionTypes.GOOGLE_ANALYTICS_VALUE_CHANGE,
                            payload: { gaMeasurementId },
                            amendToSelf: true
                        });
                    }}
                    isInvalid={!isGaMeasurementIdValid}
                    invalidInputClass={siteSettingsStyles.validationLabel}
                    validationMsg="msg: trackingTab.google.analytics.input.validation {Check the tracking code and try again.}"
                    intl={intl}
                    changedByUserOnBlur={() => dispatch({ type: actionTypes.GOOGLE_ANALYTICS_VALUE_CHANGE_ON_BLUR })}
                />
                <VerticalSpacer y={8} />
                <div className={styles.supportedFormatsLabelContainer}>
                    <span className={styles.supportedFormatsLabel}>
                        {intl.msgJoint(SUPPORTED_FORMATS_HEADER_TEXT)}
                    </span>
                    <GoogleAnalyticsSupportedFormatsTip
                        intl={intl}
                    />
                </div>
            </div>
        );
    }

    displayGoogleAnalyticsArea(canUseGoogleAnalytics) {
        const
            { intl, subscriptionData } = this.props,
            subscriptionMetadata = subscriptionData?.metadata,
            eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata),
            googleAnalyticsLabel = "msg: trackingTab.google.analytics.labelAnalyticsAndTagManager {Google Analytics and Tag Manager}",
            googleAnalyticsMsg = "msg: trackingTab.google.analytics.msg {Get to know your visitors. See where they come from and how much time they spend on your website.}",
            howToGoogleAnalyticsLink = "msg: trackingTab.google.analytics.learnMoreLink  {https://help.one.com/hc/en-us/articles/360000130578}",
            content = canUseGoogleAnalytics
                ? this.renderEditableGoogleAnalytisContents()
                : this.renderBusinessECommerceLabel({
                    feature: FEATURE_GOOGLE_ANALYTICS,
                    bannerMsg: intl.msgJoint([
                        "msg: trackingTab.businessEcommerce.google.analytics.bannerMsg {Your current Website Builder plan does not include Google Analytics. Upgrade to {packageName} to get this and other great features.}",
                        { packageName: eCommercePackageNameFromMetadata || intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}') }
                    ])
                });
        return renderArea({
            intl,
            iconStyles: styles.googleAnalyticsIcon,
            label: googleAnalyticsLabel,
            postLabelIcon: canUseGoogleAnalytics ? '' : styles.purpleBoltIcon,
            spaceAboveMsg: 12,
            msg: googleAnalyticsMsg,
            spaceBelowMsg: 5,
            link: howToGoogleAnalyticsLink,
            content
        });
    }

    renderEditableFacebookPixelContents() {
        const
            {
                tracking: { pixelId },
                service: { isPixelIdValid },
                intl, dispatch
            } = this.props,
            fbPlaceholder = intl.msgJoint(egPlaceholder) + " 1234567789012345";

        return (
            <div className={siteSettingsStyles.fieldContent}>
                <div style={{ display: 'flex' }}>
                    <div className={siteSettingsStyles.inputLabel}>
                        {intl.msgJoint("msg: trackingTab.facebook.pixel.input.label {Facebook Pixel ID}")}
                    </div>
                </div>
                <VerticalSpacer y={6} />
                <ValidatedInput
                    placeholder={fbPlaceholder}
                    value={pixelId}
                    onChange={(pixelId) => {
                        dispatch({
                            type: actionTypes.FACEBOOK_PIXEL_VALUE_CHANGE,
                            payload: { pixelId },
                            amendToSelf: true
                        });
                    }}
                    isInvalid={!isPixelIdValid}
                    invalidInputClass={siteSettingsStyles.validationLabel}
                    validationMsg="msg: trackingTab.pixelId.input.validation {Check the Pixel ID code and try again.}"
                    intl={intl}
                    changedByUserOnBlur={() => dispatch({ type: actionTypes.FACEBOOK_PIXEL_VALUE_CHANGE_ON_BLUR })}
                />
            </div>
        );
    }

    displayFacebookPixelArea(canUseFacebokPixel) {
        const
            { intl, subscriptionData } = this.props,
            subscriptionMetadata = subscriptionData?.metadata,
            facebookPixelLabel = "msg: trackingTab.facebook.pixel.label {Facebook Pixel}",
            facebookPixelMsg = "msg: trackingTab.facebook.pixel.msg {Measure, optimise and build audiences for your advertising campaigns.}",
            howToFacebookPixelLink = "msg: trackingTab.facebook.pixel.learnMoreLink {https://help.one.com/hc/en-us/articles/360001411717}",
            eCommercePackageNameFromMetadata = getEcommercePackageNameFromMetadata(subscriptionMetadata),
            content = canUseFacebokPixel
                ? this.renderEditableFacebookPixelContents()
                : this.renderBusinessECommerceLabel({
                    feature: FEATURE_FACEBOOK_PIXEL,
                    bannerMsg: intl.msgJoint([
                        "msg: trackingTab.businessEcommerce.facebook.pixel.bannerMsg {Your current Website Builder plan does not include Facebook Pixel. Upgrade to {packageName} to get this and other great features.}",
                        { packageName: eCommercePackageNameFromMetadata || intl.msgJoint('msg: common.business_ecommerce {Business + Ecommerce}') }
                    ])
                });
        return renderArea({
            intl,
            iconStyles: styles.facebookPixelIcon,
            label: facebookPixelLabel,
            postLabelIcon: canUseFacebokPixel ? '' : styles.purpleBoltIcon,
            spaceAboveMsg: 12,
            msg: facebookPixelMsg,
            spaceBelowMsg: 5,
            link: howToFacebookPixelLink,
            content
        });
    }

    displayGoogleAdsArea() {
        const
            {
                intl,
                tracking: {
                    enableGoogleAds
                }
            } = this.props,
            googleAdsLabel = "msg: trackingTab.google.ads.label {Google Ads}",
            googleAdsMsg = "msg: trackingTab.google.ads.Msg {Promote your website online with ads that convert, and measure your success.}",
            howToGoogleAdsLink = "msg: trackingTab.google.ads.learnMoreLink {https://help.one.com/hc/en-us/articles/360000506898-How-do-I-add-the-Google-Ads-tracking-code-to-my-Website-Builder-site-}";

        return renderArea({
            intl,
            iconStyles: [styles.googleAdsIcon, [enableGoogleAds ? '' : styles.blackAndWhite]],
            label: googleAdsLabel,
            spaceAboveMsg: 13,
            msg: googleAdsMsg,
            spaceBelowMsg: 6,
            link: howToGoogleAdsLink,
            content: this.getGoogleAdsStateContent()
        });
    }

    findGoogleAdsState(): GoogleAdsStateType {
        const
            {
                tracking: {
                    enableGoogleAds
                },
                service: {
                    isSiteWitEnabled,
                    trackingScriptInPublishedPage
                }
            } = this.props;

        if (isSiteWitEnabled) {
            if (!trackingScriptInPublishedPage && enableGoogleAds) {
                return googleAdsStates.publishRemember;
            } else if (trackingScriptInPublishedPage && enableGoogleAds) {
                return googleAdsStates.allSet;
            } else if (trackingScriptInPublishedPage && !enableGoogleAds) {
                return googleAdsStates.publishToRemove;
            } else if (!trackingScriptInPublishedPage && !enableGoogleAds) {
                return googleAdsStates.codeDeactivated;
            }
        }
        return googleAdsStates.buyCampaignPromotion;
    }

    getGoogleAdsStateContent() {
        const
            { intl } = this.props,
            {
                heading, content, linkText, linkUrl, tooltipText, informationContainer, iconName
            } = this.findGoogleAdsState();

        return (
            <div className={cx(
                siteSettingsStyles.fieldContent,
                styles.promotionBaseContainer,
                [!informationContainer ? styles.promotionWarningContainer : styles.promotionInformationContainer]
            )}
            >
                <div style={{ display: 'flex' }}>
                    {iconName && <div className={cx(styles[iconName + 'Icon'])} />}
                    <div className={siteSettingsStyles.fieldName}>{intl.msgJoint(heading)}</div>
                    <div style={{ flex: 1 }} />
                    {this.displayGoogleAdsEnableAndSync(tooltipText)}
                </div>
                <VerticalSpacer y={13} />
                <div style={{ width: 348 }} className={siteSettingsStyles.fieldDescription}>
                    {intl.msgJoint(content)}
                </div>
                <a
                    href={linkUrl}
                    target="_blank"
                    className={styles.linkUrl}
                >
                    <div> {intl.msgJoint(linkText)} </div>
                    <div className={styles.diagonalArrowIcon} />
                </a>
            </div>
        );
    }

    displayGoogleAdsEnableAndSync(toolTipText) {
        const {
            dispatch,
            intl,
            tracking: {
                enableGoogleAds
            },
            service: {
                isSiteWitEnabled
            }
        } = this.props;

        return (
            <div style={{ display: 'flex' }}>
                { isSiteWitEnabled && <CheckBoxSlider
                    isChecked={enableGoogleAds}
                    onClick={() => { dispatch({ type: actionTypes.ENABLE_GOOGLE_ADS_TOGGLE }); }}
                    className={styles.checkBox}
                    key="box"
                /> }
                <div
                    className={styles.updateIcon}
                    data-title={intl.msgJoint(toolTipText)}
                    data-title-position="top"
                    onClick={() => {
                        dispatch(loadSubscriptionDataForGoogleAdsCampaign());
                    }}
                />
            </div>
        );
    }

    /**
     * IMPORTANT: The below two functions have been add have been added because of the bug which allowed
     * non B+E users to use Google Analytics and Facebook Pixel tracking. From March 24, 2021, these features
     * were categories as B+E featured. For backward compatibility, users with PREMIUM subscription from
     * before the day of release of B=E, they're allowed to continue using the aforementioned tracking features
     * without being required to upgrade to B+E subscription.
     */

    gaAllowedForNonBNEUsers() {
        const { subscriptionData: { subscriptionType }, tracking } = this.props;
        return (
            !isTrialSubscription(subscriptionType)
            && !isEcommerceSubscription(subscriptionType)
            && tracking
            && tracking.gaMeasurementId
            && tracking.gaMeasurementId.trim().length
        );
    }

    fbPixelAllowedForNonBNEUsers() {
        const { subscriptionData: { subscriptionType }, tracking } = this.props;
        return (
            !isTrialSubscription(subscriptionType)
            && !isEcommerceSubscription(subscriptionType)
            && tracking
            && tracking.pixelId
            && tracking.pixelId.trim().length
        );
    }

    render() {
        const { subscriptionData: { subscriptionType } } = this.props,
            showFacebookPixelArea = !isComponentOrFeatureTierDisabledByConfiguration(FeatureTypes.GOOGLE_ANALYTICS_TRACKING),
            showGoogleAnalyticsArea = !isComponentOrFeatureTierDisabledByConfiguration(FeatureTypes.FACEBOOK_PIXEL_TRACKING),
            canUseGoogleAnalytics = showGoogleAnalyticsArea && (
                checkEcommerceSubscriptionCompatibilityFromKind(FeatureTypes.GOOGLE_ANALYTICS_TRACKING, subscriptionType)
                || this.gaAllowedForNonBNEUsers()
            ),
            canUseFacebokPixel = showFacebookPixelArea && (
                checkEcommerceSubscriptionCompatibilityFromKind(FeatureTypes.FACEBOOK_PIXEL_TRACKING, subscriptionType)
                || this.fbPixelAllowedForNonBNEUsers()
            ),
            showGoogleAds = isWsbFeatureEnabled(WsbFeature.GoogleAds);

        return (
            <Scrollbar height="100%">
                <div className={siteSettingsStyles.bodyContainer}>
                    <VerticalSpacer y={49} />
                    { showGoogleAnalyticsArea &&
                        <React.Fragment>
                            {this.displayGoogleAnalyticsArea(canUseGoogleAnalytics)}
                            <VerticalSpacer y={46.5} />
                            <div className={siteSettingsStyles.hr} />
                            <VerticalSpacer y={30.5} />
                        </React.Fragment> }
                    { showFacebookPixelArea &&
                        <React.Fragment>
                            {this.displayFacebookPixelArea(canUseFacebokPixel)}
                            <VerticalSpacer y={30.5} />
                            <div className={siteSettingsStyles.hr} />
                            <VerticalSpacer y={30.5} />
                        </React.Fragment> }
                    {showGoogleAds && canUserBuyGoogleAds(subscriptionType) && this.displayGoogleAdsArea()}
                </div>
            </Scrollbar>
        );
    }
}
const trackingGlobalDataSelector = makeEpicStateSelector(trackingGlobalDataEpic.valueActionType);
const trackingServiceSelector = makeEpicStateSelector(trackingServiceEpic.valueActionType);

const mapStateToProps = (appState) => ({
    tracking: trackingGlobalDataSelector(appState),
    service: trackingServiceSelector(appState),
    subscriptionData: appState.subscriptionData,
});

export default injectIntl(connect(mapStateToProps)(TrackingTab));
