// import jssha from 'jssha';
import makeEpic from '../../../../epics/makeEpic';
import { withSelector } from '../../../../epics/makeCondition';
import {
    SITE_DATA_LOAD_SUCCESS,
    SITE_DATA_UPDATE_SUCCESS,
    SITE_DATA_UPDATE_FAILURE,
    SITE_DATA_LOAD_SUCCESS_NO_PAGES,
    SITE_DATA_LOAD_SUCCESS_PAGES_EXIST,
    SITE_DATA_UPDATE_SUCCESS_NO_PAGES,
    OWNER_INFO_SUCCESS,
    SITE_PAGES_LOAD_SUCCESS,
} from "../../actionTypes";
import { valueActionType } from './valueActionType';
import applyPersistedModelPatch from "../../../../../dal/utils/applyPersistedModelPatch";
import DataSite from "../../../../../dal/model/DataSite";
import {
    CREATE_COMPONENT_PAGE_SUCCESS, SAVE_SITE_DATA, SITE_DATA_LOAD_AFTER_FIX,
    SITE_DATA_MOBILE_VIEW_ACTIVATION_TOGGLE
} from "./actionTypes";
import { updateSiteData, closeDialog } from "../../actionCreators/index";
import type { EpicUpdaterReducerReturnType, MakeEpicConfig } from "../../../../epics/flowTypes";
import { SITE_DATA_UPDATE_EXTERNAL, SITE_DATA_UPDATE_INTERNAL } from "./updateReasons";
import isNeedToFixSiteDataHomePageId from "./utils/isNeedToFixSiteDataHomePageId";
import { saveSiteDataAction } from "./actionCreators";
import isTestEnv from "../../../../debug/isTestEnv";
import { getAppConfig } from '../appConfig/appConfig';
import { loadTrialImportAvailabilityAction } from "../../../../../demo/modules/import/actions";
import { createScheduledAction } from "../../../../redux/middleware/schedule/actionCreators";
import { facebookSdkInitAction } from "../../../SocialAccounts/Facebook/facebookSdkActions";
import { getSocialMediaComponentsUsageAction } from "../../../SocialAccounts/actions";
import { getPublicPages, getPublicSectionLinks } from "./utils/pages";
import updatePageRefs from "./utils/updatePageRefs";
import { generateScreenshot } from '../../../Workspace/epics/saveStatus/action';
import { POST_DYNAMIC_TEMPLATE_SUCCESS } from '../../../TemplateSelector_DEPRECATED/actionTypes';
import loadPageAction from '../../../PagesTree/actionCreators/loadPageAction';

type State = DataSite;

type Scope = {
    afterSaveActionType: string | null | undefined;
    afterSaveUpdateReason: string | null | undefined;
    prevSavingSite: DataSite | null | undefined;
};

const epicConfig: MakeEpicConfig<State, Scope, string> = {
    defaultScope: {
        afterSaveActionType: null,
        afterSaveUpdateReason: null,
        prevSavingSite: null,
    },
    dispatchOutside: true,
    valueActionType,
    updaters: [
        {
            conditions: [
                SITE_DATA_LOAD_SUCCESS
            ],
            reducer: ({
                state: defaultState,
                values: [site],
                scope
            }) => {
                if (!site.getAllPageRefs().length) {
                    return {
                        state: site,
                        actionToDispatch: { type: SITE_DATA_LOAD_SUCCESS_NO_PAGES, payload: true },
                        scope
                    };
                }

                const loadedWithPagesAction = { type: SITE_DATA_LOAD_SUCCESS_PAGES_EXIST, payload: true };
                /**
                 * Site data "homePageId" might be wrong due to bug in old WE that it's not cleared,
                 * when home page is deleted.
                 * Therefore we're fixing it here (saving to server as well).
                 */
                if (isNeedToFixSiteDataHomePageId(site)) {
                    if (!isTestEnv() || isTestEnv({ withTrace: true })) {
                        console.log(`Fixing site data home page id: ${site.homePageId}`); // eslint-disable-line no-console
                    }
                    const fixedSite = new DataSite({ ...site, homePageId: '' }); // clone
                    return {
                        state: defaultState,
                        multipleActionsToDispatch: [
                            saveSiteDataAction({
                                saveSiteDataInput: { site: fixedSite },
                                afterSaveActionType: SITE_DATA_LOAD_AFTER_FIX
                            }),
                            loadedWithPagesAction
                        ],
                        scope
                    };
                }

                return {
                    state: site,
                    updateReason: SITE_DATA_UPDATE_EXTERNAL,
                    scope,
                    actionToDispatch: loadedWithPagesAction
                };
            }
        },
        {
            conditions: [SITE_DATA_LOAD_AFTER_FIX],
            reducer: ({ state: fixedSite, scope }) => ({
                state: new DataSite(fixedSite), // clone to update the sate
                updateReason: SITE_DATA_UPDATE_EXTERNAL,
                scope
            })
        },
        {
            conditions: [SAVE_SITE_DATA],
            reducer: ({ state, values: [{ saveSiteDataInput, afterSaveActionType, afterSaveUpdateReason }] }) => {
                return ({
                    scope: { afterSaveActionType, afterSaveUpdateReason, prevSavingSite: state },
                    state: saveSiteDataInput.site,
                    actionToDispatch: updateSiteData(saveSiteDataInput),
                    updateReason: SITE_DATA_UPDATE_INTERNAL
                });
            }
        },
        {
            conditions: [SITE_DATA_UPDATE_SUCCESS],
            reducer: ({
                scope: { afterSaveActionType, afterSaveUpdateReason, ...restScope },
                state: siteData,
                values: [siteDataUpdatePatch]
            }) => {
                const result: EpicUpdaterReducerReturnType<State, Scope, string> = {
                    scope: { ...restScope, afterSaveActionType: null, afterSaveUpdateReason: null },
                    state: applyPersistedModelPatch(siteData, siteDataUpdatePatch.site),
                    updateReason: SITE_DATA_UPDATE_INTERNAL
                };

                if (!result.state.getAllPageRefs().length) {
                    result.actionToDispatch = { type: SITE_DATA_UPDATE_SUCCESS_NO_PAGES, payload: true };
                    return result;
                }

                if (afterSaveActionType) result.actionToDispatch = { type: afterSaveActionType };
                if (afterSaveUpdateReason) result.updateReason = afterSaveUpdateReason;

                return result;
            }
        },

        {
            conditions: [POST_DYNAMIC_TEMPLATE_SUCCESS],
            reducer: ({ scope, values: [onImportResponse] }) => {
                const { site } = onImportResponse;
                let newState = new DataSite(site);
                return {
                    scope,
                    state: newState,
                    updateReason: SITE_DATA_UPDATE_INTERNAL
                };
            }
        },
        {
            conditions: [SITE_DATA_UPDATE_SUCCESS],
            reducer: ({ state, scope }) => {
                return {
                    scope,
                    state,
                    actionToDispatch: getAppConfig().oneWeb.generateScreenshot ? generateScreenshot() : null
                };
            }
        },
        {
            conditions: [SITE_DATA_UPDATE_FAILURE],
            reducer: ({ scope }) => {
                if (!scope.prevSavingSite) {
                    throw new Error('Expecting scope.prevSavingSite to be DataSite.');
                }

                return {
                    state: scope.prevSavingSite,
                    scope: {
                        ...scope,
                        prevSavingSite: null,
                    },
                    updateReason: SITE_DATA_UPDATE_INTERNAL,
                };
            },
        },
        {
            conditions: [SITE_DATA_MOBILE_VIEW_ACTIVATION_TOGGLE],
            reducer: ({ state: epicState, scope }) => {
                const site = new DataSite({ ...epicState, activateMobileView: !epicState.activateMobileView });
                const multipleActionsToDispatch: Action[] = [
                    saveSiteDataAction({
                        saveSiteDataInput: { site },
                    })
                ];
                if (epicState.activateMobileView) {
                    multipleActionsToDispatch.push(closeDialog());
                }
                return {
                    state: epicState,
                    multipleActionsToDispatch,
                    scope
                };
            }
        },
        {
            conditions: [
                SITE_DATA_LOAD_SUCCESS,
                OWNER_INFO_SUCCESS,
            ],
            reducer: ({ state, values: [site, ownerData], scope }) => {
                // site.fonts.length is a quick way to identify has customer ever imported template
                if ((!site.fonts || !site.fonts.length) && !site.folder.items.length) {
                    return {
                        state,
                        actionToDispatch: createScheduledAction({
                            actionToDispatch: loadTrialImportAvailabilityAction(encodeURIComponent(ownerData.email)),
                            timeout: 500, // Dispatch the action with .5 sec delay to let the application states settle.
                        }),
                        scope,
                    };
                }
                return {
                    state,
                    scope,
                };
            }
        },
        {
            conditions: [
                SITE_DATA_LOAD_SUCCESS,
            ],
            reducer: ({ state, scope }) => {
                if (isTestEnv()) {
                    return {
                        state,
                        scope,
                    };
                } else {
                    return {
                        state,
                        multipleActionsToDispatch: [
                            facebookSdkInitAction(),
                            getSocialMediaComponentsUsageAction()
                        ],
                        scope,
                    };
                }
            }
        },
        {
            conditions: [SITE_PAGES_LOAD_SUCCESS],
            reducer: ({ state: siteData, scope, values: [sitePages] }) => {
                return {
                    state: updatePageRefs(siteData, sitePages),
                    scope,
                    updateReason: SITE_DATA_UPDATE_INTERNAL,
                };
            }
        },
        {
            // called when the createComponentPage API was successful
            conditions: [CREATE_COMPONENT_PAGE_SUCCESS],
            reducer: ({ scope, values: [response] }) => {
                // load the new component page
                const multipleActionsToDispatch = [loadPageAction(response.componentPageId)];

                return {
                    // update the site data with the new site map from the api
                    state: new DataSite(response.siteMap),
                    scope,
                    updateReason: SITE_DATA_UPDATE_EXTERNAL,
                    multipleActionsToDispatch
                };
            }
        }
    ]
};

const siteDataEpic = makeEpic(epicConfig),
    homePageSelector = withSelector(
        valueActionType, siteData => siteData.getOptionalPageRefByPageId(siteData.homePageId)
    ),
    publicPagesSelector = withSelector(valueActionType, getPublicPages),
    sectionLinksSelector = withSelector(valueActionType, getPublicSectionLinks);

export {
    siteDataEpic as default,
    siteDataEpic,
    homePageSelector,
    publicPagesSelector,
    sectionLinksSelector
};
