/* eslint-disable max-len */

import makeEpic from '../../../epics/makeEpic';
import { receiveOnly } from '../../../epics/makeCondition';
import undoRedoValueActionType from '../../TopBar/epics/undoRedoButtons/valueActionType';
import workspaceBBoxValueActionType from '../../Workspace/epics/workspaceBBox/valueActionType';
import topMostHandleValueActionType from '../../Workspace/epics/topMostHandle/valueActionType';
import mousePositionWithRespectToTemplateAreaValueActionType from '../../Workspace/epics/mousePositionWithRespectToTemplateArea/valueActionType'; // eslint-disable-line max-len
import browserDimensionsValueActionType from '../../App/epics/browserDimensions/valueActionType';
import componentsClipboardValueActionType from '../../App/epics/componentsClipboard/valueActionType';
import { isPointInsideBBox } from '../../../utils/bBox';
import * as handleKinds from '../../../utils/handle/kinds';
import getComponentsBBox from '../../../utils/componentsMap/getComponentsBBox';
import { isComponentHandle, isComponentsRelatedHandle } from '../../../utils/handle/index';
import {
    getContextMenuGroupsForWorkspace,
    getContextMenuGroupsForComponent,
    getContextMenuGroupsForPagesTreeItem,
    getContextMenuGroupsForLeftPanelComponentsTab,
    getMenuPosition,
    getContextMenuGroupsForMobileView,
} from '../view/utils';
import * as actionTypes from '../actionTypes';
import * as appActionTypes from '../../App/actionTypes';
import { PAGE_TREE_PAGE_OPEN_CONTEXT_MENU } from "../../PagesTree/Tree/actionTypes";
import { CTX_MENU_UNDO, CTX_MENU_REDO, CTX_MENU_PASTE, CTX_MENU_LOCK_TO_BOTTOM,
    CTX_MENU_UNLOCK_FROM_BOTTOM } from '../view/constants';
import { getWrapOptions } from '../../Workspace/epics/wrap/wrapOptions';
import * as propertiesPanelActionTypes from '../../PropertiesPanel/epic/actionTypes';
import valueActionType from './valueActionType';
import type { DataSiteItem } from "../../../../dal/model/utils/dataSiteItemUtils";
import {
    ReceiveOnlyComponents, ReceiveOnlySelectedComponentIdSelector,
    ReceiveOnlySelectedComponentsIdsSelector
} from "../../Workspace/epics/componentsEval/selectorActionTypes";
import {
    GHOST_CODE_COMPONENT_RIGHT_CLICK
} from "../../Workspace/CodeComponentsRenderer/CodeComponentsRendererActionTypes";
import { COMPONENTS_DELETED } from "../../Workspace/actionTypes";
import { handlesUnderMouseValueActionType } from "../../Workspace/epics/handlesUnderMouse/valueActionType";
import type { Handles } from "../../../redux/modules/children/workspace/flowTypes";
import MOBILE_VIEW_EDITOR_HOVERING_AND_SELECTION from "../../MobileViewEditor/epics/hoveringAndSelection/valueActionType";
import mobileViewEditorVAT from '../../MobileViewEditor/epics/reorder/valueActionType';
import { isLockToBottomEnabled, isUnlockFromBottomEnabled } from '../../MobileViewEditor/calcLockToBottomEnablement';
import relationsValueActionType from '../../Workspace/epics/relations/valueActionType';
import TOP_BAR_POPUP from '../../TopBar/epics/popup/valueActionType';
import { componentAttachmentsVAT } from '../../Workspace/epics/componentAttachements/valueActionType';
import { splitSectionDecorationValueActionType } from '../../oneweb/Section/epics/splitSectionDecoration/valuaActionType';
import { ReceiveOnlyAttachments } from "../../Workspace/epics/componentAttachements/selectorActionTypes";
import { isModernLayoutSection } from "../../ModernLayouts/preview_utils";
import { someComponentsPlacedInModernSection } from "../../ModernLayouts/utils";
import { getWebShopStripCmpIds } from '../../ModernLayouts/layoutsData/webshopMHFDataUtils';

const
    defaultState = {
        opened: false,
        position: { x: -9999, y: -9999 },
        contextMenuGroups: []
    },
    handleKindsWhitelist = [handleKinds.Component, handleKinds.Workspace/*, handleKinds.LeftPanel*/],
    getContextMenuGroups = (kind,
        componentsMap,
        componentsIds,
        pasteDisabled,
        pageTreeItem,
        workspaceBBox,
        clickedInsideSelectedComponentsBBox,
        wrapOptions,
        { undoDisabled, redoDisabled },
        attachments,
        canBeSplit,
        mousePosition) => {
        if (pageTreeItem) {
            return getContextMenuGroupsForPagesTreeItem(pageTreeItem);
        } else if (isComponentsRelatedHandle(kind)) {
            if (componentsIds.length > 1) {
                return getContextMenuGroupsForWorkspace(
                    componentsMap,
                    componentsIds,
                    workspaceBBox,
                    clickedInsideSelectedComponentsBBox,
                    pasteDisabled,
                    attachments,
                );
            }

            return getContextMenuGroupsForComponent(
                componentsMap,
                componentsIds[0],
                {
                    [CTX_MENU_UNDO]: { disabled: undoDisabled },
                    [CTX_MENU_REDO]: { disabled: redoDisabled },
                    [CTX_MENU_PASTE]: { disabled: pasteDisabled }
                },
                workspaceBBox,
                wrapOptions,
                attachments,
                canBeSplit,
                mousePosition,
            );
        } else if (kind === handleKinds.LeftPanel) {
            return getContextMenuGroupsForLeftPanelComponentsTab();
        }
        return [];
    },
    componentUnderMouseIsNotSelected = (handlesUnderMouse: Handles, selectedComponentsIds: Array<string>) =>
        handlesUnderMouse.every(
            handle => selectedComponentsIds.every(
                selectedComponentId => handle.componentsIds[0] !== selectedComponentId
            )
        );

export default makeEpic({
    defaultState,
    valueActionType,
    updaters: [
        {
            conditions: [
                appActionTypes.APP_RIGHT_MOUSE_DOWN,
                receiveOnly(topMostHandleValueActionType),
                receiveOnly(handlesUnderMouseValueActionType),
                ReceiveOnlySelectedComponentsIdsSelector
            ],
            reducer: ({
                values: [position, topMostHandle, handlesUnderMouse, selectedComponentsIds],
                state: epicState
            }) => {
                if (topMostHandle && topMostHandle.kind && handleKindsWhitelist.indexOf(topMostHandle.kind) > -1) {
                    let
                        componentsIds = [],
                        shouldSelectComponentId = null;

                    if (isComponentHandle(topMostHandle.kind)) {
                        if (componentUnderMouseIsNotSelected(handlesUnderMouse, selectedComponentsIds)) {
                            componentsIds = topMostHandle.componentsIds;
                            shouldSelectComponentId = topMostHandle.componentsIds[0];
                        } else {
                            componentsIds = selectedComponentsIds;
                        }
                    }

                    return {
                        state: epicState,
                        actionToDispatch: {
                            type: actionTypes.SHOW_CONTEXT_MENU,
                            payload: { position, kind: topMostHandle.kind, componentsIds, shouldSelectComponentId }
                        }
                    };
                }

                return { state: epicState };
            }
        },
        {
            conditions: [
                GHOST_CODE_COMPONENT_RIGHT_CLICK
            ],
            reducer: ({ values: [{ position, componentId }], state: epicState }) => {
                return {
                    state: epicState,
                    actionToDispatch: {
                        type: actionTypes.SHOW_CONTEXT_MENU,
                        payload: { position, kind: handleKinds.Component, componentsIds: [componentId] }
                    }
                };
            }
        },
        {
            conditions: [
                propertiesPanelActionTypes.PROPERTIES_PANEL_SHOW_CONTEXT_MENU,
                ReceiveOnlySelectedComponentIdSelector
            ],
            reducer: ({ values: [{ position, isMobileV }, selectedComponentId], state: epicState }) => ({
                state: epicState,
                actionToDispatch: {
                    type: isMobileV ? actionTypes.SHOW_MOBILE_CONTEXT_MENU : actionTypes.SHOW_CONTEXT_MENU,
                    payload: { position, kind: handleKinds.Component, componentsIds: [selectedComponentId] }
                }
            })
        },
        {
            conditions: [PAGE_TREE_PAGE_OPEN_CONTEXT_MENU],
            reducer: ({ values: [payload], state }) => {
                const actionToDispatch: Action<DataSiteItem> = {
                    type: actionTypes.SHOW_CONTEXT_MENU,
                    payload
                };

                return { state, actionToDispatch };
            }
        },
        {
            conditions: [
                actionTypes.SHOW_CONTEXT_MENU,
                ReceiveOnlyComponents,
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(componentsClipboardValueActionType),
                receiveOnly(workspaceBBoxValueActionType),
                receiveOnly(mousePositionWithRespectToTemplateAreaValueActionType),
                receiveOnly(undoRedoValueActionType),
                receiveOnly(componentAttachmentsVAT),
                receiveOnly(splitSectionDecorationValueActionType),
            ],
            reducer: ({
                values: [
                    { position, kind, componentsIds, pageTreeItem },
                    { componentsMap },
                    { height: browserHeight, width: browserWidth },
                    components,
                    workspaceBBox,
                    mousePosition,
                    undoRedoState,
                    { attachments },
                    { canBeSplit }
                ]
            }) => {
                let
                    selectedComponents = [],
                    bbox,
                    pasteDisabled = false,
                    clickedInsideSelectedComponentsBBox = false,
                    wrapOptions = {},
                    { x, y } = mousePosition;

                if (!pageTreeItem) {
                    selectedComponents = componentsIds.reduce((acc, componentId) => {
                        return acc.concat(componentsMap[componentId]);
                    }, []);
                    bbox = getComponentsBBox(selectedComponents, workspaceBBox);
                    pasteDisabled = (components.length === 0);
                    clickedInsideSelectedComponentsBBox = isPointInsideBBox(x, y, bbox);
                    wrapOptions = getWrapOptions(componentsMap, componentsIds);
                }
                const contailsModernSection = componentsIds && componentsIds.some(id => isModernLayoutSection(componentsMap[id])),
                    webshopFooterStripCmpIds = getWebShopStripCmpIds(componentsMap);

                if (componentsIds && ((someComponentsPlacedInModernSection(componentsIds, componentsMap) || contailsModernSection)
                    || componentsIds.some(cmpId => webshopFooterStripCmpIds.includes(cmpId)))) {
                    return { state: defaultState };
                }

                const
                    contextMenuGroups = getContextMenuGroups(
                        kind,
                        componentsMap,
                        componentsIds,
                        pasteDisabled,
                        pageTreeItem,
                        workspaceBBox,
                        clickedInsideSelectedComponentsBBox,
                        wrapOptions,
                        undoRedoState,
                        attachments,
                        canBeSplit,
                        mousePosition,
                    );

                return {
                    state: {
                        opened: true,
                        position: getMenuPosition(position, { browserWidth, browserHeight }, contextMenuGroups),
                        contextMenuGroups,
                        dimensions: { browserWidth, browserHeight },
                        selectedComponentId: componentsIds && componentsIds[0]
                    }
                };
            }
        },
        {
            conditions: [
                COMPONENTS_DELETED
            ],
            reducer: ({ values: [deletedComponentsId], state: epicState }) => {
                if (epicState.opened && deletedComponentsId.indexOf(epicState.selectedComponentId) > -1) {
                    return { state: defaultState };
                }
                return { state: epicState };
            }
        },
        {
            conditions: [
                actionTypes.SHOW_MOBILE_CONTEXT_MENU,
                receiveOnly(browserDimensionsValueActionType),
                receiveOnly(undoRedoValueActionType),
                receiveOnly(MOBILE_VIEW_EDITOR_HOVERING_AND_SELECTION),
                ReceiveOnlyComponents,
                receiveOnly(mobileViewEditorVAT),
                receiveOnly(relationsValueActionType),
                ReceiveOnlyAttachments
            ],
            reducer: ({
                values: [
                    { position },
                    { height: browserHeight, width: browserWidth },
                    undoRedoState,
                    { selectedCmpId },
                    { componentsMap },
                    { groupsForView, data },
                    { changes },
                    attachments
                ]
            }) => {
                const { undoDisabled, redoDisabled } = undoRedoState,
                    isLockToBottomHide =
                        !isLockToBottomEnabled(selectedCmpId, componentsMap, groupsForView, data, changes, attachments),
                    isUnlockFromBottomHide =
                        !isUnlockFromBottomEnabled(selectedCmpId, componentsMap, groupsForView, data);

                const
                    contextMenuGroups = getContextMenuGroupsForMobileView(
                        componentsMap,
                        selectedCmpId,
                        {
                            [CTX_MENU_UNDO]: { disabled: undoDisabled },
                            [CTX_MENU_REDO]: { disabled: redoDisabled },
                            // @ts-ignore
                            [CTX_MENU_LOCK_TO_BOTTOM]: { hide: isLockToBottomHide },
                            // @ts-ignore
                            [CTX_MENU_UNLOCK_FROM_BOTTOM]: { hide: isUnlockFromBottomHide },
                        }
                    );

                return {
                    state: {
                        opened: true,
                        position: getMenuPosition(position, { browserWidth, browserHeight }, contextMenuGroups),
                        contextMenuGroups,
                        dimensions: { browserWidth, browserHeight }
                    }
                };
            }
        },
        {
            conditions: [
                appActionTypes.APP_RIGHT_MOUSE_DOWN,
                receiveOnly(MOBILE_VIEW_EDITOR_HOVERING_AND_SELECTION),
            ],
            reducer: ({
                values: [position, { selectedCmpId, mouseOverCmpId, isMVEEditMode }],
                state: epicState
            }) => {
                if (selectedCmpId && mouseOverCmpId && selectedCmpId === mouseOverCmpId && !isMVEEditMode) {
                    return {
                        state: epicState,
                        actionToDispatch: {
                            type: actionTypes.SHOW_MOBILE_CONTEXT_MENU,
                            payload: { position, kind: handleKinds.Component }
                        }
                    };
                }
                return { state: epicState };
            }
        },
        {
            conditions: [
                receiveOnly(TOP_BAR_POPUP),
                appActionTypes.APP_LEFT_MOUSE_DOWN,
            ],
            reducer: ({
                values: [topBarPopUp],
                state: epicState
            }) => {
                if (topBarPopUp) {
                    return { state: defaultState };
                }
                return { state: epicState };
            }
        },
        {
            conditions: [actionTypes.HIDE_CONTEXT_MENU],
            reducer: () => ({ state: defaultState })
        }
    ]
});
