import { DataPageRef, DataLinkPage, DataSectionLink } from "../index";
import { DATA_PAGE_REF_TYPE, DATA_LINK_PAGE_TYPE, DATA_LINK_SECTION_TYPE } from './dataSiteItemTypes';

export type DataSiteItem = DataPageRef | DataLinkPage | DataSectionLink;

export const
    isDataPageRefType = (type: string): type is typeof DATA_PAGE_REF_TYPE => (type === DATA_PAGE_REF_TYPE),
    isDataLinkPageType = (type: string): type is typeof DATA_LINK_PAGE_TYPE => (type === DATA_LINK_PAGE_TYPE);

const mapDataSiteItem = (data: Record<string, any>, skipLinkPages: boolean = false): DataSiteItem => {
    const { type } = data;
    if (![DATA_PAGE_REF_TYPE, DATA_LINK_PAGE_TYPE, DATA_LINK_SECTION_TYPE].includes(type)) {
        throw new Error('Unexpected site data page type: ' + type);
    }
    if (type === DATA_PAGE_REF_TYPE) {
        return new DataPageRef(data, skipLinkPages);
    }
    if (type === DATA_LINK_PAGE_TYPE) {
        return new DataLinkPage(data);
    }
    return new DataSectionLink(data);
};

const isPageRef = (page: DataSiteItem): page is DataPageRef => page instanceof DataPageRef;

const isLinkPage = (page: DataSiteItem): page is DataLinkPage => page instanceof DataLinkPage;

const isSectionLink = (page: DataSiteItem): page is DataSectionLink => page instanceof DataSectionLink;

const isBookingsPage = (page): boolean => !!(page.subType?.containsBookings);

const isDuplicateRestrictedPage = (page): boolean => isBookingsPage(page);

const cbGetDataPageRef = (pages: Array<DataPageRef>, item: DataSiteItem) => {
    const dataPageRef = new DataPageRef({ ...item }, true /* skipLinkPages */);
    pages.push(dataPageRef);
    pages.push.apply(pages, dataPageRef.getPageRefs());
};

const isPageStateHidden = (page: DataSiteItem) => {
    return isLinkPage(page) ? false : page.hidden;
};

const isPageStateLocked = (page: DataSiteItem) => {
    return isSectionLink(page) ? false : ((isLinkPage(page) && page.hidden) || (!isLinkPage(page) && !page.public));
};

export {
    mapDataSiteItem,
    cbGetDataPageRef,
    isPageRef,
    isLinkPage,
    isSectionLink,
    isBookingsPage,
    isDuplicateRestrictedPage,
    isPageStateHidden,
    isPageStateLocked,
    DATA_PAGE_REF_TYPE,
    DATA_LINK_PAGE_TYPE
};
