// @ts-nocheck
/* eslint-disable max-len */

import React from "react";
import { Msg } from "../../intl/index";
import ComponentTypes from './ComponentTypes';
import FacebookFeedGalleryPremiumComponentDialogId from "../../../components/oneweb/FacebookFeedGallery/premiumComponentDialog/configurationDialogId";
import FacebookChatPremiumComponentDialogId from "../../../components/oneweb/FacebookChat/premiumComponentDialog/configurationDialogId";
import InstagramGalleryPremiumComponentDialogId from "../../../components/oneweb/InstagramGallery/premiumComponentDialog/configurationDialogId";
import { GoogleReviewsPremiumComponentDialogId } from "../../../components/oneweb/GoogleReviews/dialog/dialogIds";
import { WidgetsPremiumComponentDialogId } from '../../../components/oneweb/Widgets/dialogs/premiumComponentId';
import { findOutMoreLabel } from "../../../components/ComponentTierManager/view/findOutMoreLabel";
import FeatureTypes from "./FeatureTypes";
import { getAppConfig } from "../../../components/App/epics/appConfig/appConfig";
import AppConfig from "../../../utils/AppConfig";

const appConfig = AppConfig(getAppConfig());

const StandardTierComponents = {
    [ComponentTypes.VIDEO_FILE]: {
        gaEventValue: 115,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.VIDEO_FILE },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                trackingSource: ComponentTypes.VIDEO_FILE,
                subscriptionData
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.video" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with your own uploaded videos. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    }
};

const PremiumTierComponents = {
    [ComponentTypes.FACEBOOK_FEED_GALLERY]: {
        dialogId: FacebookFeedGalleryPremiumComponentDialogId,
        gaEventValue: 100,
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.FACEBOOK_FEED_GALLERY
            });
            return (
                <Msg k="publish.publishFailed.premiumUpgradeDialog.body.msg.facebook" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Facebook feed feature included. This will also add other great features to your Website builder. {findOutMore}.`}
                </Msg>
            );
        }
    },
    [ComponentTypes.INSTAGRAM_GALLERY]: {
        dialogId: InstagramGalleryPremiumComponentDialogId,
        gaEventValue: 101,
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.INSTAGRAM_GALLERY
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Instagram.msg.instagram" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Instagram feature included. This will also add other great features to your Website builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [FeatureTypes.BACKUP_RESTORE]: {
        gaEventValue: 102
    },
    [ComponentTypes.CODE]: {
        gaEventValue: 103,
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.CODE
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Code.msg.code" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Code feature included. This will also add other great features to your Website builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_TWITTER]: {
        gaEventValue: 104,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_TWITTER },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_TWITTER
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Twitter" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the X widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_INSTAGRAM]: {
        gaEventValue: 107,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_INSTAGRAM },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_INSTAGRAM
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Instagram" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Instagram widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_FACEBOOK]: {
        gaEventValue: 108,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_FACEBOOK },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_FACEBOOK
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Facebook" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Facebook widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_VIMEO]: {
        gaEventValue: 105,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_VIMEO },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_VIMEO
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Vimeo" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Vimeo widgets included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_SPOTIFY]: {
        gaEventValue: 106,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_SPOTIFY },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_SPOTIFY
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Spotify" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Spotify widgets included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_SOUNDCLOUD]: {
        gaEventValue: 109,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_SOUNDCLOUD },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_SOUNDCLOUD
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Soundcloud" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the SoundCloud widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_PINTEREST]: {
        gaEventValue: 111,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_PINTEREST },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_PINTEREST
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Pinterest" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Pinterest widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_EVENTBRITE]: {
        gaEventValue: 112,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_EVENTBRITE },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_EVENTBRITE
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Eventbrite" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Eventbrite widgets included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_GOFUNDME]: {
        gaEventValue: 113,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_GOFUNDME },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_GOFUNDME
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.GoFundMe" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the GoFundMe widgets included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_MAILCHIMP]: {
        gaEventValue: 114,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_MAILCHIMP },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_MAILCHIMP
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Mailchimp" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Mailchimp widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_PAYPAL]: {
        gaEventValue: 116,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_PAYPAL },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_PAYPAL
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Paypal" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the PayPal widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_TIKTOK]: {
        gaEventValue: 110,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_TIKTOK },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_TIKTOK
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.Tiktok" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the TikTok widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
};

const EcommerceTierComponentsCommon = {
    [FeatureTypes.GOOGLE_ANALYTICS_TRACKING]: {
        gaEventValue: 200
    },
    [FeatureTypes.FACEBOOK_PIXEL_TRACKING]: {
        gaEventValue: 201
    },
    [FeatureTypes.FACEBOOK_CHAT]: {
        dialogId: FacebookChatPremiumComponentDialogId,
        gaEventValue: 205,
    },
    [FeatureTypes.TRACKING_TAB]: {
        gaEventValue: 206
    },
    [ComponentTypes.GOOGLE_REVIEWS]: {
        dialogId: GoogleReviewsPremiumComponentDialogId,
        gaEventValue: 207,
        dialogProps: { componentType: ComponentTypes.GOOGLE_REVIEWS },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.GOOGLE_REVIEWS
            });
            return (
                <Msg k="premiumDialog.upgradeBody.googleReviews.msg.googleReviews" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Google reviews feature included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
    [ComponentTypes.WIDGETS_BOOKINGS]: {
        gaEventValue: 117,
        dialogId: WidgetsPremiumComponentDialogId,
        dialogProps: { componentType: ComponentTypes.WIDGETS_BOOKINGS },
        upgradeDialogMsg: ({ dispatch, subscriptionData }) => {
            const findOutMore = findOutMoreLabel({
                dispatch,
                subscriptionData,
                trackingSource: ComponentTypes.WIDGETS_BOOKINGS
            });
            return (
                <Msg k="premiumDialog.upgradeBody.Widgets.msg.booking" params={{ findOutMore }}>
                    {`Upgrading will allow you to publish your site with the Booking widget included. This will also add other great features to your Website Builder. {findOutMore}.`}
                </Msg>);
        }
    },
};

const ecommerceUpgradeDialogMsg = (trackingSource: string) => {
    return ({ dispatch, subscriptionData }) => {
        const findOutMore = findOutMoreLabel({
            dispatch,
            subscriptionData,
            trackingSource
        });
        return (
            <Msg k="premiumDialog.upgradeBody.Webshop.msg.code" params={{ findOutMore }}>
                {`Upgrading will allow you to publish your site with the Online Shop feature included. This will also add other great features to your Website builder. {findOutMore}.`}
            </Msg>
        );
    };
};

const EcommerceTierComponentsPartners = {
    [ComponentTypes.WEBSHOP]: {
        gaEventValue: 202,
        upgradeDialogMsg: ecommerceUpgradeDialogMsg(ComponentTypes.WEBSHOP)
    },
    [ComponentTypes.FEATURED_PRODUCTS_KIND]: {
        gaEventValue: 204,
        upgradeDialogMsg: ecommerceUpgradeDialogMsg(ComponentTypes.FEATURED_PRODUCTS_KIND)
    },
    [ComponentTypes.PRODUCT_WIDGET]: {
        gaEventValue: 204,
        upgradeDialogMsg: ecommerceUpgradeDialogMsg(ComponentTypes.PRODUCT_WIDGET)
    },
};

/**
 * This is a hack for now. This should be removed when refactoring the configuration.
 * Rewrite test in wbtgen/src/components/ComponentTierManager/utils.test.ts
 */
const EcommerceTierComponents = {
    ...EcommerceTierComponentsCommon,
    ...appConfig.getOptional("oneWeb.webshop.features.enableShopInAllTier") ?
        {} : EcommerceTierComponentsPartners
};

export {
    StandardTierComponents,
    PremiumTierComponents,
    EcommerceTierComponents
};
