import CALL_API from "../../../../../redux/middleware/api/CALL_API";
import {
    GET_BOOKINGS_STATE_FAILURE,
    GET_BOOKINGS_STATE_REQUEST,
    GET_BOOKINGS_STATE_SUCCESS
} from "./actionTypes";

/**
 * Dispatch action to get the bookings inserter state
 */
export const getBookingsInserterState = () => {
    return {
        [CALL_API]: {
            // types define the actions which are called
            // when the request is called, request succeeded and failed
            types: [
                GET_BOOKINGS_STATE_REQUEST,
                GET_BOOKINGS_STATE_SUCCESS,
                GET_BOOKINGS_STATE_FAILURE
            ],
            endpoint: "getBookingsData",
            endpointParams: "inserter-state"
        }
    };
};
