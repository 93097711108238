/* eslint-disable max-len */
import * as R from 'ramda';
import { receiveOnly } from '../../../../epics/makeCondition';
import * as kbdActionTypes from "../../../App/kbdActionTypes";
import * as workspaceActionTypes from "../../actionTypes";
import * as focusKind from '../../../App/epics/userFocus/kind';
import userFocusValueActionType from '../../../App/epics/userFocus/valueActionType';
import * as updateReasons from './updateReasons';
import { selectedComponentsIdsSelector, userInteractionModeSelector, componentsMapSelector } from "./selectorActionTypes";
import {
    getSelectedComponentIdsAlongWithWrappedIds,
    getComponentWrappedIds,
    getNonDeletableComponentsIds
} from "./selectors";
import type { ComponentsEvalEpicUpdater } from './flowTypes';
import { isTransient } from "./userInteractionMutations/interactionModes";
import { componentAttachmentsVAT } from "../componentAttachements/valueActionType";
import { getAllAttachmentsForCmpIds } from "../componentAttachements/util";
import { removeEmptySpacesBetweenSections } from "../../../oneweb/Section/utils";
import { isSectionComponent } from "../../../oneweb/isSectionComponent";
import { selectedComponentIsInsideHeaderOrFooterVAT } from "../selectedComponentIsInsideHeaderOrFooterEpic/valueActionType";
import { isModernLayoutActivatedVAT } from "../isModernLayoutActivatedEpic/valueActionType";
import { getWebShopStripCmpIds } from '../../../ModernLayouts/layoutsData/webshopMHFDataUtils';

const
    kbdUpdaterFactory = (triggerActionType): ComponentsEvalEpicUpdater => ({
        conditions: [
            receiveOnly(userFocusValueActionType),
            triggerActionType
        ],
        reducer: ({ values: [focus], state }) => ({
            state,
            actionToDispatch: focus.kind === focusKind.WORKSPACE ?
                { type: workspaceActionTypes.DELETE_SELECTED_COMPONENTS } : null
        })
    }),
    onKbdDelUpdater = kbdUpdaterFactory(kbdActionTypes.DEL_BTN_PRESS),
    onKbdBackspaceUpdater = kbdUpdaterFactory(kbdActionTypes.BACKSPACE_BTN_PRESS),
    updateStateWithComponentIdsToBeDeleted = (selectedCmpIdsAlongWithWrappedIds: Array<string>, attachments: Record<string, any>, epicState: Record<string, any>) => {
        const componentIdsToDelete: Array<string> = selectedCmpIdsAlongWithWrappedIds.concat(
            getAllAttachmentsForCmpIds(attachments, selectedCmpIdsAlongWithWrappedIds)
        );
        const newEpicState = R.evolve({
            state: {
                componentsMap: R.pipe(R.omit(componentIdsToDelete), removeEmptySpacesBetweenSections(attachments))
            }
        }, epicState);

        return {
            state: newEpicState,
            actionToDispatch: {
                type: workspaceActionTypes.COMPONENTS_DELETED,
                payload: componentIdsToDelete
            },
            updateReason: updateReasons.DELETED
        };
    },
    deleteUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            receiveOnly(selectedComponentIsInsideHeaderOrFooterVAT),
            receiveOnly(isModernLayoutActivatedVAT),
            workspaceActionTypes.DELETE_SELECTED_COMPONENTS
        ],
        reducer: ({ values: [
            { attachments }, { isInsideHeaderOrFooter }, isModernLayoutActivated
        ], state: epicState }) => {
            if (isTransient(userInteractionModeSelector(epicState))
            || (isModernLayoutActivated && isInsideHeaderOrFooter)) {
                return { state: epicState };
            }

            const selectedComponentsIds = selectedComponentsIdsSelector(epicState);
            if (!selectedComponentsIds.length) return { state: epicState };

            const componentsMap = componentsMapSelector(epicState);

            const deleteRestrictedComponentsIds = getNonDeletableComponentsIds(componentsMap, selectedComponentsIds);
            if (deleteRestrictedComponentsIds.length) {
                return {
                    state: epicState,
                };
            }

            const webShopFooterStripCmpIds = getWebShopStripCmpIds(componentsMap);
            if (webShopFooterStripCmpIds.length && selectedComponentsIds.some(id => webShopFooterStripCmpIds.includes(id))) {
                return { state: epicState };
            }

            let selectedCmpIdsAlongWithWrappedIds = getSelectedComponentIdsAlongWithWrappedIds(epicState)
                .filter(id =>
                    !isSectionComponent(componentsMap[id]));
            return updateStateWithComponentIdsToBeDeleted(selectedCmpIdsAlongWithWrappedIds, attachments, epicState);
        }
    },
    deleteSectionUpdater: ComponentsEvalEpicUpdater = {
        conditions: [
            receiveOnly(componentAttachmentsVAT),
            workspaceActionTypes.DELETE_SELECTED_SECTION
        ],
        reducer: ({ values: [{ attachments }, { sectionId, templateSection }], state: epicState }) => {
            const componentsMap = componentsMapSelector(epicState);
            if (isTransient(userInteractionModeSelector(epicState))
                || !sectionId || (!templateSection && componentsMap[sectionId]?.inTemplate)) {
                return { state: epicState };
            }

            const deleteRestrictedComponentsIds = getNonDeletableComponentsIds(componentsMap, [sectionId]);
            if (deleteRestrictedComponentsIds.length) {
                return {
                    state: epicState
                };
            }

            let selectedCmpIdsAlongWithWrappedIds = getComponentWrappedIds([sectionId], componentsMap);
            return updateStateWithComponentIdsToBeDeleted(selectedCmpIdsAlongWithWrappedIds, attachments, epicState);
        }
    };

export { deleteUpdater, onKbdDelUpdater, onKbdBackspaceUpdater, deleteSectionUpdater };
