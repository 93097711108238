/* eslint-disable max-len */

const { WsbFeature } = require("../../server/shared/WsbFeature");

/*::
    import type { WhatsNewListT } from './types';
 */

const WhatsNewList/*: WhatsNewListT */ = [
    {
        id: '1',
        title: 'Quick access to image editing',
        description: `
            Just click the pen icon next to images, and adjust the look;
            crop them, add filters, borders and frames.
        `,
        releaseDate: '2018-06-27T00:00:00Z',
    },
    {
        id: '2',
        title: 'Easier to edit template settings',
        description: `
            In this release we’re making it easier to access the settings
            for editing the template from the page selector in the top green bar.
        `,
        releaseDate: '2018-07-24T00:00:00Z',
    },
    {
        id: '3',
        title: 'Get free images from One.com',
        description: `
            This version introduces access to a library with thousands of free images.
        `,
        releaseDate: '2018-08-30T00:00:00Z',
        wsbFeature: WsbFeature.Shutterstock,
    },
    {
        id: '4',
        title: 'Table component',
        description: `
            The new Website Builder now supports adding tables, just like the old one.
        `,
        releaseDate: '2018-09-25T00:00:00Z',
    },
    {
        id: '5',
        title: 'Optimized mobile website',
        description: `
            The look of your mobile website is now optimized even more.
            All you need to is re-arrange the order of components.
            Changes will not affect desktop.
        `,
        releaseDate: '2018-10-30T00:00:00Z',
    },
    {
        id: '6',
        title: 'The online shop has a new layout',
        description: `
            Make your products stand out with the card layout option.
            To try it, go to the style settings for your shop.
        `,
        releaseDate: '2019-01-06T00:00:00Z',
        wsbFeature: WsbFeature.OnlineShop,
    },
    {
        id: '7',
        title: 'New image editor',
        description: `
            Great images are valuable to any website.
            Click the pen icon next to images and adjust the look using in our new image editor;
            crop, add filters, frames, overlays and more.
        `,
        releaseDate: '2019-02-06T00:00:00Z',
    },
    {
        id: '8',
        title: 'Click-to-call links',
        description: `
            Make it easier to contact you.
            Add a phone number link to any text, image or button - and your visitors
            will be able to click it to call you if they are using a phone or a tablet.
        `,
        releaseDate: '2019-02-06T00:00:00Z',
    },
    {
        id: '9',
        title: 'The online shop just got better',
        description: `
            The online shop component got a visual update.
            Now it shows a shopping cart on all pages when your customer puts a product in it.
            Publish your site to get this update.
        `,
        releaseDate: '2019-03-04T00:00:00Z',
        wsbFeature: WsbFeature.OnlineShop,
    },
    {
        id: '10',
        title: 'Gallery and slider got a makeover',
        description: `
            You may notice that the gallery and slider looks different.
            They both are a little fresher, a little simpler, and (we think) a little better.
        `,
        releaseDate: '2019-04-30T00:00:00Z',
    },
    {
        id: '11',
        title: 'Give your mobile website a boost',
        description: `
            Adjust the font size. Change the alignment of buttons.
            Edit spacing for galleries. Easily hide and get overview of hidden elements.
            All these are now available in the mobile view editor.
        `,
        releaseDate: '2019-06-25T00:00:00Z',
    },
    {
        id: '12',
        title: 'Add a cookie banner to your website',
        description: `
            With the new tab "Cookie banner" it's now easy to add your own cookie banner to your website.
            Find the feature under the new option "Settings" in the upper right corner menu.
        `,
        releaseDate: '2019-06-25T00:00:00Z',
        learnMoreLink: 'msg: consentBanner.learnMore {https://help.one.com/hc/en-us/articles/360001769238-How-do-I-add-a-cookie-banner-to-my-Website-Builder-site}'
    },
    {
        id: '13',
        title: 'A new way to add social links',
        description: `
            Add links to your social networks with the brand new "Social links" component.
            The icons will look pixel sharp on any screen.
        `,
        releaseDate: '2019-07-30T00:00:00Z',
        learnMoreLink: 'msg: social.learnMore {https://help.one.com/hc/en-us/articles/360002023697}'
    },
    {
        id: '14',
        title: 'Add a logo, title and colours to the mobile menu',
        description: `
            Your mobile menu never looked this good.
            Check it out in "Preview" or customize it with the mobile view editor.
            Click the header section to access the settings.
            Remember to publish when you are done!
        `,
        releaseDate: '2019-07-30T00:00:00Z',
        learnMoreLink: 'msg: mobileLogo.learnMore {https://help.one.com/hc/en-us/articles/360002274197}'
    },
    {
        id: '15',
        title: 'Easier to set up tracking',
        description: `
            With the new tab "Tracking", you can quickly set up tracking for Google Analytics, Google Ads and
            Facebook Pixel.
            Find the feature under the option "Settings" in the upper right corner menu.
        `,
        releaseDate: '2019-09-10T00:00:00Z',
        learnMoreLink: 'msg: tracking.learnMoreLink {https://help.one.com/hc/en-us/articles/360000130578}',
        wsbFeature: WsbFeature.Tracking,
    },
    {
        id: '16',
        title: 'Add a logo to your website fast and easy',
        description: `
            Give your website a personal feel with the brand new “Logo” component.
            Add a logo or a catchy text phrase and style it in the way that fits your website best!
        `,
        releaseDate: '2019-10-14T00:00:00Z',
        learnMoreLink: 'msg: generalInfo.logo.learnMoreLink {https://help.one.com/hc/en-us/articles/360003212478}'
    },
    {
        id: '18',
        title: 'Add scroll effects to your website',
        description: `
            Give your website a cool 3D look with the new scroll effects available for strips.
            A small twist that can make your website appear stylish and modern! Add an image to a strip,
            and explore the effects in the component's properties panel.
        `,
        releaseDate: '2019-12-11T00:00:00Z',
        learnMoreLink: 'msg: scrollEffects.learnMoreLink {https://help.one.com/hc/en-us/articles/360004824718}'
    },
    {
        id: '19',
        title: 'Create fixed menus and footers',
        description: `
           Thought about adding a fixed menu?
           You can now make strips, and all their attached components, stick to the top or the bottom of your page.
           Access the feature by right-clicking a strip, and select "Pin to screen".
        `,
        releaseDate: '2019-12-11T00:00:00Z',
        learnMoreLink: 'msg: stripPinFixedMobileHeaders.learnMoreLink {https://help.one.com/hc/en-us/articles/360005540857}'
    },
    {
        id: '20',
        title: 'Buy high-quality images from Shutterstock',
        description: `
            Looking to spice up your website? Buy some beautiful images from Shutterstock and create a unique website.
            Explore the full selection under the tab “Shutterstock” when you add a new image.
        `,
        releaseDate: '2019-12-17T00:00:00Z',
        learnMoreLink: 'msg: shutterstock.learnMoreLink {https://help.one.com/hc/en-us/articles/115005584829}',
        wsbFeature: WsbFeature.Shutterstock,
    },
    {
        id: '21',
        title: 'Publish your site to get SSL',
        description: `
            Your site is automatically protected by SSL the next time you publish.
            This means that HTTPS is enabled - and your visitors’ information is secured when they visit your site.
        `,
        releaseDate: '2020-01-28T00:00:00Z'
    },
    {
        id: '22',
        title: 'Link to sections on your site',
        description: `
            Save visitors some scrolling by adding shortcuts to specific sections.
            Add the links to your site menu or elements on your page.
            Look for them in the new "Section link" tab when adding links! A useful feature for one-page sites.
        `,
        releaseDate: '2020-01-28T00:00:00Z',
        learnMoreLink: 'msg: sectionLink.learnMoreLink {https://help.one.com/hc/en-us/articles/360005542937}'
    },
    {
        id: '23',
        title: 'Add contact details with links and icons',
        description: `With the new contact components it is now easier than ever to link up your phone number, email, and business address. They all come with cool icons and flexible styling options. Look for them in the \'Contact\' section of the panel on the left.`,
        releaseDate: '2020-03-31T00:00:00Z',
        learnMoreLink: 'msg: contactDetails.learnMoreLink {https://help.one.com/hc/en-us/articles/360007547298}'
    },
    {
        id: '24',
        title: 'Add prestyled sections to your website',
        description: `In need of some layout inspiration? Check out the new prestyled sections available in the panel on the left. This feature will help you build a beautiful website in no time. Simply drag the sections directly to your workspace and see how they adapt to the look of your template.`,
        releaseDate: '2020-03-31T00:00:00Z'
    },
    {
        id: '25',
        title: 'Often share your website as a URL?',
        description: `Take control over how your content appears and get your posts seen. Customize the text and image used for each page you share on Facebook, LinkedIn, messages and more.`,
        releaseDate: '2020-05-12T00:00:00Z',
        learnMoreLink: 'msg: socialShare.learnMoreLink {https://help.one.com/hc/en-us/articles/360007955537}'
    },
    {
        id: '26',
        title: 'Build faster using blocks',
        description: `Blocks are ready-to-use groups of components. They adapt to the existing style, so you can focus on making a great website. Look for them under ‘Text’ and ‘Contact’ in the panel to the left.`,
        releaseDate: '2020-05-12T00:00:00Z'
    },
    {
        id: '27',
        title: 'Add your Instagram feed to your site',
        description: `Now you can easily share all of your great photos by adding your Instagram feed directly to your site. Check it out in the \"Social\" section of the panel on the left.`,
        releaseDate: '2020-10-13T00:00:00Z',
        learnMoreLink: 'msg: instagram.learnMoreLink {https://help.one.com/hc/en-us/articles/360014009697}'
    },
    {
        id: '28',
        title: 'Improved way to build your site',
        description: `Your website is now built entirely with sections. This makes it easier to organise your content and make quick layout changes to your pages. Select a section in the workspace to edit it or click a plus-sign to add a new one.`,
        releaseDate: '2020-11-11T00:00:00Z'
    },
    {
        id: '29',
        title: 'Get a colour theme for your website',
        description: 'Now you can manage your website colours from one single place and style entire groups of components in just one click. Activate your colour theme by clicking on the button with the colour swatches icon in the bottom-right corner of your workspace.',
        releaseDate: '2020-11-24T00:00:00Z',
        learnMoreLink: 'msg: autoColor.learnMoreLink {https://help.one.com/hc/en-us/articles/360015355958}'
    },
    {
        id: '30',
        title: 'Add your Facebook gallery feed to your site ',
        description: 'We’ve made it simple for you to add your Facebook gallery feed to your site to share it with your visitors. Find it in the \"Social\" section of the panel on the left.',
        releaseDate: '2020-12-14T00:00:00Z',
        learnMoreLink: 'msg: facebookFeed.learnMoreLink {https://help.one.com/hc/en-us/articles/360016124077}'
    },
    {
        id: '31',
        title: 'Easily add a GDPR compliant cookie banner',
        description: 'Easily add a GDPR compliant cookie banner to your site in seconds. Find it inside your site \"Settings\" in the upper right corner menu in the top bar.',
        releaseDate: '2021-03-23T00:00:00Z',
        learnMoreLink: 'msg: consentBanner.learnMore {https://help.one.com/hc/en-us/articles/360001769238-How-do-I-add-a-cookie-banner-to-my-Website-Builder-site}'
    },
    {
        id: '32',
        title: 'Add your business opening hours',
        description: 'Use the new Opening hours component to let your visitors know when you are open. Easily set up times for each day and choose a layout that suits your site best. Find it in the \"Contact\" section of the panel on the left.',
        releaseDate: '2021-04-13T00:00:00Z',
        learnMoreLink: 'msg: openingHours.learnMore {https://help.one.com/hc/en-us/articles/360019151538}'
    },
    {
        id: '33',
        title: 'Google My Business',
        description: 'Google My Business allows you to easily update your contact information and opening hours in one place. You’ll also be able to interact with customers anywhere on Google Search and Maps. Find it under \"Settings\" in the upper right corner menu.',
        releaseDate: '2021-04-28T00:00:00Z',
        learnMoreLink: 'msg: gmb.learnMore {https://help.one.com/hc/en-us/articles/360018987477}',
        wsbFeature: WsbFeature.GoogleMyBusiness,
    },
    {
        id: '34',
        title: 'Chat with your visitors',
        description: 'The new Messenger chat component allows your customers to easily chat with you directly on your website. Find it in the \"Contact\" section of the panel on the left.',
        releaseDate: '2021-09-30T00:00:00Z',
        learnMoreLink: 'msg: chatWidget.learnMore {https://help.one.com/hc/en-us/articles/4406882201617}',
        wsbFeature: WsbFeature.FacebookChat,
    },
    /*
    {
        id: '35',
        title: 'Header and footer layouts',
        description: 'Over the next months, we will be working to add header and footer layouts that are modern and have the capability to adapt to your screen size. We will start out simple and add more functionality over time. Please experiment and give us feedback to influence the development of this feature.',
        releaseDate: '2021-12-22T00:00:00Z',
    }
    */
    {
        id: '36',
        title: 'Publish from enhanced dashboard',
        description: 'You\'ll notice that your dashboard looks different now! We have moved things around to accommodate some website settings, which we are introducing to the dashboard gradually. You can also publish directly from dashboard pages without having to go into the editor.',
        releaseDate: '2022-08-16T00:00:00Z',
        wsbFeature: WsbFeature.Dashboard,
    },
    {
        id: '38',
        title: 'Widgets & new website templates',
        description: `
            Seamlessly integrate third-party media on your website,
            using our new widgets for Spotify, Twitter, Google Maps, YouTube & Vimeo.
            Also, we have added 25 new website templates!
            Check them out <link>here</link> and consider refreshing your website's look.
        `,
        releaseDate: '2022-09-27T00:00:00Z',
    },
    {
        id: '37',
        title: 'Introducing video player',
        description: 'Videos keep people on your website longer and increase engagement with your content. You can find the video component <link>here</link>.',
        releaseDate: '2022-10-11T00:00:00Z'
    },
    {
        id: '39',
        title: 'SEO scan & more widgets',
        description: `
            See our <link>SEO scan</link> tool where we scan your website
            and suggest tasks to improve your website's visibility on search engines, like Google.
            In the editor, you'll also find new widgets to make your website more engaging - Soundcloud, Pinterest and more!
        `,
        wsbFeature: WsbFeature.Dashboard,
        releaseDate: '2022-11-24T00:00:00Z',
    },
    {
        id: '40',
        title: 'New images',
        description: 'You can now use unlimited free images from Unsplash on your website.',
        wsbFeature: WsbFeature.Unsplash,
        releaseDate: '2023-03-14T00:00:00Z'
    },
    {
        id: '41',
        title: 'Automatic Online Shop footer',
        description: 'Online Shop users can now display their policies and payment options in their website footer. When you change these options in the Online Shop settings, they will update automatically in your footer without you having to republish your site.',
        releaseDate: '2023-03-14T00:00:00Z'
    },
    {
        id: '42',
        title: 'Video background',
        description: 'Create an impactful, first impression of your website by adding a video as a section background to your home page. Choose from our free, top-quality videos or upload your own. Available for Premium and Business + Ecommerce customers only.',
        releaseDate: '2023-06-06T00:00:00Z'
    },
    {
        id: '43',
        title: 'Online Shop stats',
        description: 'If you’re using Online Shop, you can now see an overview of important stats in your Website Builder dashboard - revenue, open orders and items out of stock.',
        wsbFeature: WsbFeature.Dashboard,
        releaseDate: '2023-06-06T00:00:00Z'
    },
    {
        id: '44',
        title: 'Google review widget',
        description: 'Add credibility to your website by displaying your customer\'s reviews directly from your Google Business listing. <link>Connect your listing now and add the Google review widget to your website</link>.',
        releaseDate: '2023-06-20T00:00:00Z'
    },
    {
        id: '45',
        title: 'Save time with our new Writing Assistant',
        description: 'No more writer’s block! Tell us what you want to write about and get customised, AI-generated website texts within seconds.',
        releaseDate: '2023-07-18T00:00:00Z'
    },
    {
        id: '46',
        title: 'No more spelling mistakes!',
        description: 'Check typos easily with our integrated browser spellcheck. Manage the spellcheck activation, deactivation and language in your browser settings.',
        releaseDate: '2024-03-20T00:00:00Z',
        learnMoreLink: 'msg: spellCheck.learnMore {https://help.one.com/hc/en-us/articles/22911562019601-How-to-manage-spell-check-in-Website-Builder}'
    },
    {
        id: '47',
        title: 'Level up your website compliance!',
        description: 'Ensure you are fully compliant with latest privacy regulations with our new optimised consent banner that offers your visitors even more privacy choices.',
        releaseDate: '2024-06-06T00:00:00Z',
        learnMoreLink: 'msg: consentBanner.learnMore {https://help.one.com/hc/en-us/articles/360001769238-How-do-I-add-a-cookie-banner-to-my-Website-Builder-site}'
    },
    {
        id: '48',
        title: 'Optimise existing texts with Writing Assistant',
        description: 'Our Writing Assistant now helps you to easily modify existing texts. Choose between our preset options “Rewrite”, “Make shorter” and “Make longer”, or write your own instructions for the changes, and get new text versions right away.',
        releaseDate: '2024-07-02T00:00:00Z',
        learnMoreLink: 'msg: writingAssistant.learnMore {https://help.one.com/hc/en-us/articles/16515401570065-How-do-I-use-the-Writing-Assistant-in-Website-Builder}',
        disableLinkForPartners: true
    },
    {
        id: '49',
        title: 'Let your customers say yes to marketing!',
        description: 'Contacts are now available for all websites, not just shops! Once your website is published, you can seamlessly collect customer consent at checkout or via contact forms, grow your contact list, and easily manage marketing preferences in Contact settings.',
        releaseDate: '2024-12-18T00:00:00Z',
        learnMoreLink: 'msg: contacts.whatnew.readMore {https://help.one.com/hc/en-us/articles/23674157271185}'
    },
    {
        id: '50',
        title: 'New feature: Bookings!',
        description: 'Let customers book your services directly from your website with our new Booking add-on. Includes automatic confirmation, reminder and cancellation emails and lets you manage your availability easily. Try it out right away.',
        releaseDate: '2025-01-15T00:00:00Z',
        wsbFeature: WsbFeature.Bookings
    }
];

module.exports = { WhatsNewList };
