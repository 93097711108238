import { ContactFormKind } from "../../oneweb/ContactForm/kind";
import { ContainersKind } from "./constants";
import GalleryKind from "../../oneweb/Gallery/kind";
import BookingsKind from "../../oneweb/Bookings/kind";

const updatedKinds = [ContactFormKind, ContainersKind, GalleryKind, BookingsKind];

export const hasUpdate = (kind: string) => {
    return updatedKinds.includes(kind);
};
