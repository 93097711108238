/* @flow */
const Routes = require("../../shared/routes");

/*::
    import type { ServiceLocator } from '../types'
*/

function isProtectedRouteMatch(protectedRoute/*: string */, urlRoute/*: string */)/*: boolean */ {
    if (typeof urlRoute !== "string") return false;

    const route = urlRoute.toLowerCase();
    if (protectedRoute === Routes.ROOT_ROUTE) {
        return protectedRoute === urlRoute;
    } else {
        return Boolean(
            protectedRoute === route ||
            route.startsWith(`${protectedRoute}/`)
        );
    }
}

function isProtectedRoute(route/*: string */)/*: boolean */ {
    return Routes.PROTECTED_ROUTES.some((protectedRoute) =>
        isProtectedRouteMatch(protectedRoute, route)
    );
}

function isDashboardShopRoute(route/*: string */)/*: boolean */ {
    return isProtectedRouteMatch(Routes.DASHBOARD_SHOP_ROUTE, route);
}

function isDashboardBookingsRoute(route/*: string */)/*: boolean */ {
    return isProtectedRouteMatch(Routes.DASHBOARD_BOOKINGS_ROUTE, route);
}

module.exports = { isProtectedRoute, isDashboardShopRoute, isDashboardBookingsRoute };
