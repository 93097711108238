import * as React from 'react';
import platform from 'platform';
import { memoMaxOne } from '../../../../utils/memo';
import { UNDO_BUTTON_PRESSED, REDO_BUTTON_PRESSED } from '../../TopBar/actionTypes';
import * as appActionTypes from "../../../redux/modules/actionTypes";
import * as workspaceActionTypes from '../../Workspace/actionTypes';
import * as OldWorkspaceActionTypes from '../../../redux/modules/children/workspace/actionTypes';
import { Msg, injectIntl } from '../../../view/intl';
import styles from "./ContextMenu.css";
import { PAGE_TREE_PAGE_DELETE, PAGE_TREE_PAGE_DUPLICATE, PAGE_TREE_PAGE_HIDE,
    SECTION_LINK_DUPLICATE } from "../../PagesTree/Tree/actionTypes";
import { DataPageRef, DataLinkPage, DataSectionLink } from "../../../../dal/model";
import {
    openLinkPageInfoDialog,
    openPageInfoDialogAction,
    openSectionLinkInfoDialog,
    loadSectionComponentsForPage
} from "../../PagesTree/Tree/actionCreators";
import type { GroupItem } from "../flowTypes";
import { MacSymbol } from "./MacSymbols";
import {
    MOBILE_EDITOR_HIDE_COMPONENTS, MOBILE_EDITOR_UNLOCK_COMPONENTS, MOBILE_EDITOR_LOCK_COMPONENTS
} from "../../MobileViewEditor/actionTypes";
import {
    removeOutlineAndSelectionMobileViewEditorAC
} from "../../MobileViewEditor/actionCreators";
import ContextMenuIcon from "../icon/ContextMenuIcon";
import {
    componentPinToBottomAC,
    componentPinToTopAC, componentUnpinAC,
    openPinDialogAC,
    pinToScreenAC
} from "../../PinComponentDialog/actionCreators/updateComponentPin";
import { SPLIT_SECTION_LOCATION, FIX_ALL_HEADER_LAYOUT } from "../../oneweb/Section/epics/splitSectionDecoration/actionTypes";
import { NEW_PAGE_DIALOG_OPEN } from '../../PagesTree/NewPageDialog/actions';
import { getPageLimitBySubscriptionType } from '../../App/epics/subscriptionData/utils';

const
    redoMacCtxPrefix = 'msg: contextmenu.item.redo.shortcut.mac {{shiftPrefix}{prefix} + Z}',
    Shortcuts = {
        'contextmenu.item.help.shortcut': 'msg: contextmenu.item.help.shortcut {F1}',
        'contextmenu.item.delete.shortcut': 'msg: contextmenu.item.delete.shortcut {DEL}',
        'contextmenu.item.undo.shortcut': 'msg: contextmenu.item.undo.shortcut {{prefix} + Z}',
        'contextmenu.item.redo.shortcut': 'msg: contextmenu.item.redo.shortcut {{prefix} + Y}',
        'contextmenu.item.cut.shortcut': 'msg: contextmenu.item.cut.shortcut {{prefix} + X}',
        'contextmenu.item.copy.shortcut': 'msg: contextmenu.item.copy.shortcut {{prefix} + C}',
        'contextmenu.item.paste.shortcut': 'msg: contextmenu.item.paste.shortcut {{prefix} + V}',
        'contextmenu.item.duplicate.shortcut': 'msg: contextmenu.item.duplicate.shortcut {{prefix} + D}'
    },
    getShortcut = memoMaxOne(k => {
        let shortCut = Shortcuts[k];
        const isMacOS = platform.os.family === 'OS X',
            prefix = isMacOS ? MacSymbol.commandK : 'Ctrl',
            isRedo = shortCut.indexOf('redo') > -1,
            hasPrefix = shortCut.indexOf('prefix') > -1;
        let shiftPrefix: string | null = null;
        if (isRedo && isMacOS) {
            shiftPrefix = MacSymbol.shiftK;
            shortCut = redoMacCtxPrefix;
        }

        const msgJointParams = hasPrefix ?
                [shortCut, (shiftPrefix ? { shiftPrefix, prefix } : { prefix })] : shortCut,
            Component = injectIntl(({ intl }) => (
                <span className={styles.shortcut}>{intl.msgJoint(msgJointParams)}</span>
            ));
        return (<Component />);
    });

export const
    OVERLAPPING_COMPONENTS_MAIN_MENU_COUNT = 2,

    ContextMenuStyles = styles,

    ContextMenuDimensions = {
        containerWidth: 240,
        subMenuOverlap: 6
    },

    ContextSubMenuDirection = {
        topToBottom: 0,
        bottomToTop: 1
    },

    componentDeleteActionItem = {
        title: <Msg k="contextmenu.item.delete.title">Delete</Msg>,
        shortcut: getShortcut("contextmenu.item.delete.shortcut"),
        actionInput: workspaceActionTypes.DELETE_SELECTED_COMPONENTS,
        className: styles.delete
    },

    undoLastAction = {
        title: <Msg k="contextmenu.item.undo.title">Undo</Msg>,
        shortcut: getShortcut("contextmenu.item.undo.shortcut"),
        actionInput: UNDO_BUTTON_PRESSED
    },

    redoLastAction = {
        title: <Msg k="contextmenu.item.redo.title">Redo</Msg>,
        shortcut: getShortcut("contextmenu.item.redo.shortcut"),
        actionInput: REDO_BUTTON_PRESSED
    },

    copyComponent = {
        title: <Msg k="contextmenu.item.copy.title">Copy</Msg>,
        shortcut: getShortcut("contextmenu.item.copy.shortcut"),
        actionInput: workspaceActionTypes.COPY_SELECTED_COMPONENTS
    },

    cutComponent = {
        title: <Msg k="contextmenu.item.cut.title">Cut</Msg>,
        shortcut: getShortcut("contextmenu.item.cut.shortcut"),
        actionInput: workspaceActionTypes.CUT_SELECTED_COMPONENTS
    },

    pasteComponent = {
        title: <Msg k="contextmenu.item.paste.title" dataTid="paste-ctx-btn">Paste</Msg>,
        shortcut: getShortcut("contextmenu.item.paste.shortcut"),
        actionInput: workspaceActionTypes.PASTE_FROM_SYSTEM_CLIPBOARD
    },

    duplicateComponent = {
        title: <Msg k="contextmenu.item.duplicate.title">Duplicate</Msg>,
        shortcut: getShortcut("contextmenu.item.duplicate.shortcut"),
        actionInput: workspaceActionTypes.DUPLICATE_SELECTED_COMPONENTS
    },

    CTX_MENU_UNDO = 'CTX_MENU_UNDO',
    CTX_MENU_REDO = 'CTX_MENU_REDO',
    CTX_MENU_COPY = 'CTX_MENU_COPY',
    CTX_MENU_CUT = 'CTX_MENU_CUT',
    CTX_MENU_PASTE = 'CTX_MENU_PASTE',
    CTX_MENU_DUPLICATE = 'CTX_MENU_DUPLICATE',

    componentCommonActionItemsOrder = [
        CTX_MENU_UNDO,
        CTX_MENU_REDO,
        CTX_MENU_COPY,
        CTX_MENU_CUT,
        CTX_MENU_PASTE,
        CTX_MENU_DUPLICATE
    ],

    headerFooterSectionCommonActionItemsOrder = [
        CTX_MENU_UNDO,
        CTX_MENU_REDO,
        CTX_MENU_PASTE
    ],

    sectionCommonActionItemsOrder = [
        CTX_MENU_UNDO,
        CTX_MENU_REDO,
        CTX_MENU_COPY,
        CTX_MENU_PASTE,
        CTX_MENU_DUPLICATE
    ],

    componentCommonActionItems = {
        [CTX_MENU_UNDO]: undoLastAction,
        [CTX_MENU_REDO]: redoLastAction,
        [CTX_MENU_COPY]: copyComponent,
        [CTX_MENU_CUT]: cutComponent,
        [CTX_MENU_PASTE]: pasteComponent,
        [CTX_MENU_DUPLICATE]: duplicateComponent
    },

    clipboardRestrictedComponentActionItems = {
        [CTX_MENU_UNDO]: undoLastAction,
        [CTX_MENU_REDO]: redoLastAction,
    },

    headerFooterSectionCommonActionItems = {
        [CTX_MENU_UNDO]: undoLastAction,
        [CTX_MENU_REDO]: redoLastAction,
        [CTX_MENU_PASTE]: pasteComponent
    },

    sectionCommonActionItems = {
        [CTX_MENU_UNDO]: undoLastAction,
        [CTX_MENU_REDO]: redoLastAction,
        [CTX_MENU_COPY]: copyComponent,
        [CTX_MENU_PASTE]: pasteComponent,
        [CTX_MENU_DUPLICATE]: duplicateComponent
    },

    componentLayerActionItem = {
        title: <Msg k="contextmenu.item.arrange.title">Arrange</Msg>,
        className: styles.arrange
    },

    componentBringToFront = {
        title: <Msg k="contextmenu.item.bringToFront.title">Bring to front</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_BRING_TO_FRONT
    },

    componentSendForward = {
        title: <Msg k="contextmenu.item.sendForward.title">Bring forward</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_SEND_FORWARD
    },

    componetSendBackwards = {
        title: <Msg k="contextmenu.item.sendBackwards.title">Send backward</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_SEND_BACKWARDS
    },

    componentSendToBack = {
        title: <Msg k="contextmenu.item.sendToBack.title">Send to back</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_SEND_TO_BACK
    },

    componentLayeringActionItems = [
        componentBringToFront,
        componentSendForward,
        componetSendBackwards,
        componentSendToBack
    ],

    overlappingComponentsHeaderItem = {
        title: <Msg k="contextmenu.item.overlappingElements.title">Overlapping elements</Msg>,
        className: styles.overlappingComponentsHeaderItem
    },

    overlappingComponentsFooterItem = {
        title: (count: number) =>
            <Msg params={{ count }} k="contextmenu.item.showAll.title">{`Show all ({count})`}</Msg>,
        className: styles.overlappingComponentsShowAllItem
    },

    changeTemplateBackground = {
        title: <Msg k="contextmenu.item.changeTemplateBackground.title">Change template background</Msg>,
        actionInput: OldWorkspaceActionTypes.CHANGE_TEMPLATE_BACKGROUND
    },

    templateSettings = {
        title: <Msg k="contextmenu.item.templateSettings.title">Template settings</Msg>,
        actionInput: OldWorkspaceActionTypes.TEMPLATE_SETTINGS
    },

    sendToPage = {
        title: <Msg k="contextmenu.item.sendToPage.title">Send to page</Msg>,
        actionInput: workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_PAGE
    },

    sendToTemplate = {
        title: <Msg k="contextmenu.item.sendToTemplate.title">Send to template</Msg>,
        actionInput: workspaceActionTypes.MOVE_SELECTED_COMPONENTS_TO_TEMPLATE
    },

    workspaceContextMenuGroup = [
        componentLayerActionItem,
        copyComponent,
        cutComponent,
        duplicateComponent,
        pasteComponent,
    ],

    showHelp = {
        title: <Msg k="contextmenu.item.help.title">Help</Msg>,
        shortcut: getShortcut("contextmenu.item.help.shortcut"),
        actionInput: appActionTypes.SHOW_HELP
    },

    PAGE_TREE_MENU_DUPLICATE = "PAGE_TREE_MENU_DUPLICATE",

    pageRefContextMenuPrimaryGroup: Array<GroupItem> = [
        {
            icon: ContextMenuIcon({ iconName: "settings" }),
            title: "msg: contextmenu.item.settings.title {Settings}",
            actionInput: (page: DataPageRef) => openPageInfoDialogAction({ pageRefId: page.id })
        },
    ],

    pageRefContextMenuSecondaryGroup: Array<GroupItem> = [
        {
            id: PAGE_TREE_MENU_DUPLICATE,
            icon: ContextMenuIcon({ iconName: "duplicate" }),
            title: <Msg k="contextmenu.item.duplicatePage.title">Duplicate page</Msg>,
            actionInput: PAGE_TREE_PAGE_DUPLICATE
        },
        {
            icon: ContextMenuIcon({ iconName: "hide" }),
            title: <Msg k="contextmenu.item.hidePage.title">Hide page</Msg>,
            actionInput: PAGE_TREE_PAGE_HIDE,
            hidden: false
        },
        {
            icon: ContextMenuIcon({ iconName: "show" }),
            title: <Msg k="contextmenu.item.showPage.title">Show page</Msg>,
            actionInput: PAGE_TREE_PAGE_HIDE,
            hidden: true
        },
        {
            icon: ContextMenuIcon({ iconName: "create_subpage" }),
            title: <Msg k="contextmenu.item.createSubpage.title">Create subpage</Msg>,
            actionInput: (page: DataPageRef) => ({ type: NEW_PAGE_DIALOG_OPEN, payload: { selectedPage: page } }),
            showPremiumIcon: (site, subscriptionData) => {
                return site.getPageIdsNotMarkedAsDontPublish().length >= getPageLimitBySubscriptionType(subscriptionData.subscriptionType);
            }
        },
        {
            icon: ContextMenuIcon({ iconName: "delete" }),
            title: <Msg k="contextmenu.item.deletePage.title">Delete page</Msg>,
            actionInput: PAGE_TREE_PAGE_DELETE
        }
    ],

    linkPageContextMenuPrimaryGroup: Array<GroupItem> = [
        {
            icon: ContextMenuIcon({ iconName: "settings" }),
            title: <Msg k="contextmenu.item.settings.title">Settings</Msg>,
            actionInput: (page: DataLinkPage) => openLinkPageInfoDialog(page)
        },
    ],

    linkPageContextMenuSecondaryGroup: Array<GroupItem> = [
        {
            icon: ContextMenuIcon({ iconName: "delete" }),
            title: <Msg k="contextmenu.item.deleteLink.title">Delete link</Msg>,
            actionInput: PAGE_TREE_PAGE_DELETE
        }
    ],

    sectionLinkContextMenuPrimaryGroup: Array<GroupItem> = [
        {
            icon: ContextMenuIcon({ iconName: "settings" }),
            title: <Msg k="contextmenu.item.settings.title">Settings</Msg>,
            actionInput: [loadSectionComponentsForPage, (page: DataSectionLink) => openSectionLinkInfoDialog(page)]
        },
    ],

    sectionLinkContextMenuSecondaryGroup: Array<GroupItem> = [
        {
            icon: ContextMenuIcon({ iconName: "duplicate" }),
            title: <Msg k="contextmenu.item.duplicateLink.title">Duplicate link</Msg>,
            actionInput: SECTION_LINK_DUPLICATE
        },
        {
            icon: ContextMenuIcon({ iconName: "hide" }),
            title: <Msg k="contextmenu.item.hideLink.title">Hide link</Msg>,
            actionInput: PAGE_TREE_PAGE_HIDE,
            hidden: false
        },
        {
            icon: ContextMenuIcon({ iconName: "show" }),
            title: <Msg k="contextmenu.item.showLink.title">Show link</Msg>,
            actionInput: PAGE_TREE_PAGE_HIDE,
            hidden: true
        },
        {
            icon: ContextMenuIcon({ iconName: "delete" }),
            title: <Msg k="contextmenu.item.deleteLink.title">Delete link</Msg>,
            actionInput: PAGE_TREE_PAGE_DELETE
        }
    ],

    componentLockToTop = {
        title: <Msg k="contextmenu.item.lockToTop.title">Lock to top</Msg>,
        actionInput: ''
    },
    componentLockToBottom = {
        title: <Msg k="contextmenu.item.lockToBottom.title">Lock to bottom</Msg>,
        actionInput: [MOBILE_EDITOR_LOCK_COMPONENTS, removeOutlineAndSelectionMobileViewEditorAC]
    },
    componentUnlockFromBottom = {
        title: <Msg k="contextmenu.item.unlockFromBottom.title">Unlock from bottom</Msg>,
        actionInput: [MOBILE_EDITOR_UNLOCK_COMPONENTS, removeOutlineAndSelectionMobileViewEditorAC]
    },

    componentHideInMobile = {
        title: <Msg k="contextmenu.item.hide.title">Hide</Msg>,
        shortcut: getShortcut("contextmenu.item.delete.shortcut"),
        actionInput: [MOBILE_EDITOR_HIDE_COMPONENTS, removeOutlineAndSelectionMobileViewEditorAC]
    },

    CTX_MENU_LOCK_TO_TOP = 'CTX_MENU_LOCK_TO_TOP',
    CTX_MENU_LOCK_TO_BOTTOM = 'CTX_MENU_LOCK_TO_BOTTOM',
    CTX_MENU_UNLOCK_FROM_BOTTOM = 'CTX_MENU_UNLOCK_FROM_BOTTOM',
    CTX_MENU_MOBILE_HIDE = 'CTX_MENU_MOBILE_HIDE',

    mobileViewCommonActionItems = {
        [CTX_MENU_UNDO]: undoLastAction,
        [CTX_MENU_REDO]: redoLastAction,
        // [CTX_MENU_LOCK_TO_TOP]: componentLockToTop,
        [CTX_MENU_LOCK_TO_BOTTOM]: componentLockToBottom,
        [CTX_MENU_UNLOCK_FROM_BOTTOM]: componentUnlockFromBottom,
    },

    mobileViewCommonActionItemsOrder = [
        CTX_MENU_UNDO,
        CTX_MENU_REDO,
        // CTX_MENU_LOCK_TO_TOP,
        CTX_MENU_LOCK_TO_BOTTOM,
        CTX_MENU_UNLOCK_FROM_BOTTOM
    ],

    mobileViewSecondaryActionItems = {
        [CTX_MENU_MOBILE_HIDE]: componentHideInMobile
    },

    mobileViewSecondaryActionItemsOrder = [
        CTX_MENU_MOBILE_HIDE
    ],
    leftPanelComponentsTabContextMenuGroup = [showHelp],

    componentWrapTopLeft = {
        title: <Msg k="contextmenu.item.wrapTopLeft.Title">Wrap left</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_WRAP_TOP_LEFT
    },
    componentWrapTopRight = {
        title: <Msg k="contextmenu.item.wrapTopRight.Title">Wrap right</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_WRAP_TOP_RIGHT
    },
    componentWrapToCenter = {
        title: <Msg k="contextmenu.item.wrapToCenter.Title">Wrap center</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_WRAP_TO_CENTER
    },
    componentWrapBelow = {
        title: <Msg k="contextMenu.item.wrapDown.Title">Wrap below</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_WRAP_BELOW
    },
    componentWrapAbove = {
        title: <Msg k="contextMenu.item.wrapUp.Title">Wrap above</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_WRAP_ABOVE
    },
    componentUnwrap = {
        title: <Msg k="contextmenu.item.unwrap.Title">Unwrap</Msg>,
        actionInput: workspaceActionTypes.SELECTED_COMPONENT_UNWRAP
    },

    splitSection = (componentId: string, props: Object = {}) => ({
        title: <Msg k="contextmenu.item.splitSection.Title">Split section</Msg>,
        icon: ContextMenuIcon({ iconName: 'splitSection' }),
        className: styles.splitSection,
        actionInput: () => ({ type: SPLIT_SECTION_LOCATION, payload: componentId }),
        ...props
    }),

    splitHeaderFooterSection = (selectedComponentsIds: Array<string>, props: Object = {}) => {
        const title = selectedComponentsIds.length === 1 ?
            <Msg k="contextmenu.item.splitHeaderSection.Title">Split Header section</Msg> :
            <Msg k="contextmenu.item.splitHeaderFooterSection.Title">Split Header & Footer section</Msg>;
        return {
            title,
            icon: ContextMenuIcon({ iconName: 'splitSection' }),
            className: styles.splitSection,
            actionInput: () => ({ type: FIX_ALL_HEADER_LAYOUT, payload: selectedComponentsIds }),
            ...props
        };
    },

    pinToScreen = (componentId: string) => ({
        title: <Msg k="contextmenu.item.pinToScreen.title">Pin to screen</Msg>,
        icon: ContextMenuIcon({ iconName: 'pin' }),
        actionInput: () => pinToScreenAC(componentId)
    }),
    pinToTop = () => ({
        title: <Msg k="contextmenu.item.pinToTop.title">Pin to top</Msg>,
        icon: ContextMenuIcon({ iconName: 'pin' }),
        actionInput: () => componentPinToTopAC()
    }),
    pinToBottom = () => ({
        title: <Msg k="contextmenu.item.pinToBottom.title">Pin to bottom</Msg>,
        icon: ContextMenuIcon({ iconName: 'pin' }),
        actionInput: () => componentPinToBottomAC()
    }),
    unPin = () => ({
        title: <Msg k="contextmenu.item.unpin.title">Unpin</Msg>,
        icon: ContextMenuIcon({ iconName: 'pin' }),
        actionInput: () => componentUnpinAC()
    }),
    pinSettings = (componentId: string) => ({
        title: <Msg k="contextmenu.item.pinSettings.title">Pin settings</Msg>,
        icon: ContextMenuIcon({ iconName: 'pin' }),
        actionInput: () => openPinDialogAC(componentId)
    });
