import { bookingsInserterGlobalDataVAT } from ".";
import { makeEpicStateSelector } from "../../../../../epics/makeEpic";
import { BookingsInserterViewTypes, BookingsOnboardingSteps } from "../types";

const bookingsInserterEpicSelector = makeEpicStateSelector(bookingsInserterGlobalDataVAT);

export const selectIsWaitingForBookingsAPI = (state): boolean =>
    bookingsInserterEpicSelector(state).waitingForAPIResponse;

export const selectBookingsInserterView = (state): BookingsInserterViewTypes =>
    bookingsInserterEpicSelector(state).inserterView;

export const selectShowBookingsUpgradeIcon = (state): boolean =>
    bookingsInserterEpicSelector(state).showUpgradeIcon;

export const selectBookingsOnboardingSteps = (state): BookingsOnboardingSteps =>
    bookingsInserterEpicSelector(state).onboardingSteps;
