import { path, differenceWith } from 'ramda';
import { OldComponentTypes } from "../../../../../dal/pageMapAdapter/componentTypesMap";
import { toHsl } from "../../../../../dal/pageMapAdapter/mappers/Base/color";
import {
    blackColorValueHSL,
    operationDetailsDataPagesPath,
    operationDetailsSiteMapPath,
    whiteColorValueHSL
} from "../../../ThemeGlobalData/constants";
import localStorage from '../../../../utils/localStorage';
import { DT_CONCEPT_GENERATION_COUNT, DT_GMBKEY } from "../constants";
import { DynamicOnboardingEpicState } from "./types";
import { isPageRef } from '../../../../../dal/model/utils/dataSiteItemUtils';
import DataSite from "../../../../../dal/model/DataSite";

const extractTextFromTextComponents = (items) => {
    return items
        .reduce((acc, { type, text }) => {
            if (type === OldComponentTypes.TEXT) {
                acc.push(text);
            }
            return acc;
        }, []);
};

const clearGptDataInUrl = () => {
    const currURL = new URL(window.location.href);
    currURL.searchParams.delete('gptid');
    currURL.searchParams.delete('source');
    window.history.replaceState({}, '', currURL.toString());
};

interface AdditionalProps {
    pagesToGenerate: string[]
    oldTexts?: Record<string, Object>
    filterSections?: string[]
}

const constructPayloadForAIOnboarding = (onboardingEpicState: DynamicOnboardingEpicState, additionalProps: AdditionalProps) => {
    let defaultColor: any = {};
    const { contactInfo, shopDisabled, gmbKey, isGroup,
        businessName, purpose: _purpose, keywords, showShopWelcome, isMultipageAllowed,
        language, concept: { name, optionSet } } = onboardingEpicState;
    const defaultPalette = onboardingEpicState.concept?.colorThemes[0];
    const { addressLocation, address, addressUrl, addressCountryCode, addressCity } = contactInfo?.completeAddress || ({} as any);

    const submitCount = parseInt(localStorage.get(DT_CONCEPT_GENERATION_COUNT) || 1, 10);
    const addressData = {
        addressLocation,
        addressText: address,
        addressUrl
    };
    if (defaultPalette?.length === 2) {
        defaultColor = {
            accentColor: toHsl(defaultPalette[0]),
            mainColor: toHsl(defaultPalette[1]),
            whiteColor: whiteColorValueHSL,
            blackColor: blackColorValueHSL
        };
    }

    let purpose = shopDisabled && optionSet?.key === 'shop' ? [optionSet.set[0]] : [..._purpose];

    return {
        websiteTitle: businessName,
        country: addressCountryCode,
        city: addressCity,
        gmbKey,
        group: isGroup,
        concept: name,
        aboutKeywords: keywords,
        optionSet: purpose,
        defaultColor,
        addressData: addressUrl ? addressData : {},
        language,
        additionalData: {
            localGmbKey: localStorage.get(DT_GMBKEY)
        },
        addShopSection: showShopWelcome && !['onlineShopCatchall', 'shops'].includes(name),
        isMultipageAllowed,
        submitCount,
        ...additionalProps,
    }
}

const getPageIdsToLoad = (data) => {
    const siteData = path(operationDetailsSiteMapPath)(data);
    if (!siteData) {
        return [];
    }
    const site = new DataSite(siteData);
    const allPages = site.getAllItems().filter(p => isPageRef(p));
    const loadedPages = path(operationDetailsDataPagesPath)(data);

    if (allPages && allPages.length) {
        return differenceWith(
            (a, b) => a.pageId === b.id,
            allPages,
            loadedPages
        ).map(page => page.pageId);
    } else {
        return [];
    }
}

export {
    extractTextFromTextComponents,
    clearGptDataInUrl,
    constructPayloadForAIOnboarding,
    getPageIdsToLoad
};
