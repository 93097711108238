import * as React from "react";
import { useDispatch } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";

import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button, Grid } from "@mui/material";
import cx from "classnames";
import { useIntl, Msg } from "../../../../../../../wbtgen/src/view/intl";
import { UpgradeDialogPlanTypeMap } from "../../../../../../../wbtgen/src/components/TopBar/view/Upgrade/UpgradePlansDialog/constants";
import { getCapitalizedCaseName } from "../utils";
import {
    isBasicSubscription,
    isEcommerceSubscription
} from "../../../../../../../wbtgen/src/components/App/epics/subscriptionData/utils";
import { getPlanPricePerMonthWithoutCurrency } from "../../../../../../../wbtgen/src/components/TopBar/view/Upgrade/utils";
import { StyledTableCell, StyledTableRow, StyledTableSpecialCell, StyledTableSpecialRow, TopTableCell, useStyles } from "./fullTableStyles";
import { getFeaturesList } from "./Features";
import { onClickHandler } from "./PlanSelectionView";
import { useIsMobile, useIsTablet } from "../../../../hooks/useIsMobileOrTablet";
import { Stepper } from "./stepper";
import UpgradeText from "../../../../../../../wbtgen/src/components/UpgradeText";

const getCostText = (plan) => {
    const
        { addonProductToUpgrade, chargesForUpgrade, freeUpgradeAvailable } = plan,
        isBasicUser = isBasicSubscription(addonProductToUpgrade),
        pricePerMonth = getPlanPricePerMonthWithoutCurrency(chargesForUpgrade),
        { currencyCode } = chargesForUpgrade;

    const pricePerMonthText = `${currencyCode} ${(isBasicUser || freeUpgradeAvailable) ? 0 : pricePerMonth},- `;

    return pricePerMonthText;
};

const getRenewalInfo = (plan, intl: Intl) => {
    const
        { chargesForUpgrade } = plan,
        pricePerMonth = getPlanPricePerMonthWithoutCurrency(chargesForUpgrade),
        { currencyCode } = chargesForUpgrade;

    return intl.msgJoint([`msg: upgrade.plan.renewal.info_new {Renews at {pricePerMonth}{currencyCode} /month}`, { currencyCode, pricePerMonth }]) + ". " + intl.msgJoint("msg: common.billed.yearly {Billed yearly}");
};

const getInfoText = (plan, intl: Intl): string => {
    const { addonProductToUpgrade, freeMonths, freeUpgradeAvailable, isDowngradeAvailable } = plan;

    if (isBasicSubscription(addonProductToUpgrade)) return intl.msgJoint("msg: upgrade.plan.included {Included in hosting plan}");
    if (isDowngradeAvailable) return intl.msgJoint("msg: upgrade.plan.downgradeInfo {In case you want to downgrade please, go to control panel or contact support}");
    if (freeUpgradeAvailable && freeMonths) return intl.msgJoint([`msg: upgrade.plan.free.monthsWithoutAsterisk {Free for the next {freeMonths} months}`, { freeMonths }]);

    return "";
};

const getButton = ({ plan, classes, dispatch, currentsubscriptionType }) => {
    const isEcommercePlan = isEcommerceSubscription(plan.addonProductToUpgrade);
    const { chargesForUpgrade, freeMonths, addonProductToUpgrade, freeUpgradeAvailable, isDowngradeAvailable } = plan;
    const isCurrentPlan = plan.addonProductToUpgrade === currentsubscriptionType;

    if (isDowngradeAvailable) return null;

    let buttonText = (<Msg k="common.select">Select</Msg>);
    if (isCurrentPlan) {
        buttonText = (<Msg k="common.current">Current</Msg>);
    } else if (isEcommercePlan) {
        buttonText = (<UpgradeText />);
    }

    return <Button
        variant={(isCurrentPlan || isEcommercePlan) ? "contained" : "outlined"}
        color="primary"
        className={classes}
        disabled={isCurrentPlan}
        data-testid={addonProductToUpgrade + "_select_btn_full_table"}
        onClick={() => onClickHandler({
            dispatch,
            isFreeUpgradeAvailable: freeUpgradeAvailable,
            upgradeType: addonProductToUpgrade,
            freeMonths,
            chargesForUpgrade
        })}
    >
        {buttonText}
    </Button>;
};

const getHeader = ({ plansList, intl, classes, dispatch, currentsubscriptionType, handleNext, handleBack, selected, isMobile, isTablet, totalSteps }) => {
    const list = {
        name: plansList
            .map(plan => plan.addonProductToUpgrade)
            .map(type => {
                return <div
                    className={classes.planName}
                    data-testid={type + "_plan_heading"}
                    key={type}
                >
                    {getCapitalizedCaseName(intl.msgJoint(UpgradeDialogPlanTypeMap[type]))}
                </div>;
            }),
        cost: plansList.map((plan, index) => {
            return <div className={classes.pice} key={index}>
                {
                    plan.isDowngradeAvailable ? "-" : <React.Fragment>{getCostText(plan)}<span className={classes.perMonth}>{intl.msgJoint("msg: upgrade.plan.month {/month}")}</span></React.Fragment>
                }
            </div>;
        }),
        info: plansList.map((plan, index) => {
            return <div
                className={cx(classes.info, { [classes.downgradeInfo]: (plan.isDowngradeAvailable && !isBasicSubscription(plan.addonProductToUpgrade)) })}
                key={index}
            >
                {getInfoText(plan, intl)}
            </div>;
        }),
        renew: plansList.map((plan, index) => {
            if (plan.isDowngradeAvailable) return null;

            return <div className={cx(classes.renew, { [classes.hideRenew]: isBasicSubscription(plan.addonProductToUpgrade) })} key={index}>{getRenewalInfo(plan, intl)}</div>;
        }),
        actionBtn: plansList.map(plan => getButton({
            plan,
            classes: classes.actionBtn,
            dispatch,
            currentsubscriptionType
        }))
    };

    if (isMobile) {
        // @ts-ignore
        list.stepper = plansList.map((_plan, index) => {
            return <Stepper
                totalSteps={totalSteps}
                handleNext={handleNext}
                handleBack={handleBack}
                activeStepIndex={selected}
                key={index}
            />;
        });
    } else if (isTablet) {
        // @ts-ignore
        list.stepper = [<Stepper
            totalSteps={totalSteps}
            handleNext={handleNext}
            handleBack={handleBack}
            activeStepIndex={selected}
        />, ''];
    }

    return list;
};

export const FullTable = ({ plansList, currentsubscriptionType, handleNext, handleBack, selected, totalSteps, hideRecommended }) => {
    const intl = useIntl();
    const classes = useStyles();
    const dispatch = useDispatch();
    const plans = plansList;
    const isMobile = useIsMobile();
    const isTablet = useIsTablet();
    const header = getHeader({ intl, plansList: plans, classes, dispatch, currentsubscriptionType, handleNext, handleBack, selected, isMobile, isTablet, totalSteps });
    const featuresList = getFeaturesList(intl, plans);
    let showRecommended = false;

    if ((plansList.length === 1 || plansList.length === 2) && isEcommerceSubscription(plansList[0].addonProductToUpgrade)) {
        showRecommended = true;
    }

    if (!isMobile && !isTablet) {
        showRecommended = true;
    }

    if (hideRecommended) showRecommended = false;

    const renderHeader = () => {
        return <React.Fragment>
            <TableHead>
                <TableRow>
                    {isMobile ? null : <TopTableCell />}
                    <TopTableCell
                        colSpan={isMobile ? 2 : 1}
                        className={cx(classes.recommended, { [classes.hideRecommended]: !showRecommended })}
                    >
                        <div>{intl.msgJoint("msg: common.recommended {Recommended}")}</div>
                    </TopTableCell>
                    {
                        //eslint-disable-next-line no-nested-ternary
                        isMobile ? null : (
                            isTablet ? <TopTableCell /> : <React.Fragment><TopTableCell /><TopTableCell /><TopTableCell /></React.Fragment>
                        )
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(header).map((headerKey) => {
                    const headersList = isMobile ? header[headerKey] : ["", ...header[headerKey]];

                    return <StyledTableSpecialRow key={headerKey}>
                        {headersList.map((item, index) => (
                            <StyledTableSpecialCell colSpan={isMobile ? 2 : 1} key={index}>{item}</StyledTableSpecialCell>
                        ))}
                    </StyledTableSpecialRow>;
                })}
            </TableBody>
        </React.Fragment>;
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Table className={classes.table}>
                    {renderHeader()}
                    {
                        featuresList.map(section => (
                            <React.Fragment key={section.name}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell width="80%" colSpan={5} className={classes.sectionName}>{section.name}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {section.rows.map((row, index) => (
                                        <StyledTableRow key={index}>
                                            {row.map((item, index) => <StyledTableCell key={index}>{item}</StyledTableCell>)}
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </React.Fragment>
                        ))
                    }
                </Table>
            </Grid>
        </Grid>
    );
};
