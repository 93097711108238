import { ENABLE_MARKETING_CONSENT, DISABLE_MARKETING_CONSENT, } from "./actionTypes";

const ContactFormEvents = {
    MarketingConsentChecked: "WSB marketing consent checkbox added to form",
    MarketingConsentUnchecked: "WSB marketing consent checkbox removed from form",
};

export const ContactFormMixpanelEvents = {
    [ENABLE_MARKETING_CONSENT]: {
        eventName: ContactFormEvents.MarketingConsentChecked,
    },
    [DISABLE_MARKETING_CONSENT]: {
        eventName: ContactFormEvents.MarketingConsentUnchecked,
    },
};
