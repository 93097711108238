import React from "react";
import { IntlShape } from "react-intl";
import getCenteredDialogConfig from "../../DialogManager/getCenteredDialogConfig";
import { closeDialog } from "../../App/actionCreators/index";
import { DialogV2 } from "../../../view/common/dialogs/DialogV2/index";
import styles from "../view/Backup.css";
import InputField from "../../../view/common/Input/InputField";
import lengthInputValidation from "../../inputControls/input/commonValidations/lengthInputValidation";
import { NAME_SAVEPOINT } from "../actionTypes";
import NamedBackup from "../../../../dal/model/NamedBackup";

const dialogId = "NamedBackupTitleDialog";
const width = 700;
const height = 318;

interface NamedBackupTitleDialogProps {
    dispatch: (action: any) => void;
    timestamp: string;
    existingTitle?: string;
    intl: Intl & IntlShape;
}

const NamedBackupTitleDialog: React.FC<NamedBackupTitleDialogProps> = (
    {
        dispatch,
        intl,
        timestamp,
        existingTitle
    }
) => {
    const [titleValue, setTitleValue] = React.useState(existingTitle || '');
    return (
        <DialogV2
            title="msg: component.backup.description {Backup description}"
            titleClassName={styles.namedBackupDialogTitle}
            subTitle="msg: component.backup.backup {Backup}"
            mctaText="msg: common.save {Save}"
            mctaHandler={() => {
                dispatch({
                    type: NAME_SAVEPOINT,
                    payload: {
                        payload: new NamedBackup({ title: titleValue, timestamp }),
                        requestType: existingTitle ? 'edit' : 'add'
                    }
                });
            }}
            sctaHandler={() => {
                dispatch(closeDialog());
            }}
            sctaText="msg: common.cancel {Cancel}"
            disabled={titleValue === ""}
        >
            <div className={styles.inputBoxContainer}>
                <InputField
                    className={styles.inputBox}
                    placeholder={`${intl.msgJoint("msg: common.description {Description}")}`}
                    intl={intl}
                    counter={{ maxLength: 100 }}
                    inputValidations={[
                        {
                            validation: lengthInputValidation,
                            options: { max: 100 }
                        }
                    ]}
                    value={titleValue}
                    onChange={setTitleValue}
                    usePropsValue
                    stopOnKeyDownEventPropagation
                    rejectInvalidValue
                    trimValueOnPaste
                />
            </div>

        </DialogV2>
    );
};

export { NamedBackupTitleDialog };

const dialog = getCenteredDialogConfig(width, height, NamedBackupTitleDialog);

export { dialogId, dialog };
