import React, { useEffect } from "react";
import { getBookingsScriptUrl } from "../utils";

const View = (props) => {
    const { locale } = props;
    const scriptUrl = getBookingsScriptUrl(locale, true);
    useEffect(() => {
        let scriptEle;
        const fetchScript = async () => {
            try {
                const response = await fetch(scriptUrl),
                    scriptText = await response.text();
                scriptEle = document.createElement('script');
                scriptEle.textContent = scriptText;
                document.body.appendChild(scriptEle);
            } catch (error) {
                console.error('Failed to fetch script:', error);
            }
        };
        fetchScript();
        //cleanup scripts from body
        return () => {
            if (!scriptEle) { return }
            document.body.removeChild(scriptEle);
        };
    }, []);

    useEffect(() => {
        if (!window.refreshBookings) { return; }
        window.refreshBookings();
    }, []);

    return (
        <div style={{ height: "100%" }} data-testid="bookings-view">
            <script defer src={getBookingsScriptUrl()}> </script>
            <onecom-bookings-main
                class="onecom-bookings-main"
                params='"backgroundColor":"white","themeColor":"blue","embedType":"websitebuilder"'
            />
        </div>
    );
};

export default View;
