import React from 'react';
import { pure } from 'recompose';
import styles from './Icons.css';
import { injectIntl } from "../intl/index";
import type { Intl } from "../intl/injectIntl";

type IconProps = {
    border?: boolean,
    className?: string,
    style?: any,
    title?: string,
    intl: Intl,
    iconStyle?: React.CSSProperties
}

const
    basePath = './svg/',
    // WBTGEN-2412: somehow flow does not recognize Map<React.Component<IconProps>> :(
    Icons: MapT<AnyValue> = {
        ADD_COLUMN: { width: '29px', height: '15px' },
        ADD_LAYER: { width: '15px', height: '15px' },
        ADD_LINK_MENU_TREE: { width: '33px', height: '33px' },
        ADD_LINK_MENU_TREE_HOVER: { width: '33px', height: '33px' },
        ADDRESS_INSERTER_ICON: { width: '15px', height: '20px' },
        ARROW_DOWN: { width: '11.312px', height: '7.094px' },
        ARROW_UP: { width: '11px', height: '7px' },
        ARROW_RIGHT: { width: '4.469px', height: '9px' },
        ARROW_LEFT: { width: '13px', height: '13px' },
        BACKGROUND: { width: '16px', height: '16px' },
        HOVERBOX: { width: '22px', height: '22px' },
        BIN: { width: '12px', height: '15px' },
        BOLD: { width: '7.969px', height: '9.938px' },
        BUTTON: { width: '21px', height: '11px' },
        CENTER_BOX: { width: '18px', height: '18px' },
        CHECKMARK: { width: '8.5px', height: '6.375px' },
        REMOVE_FORMAT: { width: '15px', height: '12px' },
        CONTACT_FORM: { width: '19px', height: '15px' },
        CODE: { width: '15px', height: '15px' },
        COLOR_THEME_ICON: { width: '28px', height: '28px' },
        COLOR_THEME_ICON_BLUE: { width: '26px', height: '26px' },
        COLOR_THEME_ICON_BLUE_2: { width: '68px', height: '68px' },
        CROSS: { width: '16.469px', height: '16.688px' },
        CROSS_RED_IN_CIRCLE_WHITE: { width: '16px', height: '16px' },
        CROSS_WHITE_IN_CIRCLE_RED: { width: '16px', height: '16px' },
        CROSS_WITH_CIRCLE: { width: '14px', height: '14px' },
        DESKTOP: { width: '25px', height: '22px' },
        DOT: { width: '3px', height: '3px' },
        DOTS: { width: '14px', height: '14px' },
        DOWN_ARROW: { width: '14px', height: '18px' },
        UP_ARROW: { width: '14px', height: '18px' },
        DROPDOWN_ARROW: { width: '7px', height: '3.719px' },
        DRAG_HANDLE: { width: '2px', height: '7px' },
        DRAGGING_HANDLE: { width: '7px', height: '15x' },
        EXTERNAL_LINK: { width: '11px', height: '11px' },
        EMAIL_INSERTER_ICON: { width: '20px', height: '15px' },
        FILEUPLOAD_ICON: { width: '32px', height: '32px' },
        FRIENDLY_CAPTCHA_ICON: { width: '32px', height: '32px' },
        FULL_WIDTH: { width: '174px', height: '8px' },
        GEAR: { width: '10.8px', height: '10.8px' },
        GROUP_ICON: { width: '24px', height: '21px' },
        MAGIC_WAND: { width: '15.9px', height: '15.9px' },
        MAP: { width: '15.438px', height: '15.438px' },
        MENU: { width: '17px', height: '16px' },
        MENU_ICON: { width: '11px', height: '3px' },
        MENU_INACTIVE: { width: '24px', height: '16px' },
        MENU_VERTICAL_TREE: { width: '14px', height: '11px' },
        MENU_VERTICAL_DROPDOWN: { width: '14px', height: '8px' },
        MENU_VERTICAL_SINGLE_LEVEL: { width: '14px', height: '5px' },
        MENU_HORIZONTAL_DROPDOWN: { width: '14px', height: '11px' },
        MENU_HORIZONTAL_SINGLE_LEVEL: { width: '14px', height: '2px' },
        RE_ARRANGE_ILLUSTRATION: { width: '38px', height: '42px' },
        MINUS: { width: '10px', height: '2px' },
        MORE: { width: '13px', height: '3px' },
        MOBILE: { width: '14px', height: '21px' },
        MVE_ILLUSTRATION: { width: '410px', height: '647px' },
        MVE_GREEN_TRIANGLE: { width: '263px', height: '438px' },
        GALLERY: { width: '20px', height: '13px' },
        LOGOUT: { width: '17px', height: '19px' },
        MIRROW: { width: '20.188px', height: '19.437px' },
        ROTATE_RIGHT: { width: '16px', height: '13px' },
        ROTATE_LEFT: { width: '16px', height: '13px' },
        ROTATE_MOBILE: { width: '17.57px', height: '17.57px' },
        MOBILE_LANDSCAPE: { width: '651px', height: '340px' },
        MOBILE_PORTRAIT: { width: '340px', height: '651px' },
        LINK: { width: '18px', height: '7px' },
        TILTED_LINK: { width: '15.9px', height: '15.9px' },
        EDIT: { width: '12px', height: '12px' }, // 16.531
        REPOSITION: { width: '24px', height: '24px' },
        AI_TEXT: { width: '24px', height: '24px' },
        INFO_SQUARE: { width: '13px', height: '13px' },
        I: { width: '13px', height: '13px' },
        IMAGE: { width: '17px', height: '12px' },
        INFO_CIRCLE: { width: '14px', height: '14px' },
        INSERT_ROW: { width: '25px', height: '18px' },
        FACEBOOK_LOGO: { width: '15px', height: '15px' },
        FACEBOOK_LOGO_FULL: { width: '15px', height: '15px' },
        ITALIC: { width: '4px', height: '10.719px' },
        LARGE_TEXT_ALIGN_CENTER: { width: '19px', height: '18px' },
        LARGE_TEXT_ALIGN_FULL: { width: '19px', height: '18px' },
        LARGE_TEXT_ALIGN_LEFT: { width: '19px', height: '18px' },
        LARGE_TEXT_ALIGN_RIGHT: { width: '19px', height: '18px' },
        LEFT_WITH_BACKGROUND: { width: '17px', height: '17px' },
        MAGNIFIER: { width: '15px', height: '15px' },
        ORDERED_LIST: { width: '19px', height: '16px' },
        DOCUMENT: { width: '14px', height: '16px' },
        PREVIEW: { width: '17px', height: '13px' },
        PREMIUM_UPGRADE_LARGE: { width: '9px', height: '14px' },
        PROFILE: { width: '19px', height: '19px' },
        PHONE_INSERTER_ICON: { width: '15px', height: '20px' },
        PLUS1: { width: '17px', height: '17px' },
        PLUS2: { width: '14px', height: '14px' },
        PLUS_LARGE: { width: '14px', height: '14px' },
        UNORDERED_LIST: { width: '17px', height: '12px' },
        REMOVE_ROW: { width: '25px', height: '18px' },
        REMOVE_COLUMN: { width: '29px', height: '15px' },
        QUESTION: { width: '4.469px', height: '7.281px' },
        SAVE: { width: '17px', height: '17px' },
        SETTINGS: { width: '16.6px', height: '16.6px' },
        SLIDER: { width: '17px', height: '17px' },
        SHARE: { width: '14px', height: '15px' },
        SHOP: { width: '18px', height: '16px' },
        WEBSHOP: { width: '20px', height: '16px' },
        WEBSHOP_POLICIES: { width: '25px', height: '24px' },
        WEBSHOP_PAYMENT_METHODS: { width: '25px', height: '24px' },
        SPEAK: { width: '14px', height: '14px' },
        SUBSCRIPT: { width: '13px', height: '12px' },
        SUPERSCRIPT: { width: '13px', height: '12px' },
        TABLE: { width: '19px', height: '13px' },
        TEXT: { width: '14px', height: '14px' },
        TEXT_ALIGN_CENTER: { width: '13px', height: '12px' },
        TEXT_ALIGN_FULL: { width: '13px', height: '12px' },
        TEXT_ALIGN_LEFT: { width: '13px', height: '12px' },
        TEXT_ALIGN_RIGHT: { width: '13px', height: '12px' },
        TEXT_ARROW_UP: { width: '19px', height: '13px' },
        TEXT_ARROW_DOWN: { width: '19px', height: '13px' },
        TEXT_ARROWS_MEET: { width: '19px', height: '16px' },
        TEXT_COLOR: { width: '6px', height: '6px' },
        RIGHT_ALIGN: { width: '35px', height: '34px' },
        CENTER_ALIGN: { width: '35px', height: '34px' },
        STRETCH_ALIGN: { width: '35px', height: '34px' },
        LEFT_ALIGN: { width: '35px', height: '34px' },
        INDENT: { width: '18px', height: '18px' },
        OUTDENT: { width: '18px', height: '18px' },
        TOP: { width: '10px', height: '5px' },
        BOTTOM: { width: '10px', height: '5px' },
        LEFT: { width: '5px', height: '10px' },
        LEFT_TOP: { width: '7px', height: '7px' },
        LEFT_BOTTOM: { width: '7px', height: '7px' },
        RIGHT: { width: '5px', height: '10px' },
        RIGHT_TOP: { width: '7px', height: '7px' },
        RIGHT_BOTTOM: { width: '7px', height: '7px' },
        RIGHT_WITH_BACKGROUND: { width: '17px', height: '17px' },
        VIDEO: { width: '17px', height: '15px' },
        GOOGLE_REVIEWS: { width: '15px', height: '15px' },
        UNDERLINE: { width: '8px', height: '11px' },
        CHECKERBOARD: { width: '16px', height: '16px' },
        UNLINK: { width: '14px', height: '8px' },
        UPLOAD: { width: '25px', height: '25px' },
        ZOOM_PLUS: { width: '25px', height: '20px' },
        NAME: { width: '30px', height: '30px' },
        EMAIL: { width: '30px', height: '30px' },
        COMPANY: { width: '30px', height: '30px' },
        NUMBER: { width: '30px', height: '30px' },
        PHONE: { width: '30px', height: '30px' },
        MESSAGE: { width: '30px', height: '30px' },
        RADIOBUTTON: { width: '30px', height: '30px' },
        TEXTLARGE: { width: '30px', height: '30px' },
        WEBSITE: { width: '35px', height: '30px' },
        CHECKBOX: { width: '30px', height: '30px' },
        DROPDOWN: { width: '35px', height: '30px' },
        NAME_ICON: { width: '32px', height: '32px' },
        EMAIL_ICON: { width: '32px', height: '32px' },
        COMPANY_ICON: { width: '32px', height: '32px' },
        NUMBER_ICON: { width: '32px', height: '32px' },
        PHONE_ICON: { width: '32px', height: '32px' },
        MESSAGE_ICON: { width: '32px', height: '32px' },
        RADIOBUTTON_ICON: { width: '32px', height: '32px' },
        CHECKBOX_ICON: { width: '32px', height: '32px' },
        DROPDOWN_ICON: { width: '32px', height: '32px' },
        WEBSITE_ICON: { width: '32px', height: '32px' },
        TEXT_ICON: { width: '32px', height: '32px' },
        DRAGDROP: { width: '7px', height: '15px' },
        DRAG_DROP_ACTIVE: { width: '16px', height: '16px' },
        DRAG_DROP_DISABLED: { width: '16px', height: '16px' },
        BORDER: { width: '36px', height: '36px' },
        CORNER: { width: '36px', height: '36px' },
        SPACING: { width: '36px', height: '36px' },
        CLOSE: { width: '26px', height: '26px' },
        COPY: { width: '19px', height: '19px' },
        PAGES: { width: '15px', height: '16px' },
        HOME_PAGE: { width: '10px', height: '12px' },
        LOCKED_PAGE: { width: '11px', height: '11px' },
        HIDDEN_PAGE: { width: '14px', height: '10px' },
        HIDDEN_PAGE_ICON: { width: '18px', height: '11px' },
        HIDDEN: { width: '28px', height: '22px' },
        SOCIAL_LINKS: { width: '19px', height: '19px' },
        HINT: { width: '17px', height: '17px' },
        HINT_LARGE: { width: '34px', height: '34px' },
        HINT_LARGE_WHITE: { width: '34px', height: '34px' },
        TOPMENU_UPGRADE: { width: '5.97px', height: '9.57px' },
        TOPMENU_UPGRADE_LARGE: { width: '9px', height: '15px' },
        LOADING: { width: '24px', height: '24px' },
        LOADING_NON_PREMIUM: { width: '24px', height: '24px' },
        CLOSE_LIGHT: { width: '12px', height: '17px' },
        DONE: { width: '53px', height: '53px' },
        CONFIRMATION_CHECKMARK: { width: '73px', height: '73px' },
        WSB_PREMIUM: { width: '52px', height: '40px' },
        PREMIUM_CHECKMARK: { width: '13px', height: '13px' },
        COG: { width: '13px', height: '13px' },
        TOOLTIP_DEFAULT: { width: '14px', height: '14px' },
        TOOLTIP_HOVER: { width: '14px', height: '14px' },
        TOPMENU_BACKUP: { width: '19px', height: '19px' },
        LINKED: { width: '18px', height: '7px' },
        BLUE_COG: { width: '17px', height: '17px' },
        BORDER_DISABLED: { width: '36px', height: '36px' },
        CORNER_DISABLED: { width: '36px', height: '36px' },
        SPACING_DISABLED: { width: '36px', height: '36px' },
        LOWERCASE: { width: '17px', height: '16px' },
        UPPERCASE: { width: '15px', height: '15px' },
        COG_DISABLED: { width: '17px', height: '17px' },
        DESKTOP_TOP_BAR: { width: '20px', height: '15px' },
        MOBILE_TOP_BAR: { width: '12px', height: '20px' },
        DELETEWHITE: { width: '14px', height: '14px' },
        ADDROW: { width: '19px', height: '15px' },
        ADDCOLUMN: { width: '15px', height: '19px' },
        REMOVEROW: { width: '19px', height: '17px' },
        REMOVECOLUMN: { width: '17px', height: '19px' },
        FONT_SIZE_DECREASE: { width: '14px', height: '18px' },
        FONT_SIZE_INCREASE: { width: '20px', height: '20px' },
        CELLALIGNTOP: { width: '16px', height: '16px' },
        CELLALIGNMIDDLE: { width: '16px', height: '16px' },
        CELLALIGNBOTTOM: { width: '16px', height: '16px' },
        CLEARFORMATING: { width: '16px', height: '16px' },
        WEBSHOP_DEFAULT_SELECTED: { width: '117px', height: '117px' },
        DOWNLOAD_ARROW_IMAGE_EDITOR: { width: '14x', height: '14px' },
        WEBSHOP_CARD_UNSELECTED: { width: '117px', height: '117px' },
        LOGO_INSERTER: { width: '20px', height: '16px' },
        TRASHCAN: { width: '16px', height: '17px' },
        GROUP_IMAGE_BIGGER: { width: '41px', height: '34px' },
        GROUP_IMAGE_SMALLER: { width: '41px', height: '34px' },
        GROUP_IMAGE_BIGGER_DISABLED: { width: '41px', height: '34px' },
        GROUP_IMAGE_SMALLER_DISABLED: { width: '41px', height: '34px' },
        THEME_ACCORDION_ARROW: { width: '13px', height: '8px' },
        REVERT: { width: '14px', height: '14px' },
        INSTAGRAM_GALLERY: { width: '20px', height: '13px' },
        FACEBOOK_SMALL_GRAY: { width: '18px', height: '18px' },
        OPENING_HOURS_ICON: { width: '18px', height: '18px', fill: '#3c3c3c', stroke: '#3c3c3c' },
        GMB_LISTING_ICON: { width: '70px', height: '70px' },
        CHAT_WIDGET_ICON: { width: '24px', height: '14px' },
        CHAT_CONNECT_ICON: { width: '144px', height: '145px' },
        REFRESH: { width: '16px', height: '16px', fill: 'none' },
        EDIT_ICON: { width: '16px', height: '16px', fill: 'none' },
        EDIT_ICON_WHITE: { width: '16px', height: '16px', fill: 'none' },
        EDIT_ICON_BLACK: { width: '16px', height: '16px', fill: 'none' },
        TICK_GREEN: { width: '16px', height: '16px', fill: 'none' },
        EXCLAMATION_RED: { width: '16px', height: '16px', fill: 'none' },
        EXPAND_MORE: { width: '16px', height: '16px', fill: 'none' },
        EXPAND_LESS: { width: '16px', height: '16px', fill: 'none' },
        AWARD: { width: '144px', height: '144px', fill: 'none' },
        USERS: { width: '144px', height: '144px', fill: 'none' },
        EASEOfUSE: { width: '395px', height: '226px', fill: 'none' },
        WEBSITE_BUILDER: { width: '94px', height: '96px', fill: 'none' },
        WELCOME_TO_DASHBOARD: { width: '140px', height: '140px', fill: 'none' },
        BULLET_CIRCLE: { width: '24px', height: '24px', fill: 'none' },
        CHECK_CIRCLE: { width: '24px', height: '24px', fill: 'none' },
        OVERVIEW: { width: '24px', height: '24px', fill: 'none' },
        HELPICON: { width: '18px', height: '18px' },
        EXCLAMATION: { width: '14px', height: '14px', fill: 'none' },
        BLANK_PAGE_ICON: { width: '25px', height: '25px', fill: 'none' },
        ARROW_RIGHT_LONG: { width: '24px', height: '24px', fill: 'none' },
        CHECK_GREEN_CIRCLE: { width: '24px', height: '24px', fill: 'none' },
        BOOKINGS_INSERTER: { width: '144px', height: '144px', fill: 'none' },
    };

Object.keys(Icons).forEach(iconName => {
    const path = basePath + iconName.toLowerCase() + '.svg';
    const style = Icons[iconName];
    Icons[iconName] = pure(injectIntl(
        // $FlowFixMe: weird, intl is there
        ({ border, className = '', title, intl, iconStyle, ...wrapperProps }: IconProps) => {
            const
                wrapperClassName = styles.icon + ' ' + (border ? styles.border : '') + ' ' + className,
                translatedTitle = title ? intl.msgJoint(title) : undefined;

            return (
                <span className={wrapperClassName} {...wrapperProps} data-title={translatedTitle}>
                    <svg
                        style={{ ...iconStyle, ...style, pointerEvents: 'none' }}
                        // $FlowFixMe: TODO: rewrite full dynamic require
                        dangerouslySetInnerHTML={{ __html: '<use xlink:href="' + require(`${path}`) + '"></use>' }} // eslint-disable-line
                    />
                </span>
            );
        }
    ));
});

export const customizedIcon = (Icon: any, customizations: any) =>
    (props: Omit<IconProps, 'intl'>) => <Icon {...props} {...customizations} />;

export default Icons;
